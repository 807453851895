<template>
  <div class="w page">

    <div class="page-header">
      <h1 class="mb0">Правила возврата</h1>
    </div>

    <main>

      <nav class="breadcrumb">
        <router-link to="/">Главная</router-link>
        <router-link to="/about/">Информация о компании</router-link>
        <span class="bread-current">Правила возврата</span>
      </nav>

      <AboutMenu />


      <div class="text">
        <p style="text-align: center;"><strong>ПОЛОЖЕНИЕ</strong><br>
          о порядке реализации и возврата туристских путёвок   ООО «ТЭФ «ВИЗИТ»
        </p>


        <ol>
          <li>
            <h3>Общие положения</h3>
            <ol>
              <li>
                Настоящее Положение разработано в соответствии с Гражданским кодексом РФ, Федеральным законом Российской Федерации «О защите прав потребителей», Уставом общества с ограниченной ответственностью "ТУРИСТСКО-ЭКСКУРСИОННАЯ ФИРМА  «ВИЗИТ» (далее по тексту «Фирма»).
              </li>
              <li>
                Настоящее Положение устанавливает порядок реализации и порядок возврата туристских путёвок.
              </li>
              <li>
                Туристской путёвкой является оформленный электронный билет или бланк установленного образца путёвки (экскурсионного билета), дающий право на посещение физическим лицом экскурсионного маршрута, организованного Фирмой (далее по тексту «путёвка»).
              </li>
              <li>
                Путёвка содержит: наименование экскурсии (маршрута), время начала экскурсии, количество туристов, обслуживаемых по путёвке, сведения о туристе, стоимость экскурсии. Стоимость дополнительных услуг на объектах экскурсионного маршрута указывается на обратной стороне путёвки.
              </li>
              <li>
                Фирма оставляет за собой право вносить изменения в маршрут в случае форс-мажорных обстоятельств: природные катаклизмы, изменение администрацией объектов показа режима их работы, непредвиденный рост транспортных тарифов.
                Изменения в маршруте экскурсии не являются достаточным основанием для аннулирования  путёвки.
              </li>
            </ol>
          </li>
          <li>

            <h3>Порядок реализации туристских путёвок</h3>
            <ol>
              <li>
                Покупатели приобретают путёвки в местах их реализации у уполномоченных Фирмой  лиц – организаторов экскурсий (далее – «кассиров»).
              </li>
              <li>
                Приобретая путёвку на экскурсию, организованную «Фирмой», покупатель подтверждает факт заключения с «Фирмой» договора возмездного оказания услуг в сфере культуры и отдыха, а также своё согласие с настоящим Положением. Договор возмездного оказания услуг заключатся путём оформления и выдачи покупателю туристской путёвки.
              </li>
              <li>
                Стоимость туристских путёвок, а также льготы при их реализации отдельным категориям покупателей определяется Фирмой самостоятельно.
              </li>
            </ol>
          </li>
          <li>
            <h3>Перечень льгот на приобретение туристских путёвок</h3>
            <ol>
              <li>
                Без оплаты -  на туры, продолжительностью до 14 часов:
                <ol>
                  <li>
                    участники и инвалиды Великой Отечественной войны
                  </li>
                  <li>
                    блокадники Ленинграда
                  </li>
                  <li>
                    дети до 5 лет (без предоставления отдельного места в транспорте).
                  </li>
                </ol>
              </li>
              <li>
                <h3>С оплатой 50% от стоимости путёвки - на туры, продолжительностью до 14 часов:</h3>
                <ol>
                  <li>
                    инвалиды  I и II нерабочих групп
                  </li>
                  <li>
                    инвалиды детства
                  </li>
                  <li>
                    участники Афганской и Чеченской войн
                  </li>
                  <li>
                    участники специальной военной операции (СВО)
                  </li>
                </ol>
              </li>
            </ol>

            <p>
              Льготы предоставляются на основании подтверждающих документов при наличии их ксерокопий.
            </p>
          </li>


          <li>
            <h3>
              Порядок возврата туристских путёвок и их стоимости
              на ближние рейсы (продолжительность до 14 часов)
            </h3>
            <ol>
              <li>
                «Фирма» производит возврат денежных средств покупателю туристской путёвки  в размере полной стоимости  в следующих случаях:
                <ol>
                  <li>
                    отказа от экскурсии и возврата путёвки не позднее чем за 24 часа до начала экскурсионного маршрута;
                  </li>
                  <li>
                    болезни (кроме хронической), при наличии листка временной нетрудоспособности (или медицинской справки), заверенного печатью медицинского учреждения. Стоимость путёвки в полном объёме возвращается тому покупателю, который указан в медицинских документах. Также возвращается оплаченная сумма путёвки одного из родителей заболевшего ребёнка. Порядок возврата денежных средств остальным покупателям, вписанным в путёвку и отказавшимся от экскурсии позднее чем за 24 часа, определяется п.п. 4.2 настоящего Положения.
                  </li>
                </ol>
              </li>
              <li>
                В случаях отказа от туристской путёвки менее чем за 24 часа до начала экскурсионного маршрута, а также болезни покупателя без предъявления листка временной нетрудоспособности (либо медицинской справки) «Фирма» производит возврат денежных средств частично в следующих размерах от полной стоимости:
                <ol>
                  <li>
                    Для экскурсионных маршрутов, время отправления которых осуществляется  после 13-00:
                    <ol>
                      <li>
                        в срок от 24 до 15 часов до начала экскурсионного маршрута – 90 % от стоимости
                      </li>
                      <li>
                        в срок от 15 до 3 часов  до  начала экскурсионного маршрута – 50 % от стоимости
                      </li>
                      <li>
                        Денежные средства не возвращаются покупателю в случае его отказа от туристской путёвки в срок менее чем за 3 часа до начала экскурсионного маршрута.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Для экскурсионных маршрутов, время отправления которых осуществляется  с 8-00 до 9-00:
                    <ol>
                      <li>
                        в срок до 14-00 предыдущего дня  - 90% от стоимости
                      </li>
                      <li>
                        в срок от 14-00 до 18-00  предыдущего дня - 50% от стоимости
                      </li>
                      <li>
                        Денежные средства не возвращаются покупателю в случае его отказа от туристской путёвки позднее 18-00 предыдущего дня.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>

          </li>
          <li>
            <h3>
              Порядок возврата туристских путёвок и их стоимости  на дальние рейсы
              (продолжительность более 24  часов) Крым, Абхазия, Сочи, Сочи + Абхазия,  Лаго-Наки)
            </h3>
            <ol>
              <li>
                «Фирма» производит возврат денежных средств покупателю туристской путёвки  в размере полной стоимости  в следующих случаях:
                <ol>
                  <li>
                    отказа от экскурсии и возврата путёвки не позднее чем за 24 часа до начала экскурсионного маршрута;
                  </li>
                  <li>
                    болезни (кроме хронической), при наличии листка временной нетрудоспособности (или медицинской справки), заверенного печатью медицинского учреждения. Стоимость путёвки в полном объёме возвращается тому покупателю, который указан в медицинских документах. Также возвращается оплаченная сумма путёвки одного из родителей заболевшего ребёнка.
                  </li>
                </ol>
              </li>
              <li>
                Не возвращается покупателю сумма предварительной оплаты  в случае его отказа от туристской путёвки в срок менее чем за 24 часа до начала экскурсионного маршрута.
              </li>
            </ol>
          </li>
          <li>
            <h3>Дополнения</h3>
            <ol>
              <li>
                В случае опоздания покупателя к началу экскурсионного маршрута денежные средства за туристскую путёвку Фирмой не возвращаются.
              </li>
              <li>
                Ухудшение погодных условий (дождь, усиление ветра и др.) не является причиной возврата денежных средств.
              </li>
            </ol>
          </li>
        </ol>
      </div>

    </main>
  </div>
</template>
<script>

import AboutMenu from './_menu';
import $ from "jquery";

export default {

  created() {
    document.title = 'Договор оферты'
    $('meta[name="description"]').attr('content', 'Договор оферты компании ТЭФ Визит')
  },

  components: {
    AboutMenu
  }
}


</script>