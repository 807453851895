<template>
  <header :class="{fixed: scrollPosition, page: $route.path !== '/' && $route.path !== '/' + getCityCode() && $route.path !== '/' + getCityCode() +'/'}">
    <div class="w">

      <div class="flex flex-align-center">
        <router-link to="/" class="logo"></router-link>
        <div class="dropdown dropdown-event m-show">
          <span>{{ getCityTitle() }}</span>

          <div class="dropdown-content">
            <span v-for="(item, index) in cities" v-bind:key="index" v-on:click.prevent="setCity(item.city_code)">
              {{ item.city_title }}
            </span>
          </div>
        </div>
      </div>

      <div class="right" :class="{showed: mobileShow}">
        <nav>
          <router-link :to="`/${getCityCode()}/excursion/`" title="Экскурсии">
            Экскурсии
          </router-link>
          <router-link :to="`/${getCityCode()}/excursion/schedule/`" title="Расписание">
            Расписание
          </router-link>
          <router-link :to="`/${getCityCode()}/blog/`" title="Блог">
            Блог
          </router-link>
        </nav>

        <div class="profile">
          <div class="dropdown dropdown-event m-hide">
            <span>{{ getCityTitle() }}</span>

            <div class="dropdown-content">
              <span v-for="(item, index) in cities" v-bind:key="index" v-on:click.prevent="setCity(item.city_code)">
                {{ item.city_title }}
              </span>
            </div>
          </div>
          <div></div>
          <template v-if="loggedIn">
            <router-link to="/cabinet/orders/">
              {{ user.account_title }}
            </router-link>
            <router-link to="/logout/" class="btn btn-solid_white">
              Выход
            </router-link>
          </template>
          <template v-else>
            <span v-on:click.prevent="showLogin = true">
              Вход
            </span>
            <span class="btn btn-solid_white" v-on:click.prevent="showLogin = 'r'">
              Регистрация
            </span>
          </template>
          <span class="close-mobile-nav" v-if="mobileShow" v-on:click.prevent="mobileShow = false"></span>
        </div>
      </div>
      <span class="mobile-toggle" :class="{showed: mobileShow}" v-on:click="mobileShow = !mobileShow"></span>
      <a href="#" class="top" v-on:click.prevent="top">↑</a>
    </div>

    <template v-if="showLogin">
      <div class="window-bg" v-on:click="showLogin = false" />
      <div class="window">
        <a class="window-close window-close-inner" v-on:click="showLogin = false"></a>
        <Login :setTab="showLogin === 'r' ? 'sign_in' : 'log_in'" />
      </div>
    </template>

    <template v-if="showSearch">
      <div class="window-bg" v-on:click="showSearch = false" />
      <div class="window">
        <Search :window="true" v-on:update="updateSearch" />
      </div>
    </template>
    <span class="faq-btn" v-on:click.prevent="showFAQ = !showFAQ">Как купить?</span>
    <div class="window-bg" v-if="showFAQ" v-on:click="showFAQ = !showFAQ"></div>
    <div class="window" v-if="showFAQ">
      <a class="window-close window-close-inner" v-on:click="showFAQ = !showFAQ"></a>
      <div class="window-header">Как оформить заказ</div>

      <div>
        <div class="steps">
          <div class="w">

            <ul style="margin-top: 0">
              <li>
                <h4>
                  Выберите подходящий вам тур
                </h4>
                Мы подготовили для вас 58 уникальный
                маршрутов, по 10 различным тематикам
              </li>
              <li>
                <h4>
                  Выберите дату и время отправления
                </h4>
                А так же заполните количество человек и нажмите кнопку "Оформить заказ"
              </li>
              <li>
                <h4>
                  Оплатите заказ
                </h4>
                Заполните поля: ФИО, номер телефона, выберите место посадки и нажмите кнопку "Оплатить"
              </li>
              <li>
                <h4>
                  После оплаты дождитесь СМС
                </h4>
                Сразу после оплаты вы получите СМС с ссылкой на электронный билет с подробной информацией о заказе.
              </li>
            </ul>
          </div>
          </div>
      </div>

    </div>
  </header>


</template>

<script>
import mixins from "@/helpers/mixins";
import User from '../../user';
import Login from '../auth/Login';

import Search from '../excursion/_search';
import Ajax from "@/helpers/ajax";

export default {
  mixins: [mixins],

  components: {
    Login,
    Search
  },

  props: {
    category: Array,
    updateCart: Date
  },

  watch: {

    'updateCart'() {
      this.getCart()
    },

    '$route'() {
      this.showLogin = false;
      this.showSearch = false;
      this.mobileShow = false
    }
  },

  data () {
    return {
      showFAQ: false,
      scrollPosition: window.scrollY,
      data: {},
      cartSum: 0,
      cities: JSON.parse(localStorage.getItem('cities')),
      mobileShow: false,
      mobileShowEx: false,
      mobileShowEx2: false,
      mobileShowEx3: false,
      mobileShowBlog: false,
      mobileShowAbout: false,
      user: User.currentUser.user || {},
      loggedIn: User.isLoggedIn(),
      wishlistCount: 0,
      showLogin: false,
      showSearch: false,
      cart: []
    }
  },

  methods: {

    top() {
      window.scrollTo(0, 0);
    },

    setCity(code) {
      let oldCity = localStorage.getItem('c_code');
      localStorage.setItem('c_code', code);
      this.$emit('updateCity');

      if (oldCity !== code) {
        if (this.$route.params.city && this.$route.params.city !== code) {
          this.$router.replace({
            params: {
              city: code
            }
          })
        } else {
          if (this.$route.path === '/') {
            this.$router.push({path: `/${code}`})
            //reload component
          }
        }
      }
    },

    updateScroll() {
      this.scrollPosition = window.scrollY
    },

    setWishlistCount() {
      if (!localStorage.wishlist) {
        localStorage.wishlist = ''
      }
      let w = localStorage.wishlist !== '' ? localStorage.wishlist.split(',') : '';

      this.wishlistCount = w.length;
    },

    updateSearch() {
      this.showSearch = false;
    },

    updateUser() {
      this.loggedIn = User.isLoggedIn();
      this.user = User.currentUser.user || {};

      if (this.loggedIn) {
        this.showLogin = false;
      }
    },

    removeCartItem(i) {
      let cart = JSON.parse(localStorage.cart);
      cart.splice(i, 1);
      localStorage.cart = JSON.stringify(cart);
      this.getCart();
    },

    async getCart() {
      this.cart = [];
      this.cartSum = 0;

      if (!localStorage.cart) {
        localStorage.cart = ''
      } else {
        if (localStorage.cart.length) {
          let ex_ids = [];
          JSON.parse(localStorage.cart).find(i => {ex_ids.push(i.excursion_id)})
          let result = await Ajax.report('/excursion/list?filters[excursion_id]=ins|'+ ex_ids.join(',') +'&page=1&limit=100');

          if (result.response && result.response.rows) {
            JSON.parse(localStorage.cart).find(i => {
              result.response.rows.find(ex => {
                if (i.excursion_id === ex.excursion_id) {
                  let itemSum = Math.ceil((
                      (ex.excursion_price_adult * i.excursion_adults) + (ex.excursion_price_children_free * i.excursion_children_count1) + (ex.excursion_price_children * i.excursion_children_count2)
                  ) / 100 * 95);
                  this.cart.push(
                      Object.assign(i, ex, {excursion_item_sum: itemSum})
                  )
                  this.cartSum = Math.ceil(this.cartSum + itemSum)
                }
              })
            });
          }
        }
      }
    },
  },

  mounted () {
    this.setWishlistCount();
    this.updateUser();
    this.getCart();
    window.addEventListener('scroll', this.updateScroll);
  },

  created () {
   this.setWishlistCount();
    User.on('change.app', () => {
      this.updateUser();
    });

    User.on('refresh.app', () => {
      this.updateUser();
    });
  },

}
</script>