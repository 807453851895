<template>

  <div>
    <div class="text">
      <h2>Почтовая рассылка</h2>
      <p>Узнавайте первым об акциях и скидках на услуги размещенные на сайте</p>
      <p>
        Более 100 тысяч туристов уже ощутили выгоду наших писем. Присоединяйтесь!<br>
        — Узнавайте первым об акциях и скидках на сайте;<br>
        — Секретные предложения для подписчиков;<br>
        — Новинки и бестселлеры, интересные именно вам.
      </p>

    </div>

    <table class="table">

      <tr>
        <td>
          Еженедельная рассылка
        </td>
        <td>
          <label class="checkbox">
            <input type="checkbox" name="daily" checked="">
            <div>
              <i>Нет</i>
              <i>Да</i>
            </div>
          </label>
        </td>
      </tr>
      <tr>
        <td>
          Горящие туры и экскурсии
        </td>
        <td>
          <label class="checkbox">
            <input type="checkbox" name="hot" checked="">
            <div>
              <i>Нет</i>
              <i>Да</i>
            </div>
          </label>
        </td>
      </tr>

    </table>

  </div>


</template>

<script>
import mixins from '../../../helpers/mixins.js';


export default {
  mixins: [mixins],

  created () {
    document.title = 'Подписки - личный кабинет';
  },

  components: {
  }
}

</script>
