<template>
  <section class="page-header w">
    <h1 class="title">{{title}}</h1>
  </section>
</template>

<script>


export default {
  name: 'ImageHeader',
  components: {

  },

  props: {
    bg: String,
    title: String
  }
}
</script>