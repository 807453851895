<template>
  <div id="app">
    <Header v-if="$route.path.indexOf('/i/') === -1" :category="category" :update-cart="updateCartHeader" v-on:updateCity="updateCity" />
    <router-view @updateCart="updateCart()" :key="city"></router-view>
    <Footer v-if="$route.path.indexOf('/i/') === -1" :category="category" :key="city" />
  </div>
</template>

<script>
import User from '../user';
import Header from './default/Header';
import Footer from './default/Footer';
import Ajax from "../helpers/ajax";

export default {
  watch: {
    'updateCart'() {
      this.updateCartHeader = new Date();
    },
  },

  components: {
      Header,
      Footer
    },

    data () {
      return {
        city: localStorage.getItem('c_code'),
        updateCartHeader: new Date(),
        loggedIn: User.isLoggedIn(),
        role: '',
        category: []
      }
    },

    created () {
      this.loadCategory();

      User.on('change.app', () => {
        this.updateUser();
      });

      User.on('refresh.app', () => {
        this.updateUser();

        if (User.isLoggedIn()) {
          if (this.$route.path !== '/booking') {
            this.$router.go()
          }
        }
      });

      this.updateUser();
    },


    methods: {

      updateCity() {
        this.city = localStorage.getItem('c_code')
      },

      updateCart() {
        this.updateCartHeader = new Date();
        this.$emit('updateCart')
      },

      updateUser() {
        this.loggedIn = User.isLoggedIn();
        this.isImpersonate = User.isImpersonate();
        this.role = (this.loggedIn) ? User.currentUser.user.role_type : '';
      },

      async loadCategory() {
        let result = await Ajax.get('/category/all');
        if (result.response) {
          this.category = result.response;
          localStorage.category = JSON.stringify(result.response);
        }
      },
    }
  }
</script>

<style lang="scss">
  @import "../scss/common";
</style>