<template>

  <div>

    <ImageHeader
        :bg="`excursion/header/${data.excursion.excursion_images ? data.excursion.excursion_images[0] : 'n.webp'}`"
        :title="data.excursion.excursion_title"
    />

    <div class="w">

      <nav class="breadcrumb">
        <router-link to="/">Главная</router-link>
        <router-link :to="`/${getCityCode()}/excursion/`">Экскурсии</router-link>
        <router-link :to="'/' + getCityCode() + '/excursion/' + data.category.category_url+'/'">{{data.category.category_title}}</router-link>
        <span>{{data.excursion.excursion_title}}</span>
      </nav>


      <component :is="'script'" type="application/ld+json">
        {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "{{getCityTitle()}}",
            "item": "https://vizit.tours{{`/${getCityCode()}/`}}"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "Экскурсии",
            "item": "https://vizit.tours{{`/${getCityCode()}/excursion/`}}"
        },{
            "@type": "ListItem",
            "position": 3,
            "name": "{{data.category.category_title}}",
            "item": "https://vizit.tours{{'/' + getCityCode() + '/excursion/' + data.category.category_url+'/'}}"
        },{
            "@type": "ListItem",
            "position": 4,
            "name": "{{data.excursion.excursion_title}}",
            "item": "https://vizit.tours{{`/${getCityCode()}/excursion/${data.excursion.excursion_alias}`}}"
        }]
        }
      </component>

      <component :is="'script'" type="application/ld+json">
        {
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "{{data.excursion.excursion_seo_title}}",
          "image": [
            "https://vizit.tours/img/excursion/list/{{ data.excursion.excursion_images ? data.excursion.excursion_images[0] : 'n.webp' }}"
          ],
          "description": "{{data.excursion.excursion_seo_desc}}",
          "sku": "{{data.excursion.excursion_id}}",
          "mpn": "r{{data.excursion.excursion_id}}",
          "brand": {
            "@type": "Brand",
            "name": "ТЭФ Визит"
          },
          "review": {
          "@type": "Review",
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": "{{data.excursion.excursion_rating}}",
            "bestRating": "{{data.excursion.excursion_rating}}"
          },
            "author": {
              "@type": "Person",
              "name": "ТЭФ Визит"
            }
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "{{data.excursion.excursion_rating}}",
            "reviewCount": "{{parseInt(data.excursion.excursion_rating*4)}}"
          },
          "offers": {
            "@type": "AggregateOffer",
            "offerCount": "100",
            "lowPrice": "{{ data.excursion.excursion_price_children_free }}",
            "highPrice": "{{data.excursion.excursion_price_adult}}",
            "priceCurrency": "RUB"
          }
        }
      </component>

      <section class="scroll-right">
        <div class="prices">
          <div>
            {{prepareDuration(data.excursion.excursion_duration)}}
            <div>продолжительность</div>
          </div>
        </div>
        <div class="prices">
          <div>
            {{formatRUB(data.excursion.excursion_price_adult)}}
            <div>взрослый</div>
          </div>
          <div>
            {{formatRUB(data.excursion.excursion_price_children)}}
            <div>дети (6-12)</div>
          </div>
          <div>
            {{formatRUB(data.excursion.excursion_price_children_free)}}
            <div>дети (0-6)</div>
          </div>
          <div v-if="data.excursion.excursion_price_children_free2_allow === 'true'">
            {{formatRUB(data.excursion.excursion_price_children_free2)}}
            <div>дети без места</div>
          </div>
        </div>
      </section>

      <main class="ex-show">
        <div class="left">

          <div class="btn-fixed mb20">
            <button class="btn btn-primary" v-on:click="showCart = true">Оформить заказ</button>
          </div>

          <div class="photo">
            <div class="photo-preview" v-if="selectedPhoto" v-on:click="showPhoto = `${domain}/img/excursion/photo/preview/${selectedPhoto}`">
              <img v-if="selectedPhoto.indexOf('iframe') === -1" :src="`${domain}/img/excursion/photo/preview/${selectedPhoto}`" />
              <div v-else v-html="selectedPhoto"></div>
            </div>
            <div class="photo-list">
              <template v-for="(img, index) in data.excursion.excursion_images">
                <div
                    v-if="index !== 0 && img.indexOf('<iframe') > -1"
                    v-bind:key="index"
                    :class="{current: img === selectedPhoto}"
                    v-on:click.prevent="setPhoto(img)"
                >
                  <v-lazy-image src="/img/video.svg" :alt="data.excursion.excursion_title" />
                </div>
              </template>
              <template v-for="(img, index) in data.excursion.excursion_images">
                <a
                    v-if="index !== 0 && img.indexOf('<iframe') === -1"
                    v-bind:key="index"
                    :class="{current: img === selectedPhoto}"
                    :alt="data.excursion.excursion_title"
                    :href="`${domain}/img/excursion/photo/preview/${img}`"
                    v-on:click.prevent="setPhoto(img)"
                >
                  <img :src="`${domain}/img/excursion/photo/thumb/${img}`"
                       :alt="data.excursion.excursion_title"
                  />
                </a>
              </template>
            </div>
          </div>


          <div class="desc mb80 text">
            <h2>Описание экскурсии</h2>
            <div class="desc-content" :class="{show: showDesc}" v-html="data.excursion.excursion_desc"></div>
            <button class="btn btn-middle mt20" v-on:click="showDesc = !showDesc">
              <template v-if="showDesc">
                Скрыть
              </template>
              <template v-else>
                Показать больше
              </template>
            </button>
          </div>

          <div class="text mb80">
            <h2>Расписание</h2>

            <template v-if="schedule.calendar.length >= 1">

              <div class="calendar">

                <template v-for="(row, index) in schedule.calendar">
                  <div class="item" v-bind:key="index" v-if="index < 3">
                    <div class="title">
                      <b>{{row.mount}}, {{row.year}}</b>
                    </div>
                    <div class="head">Пн</div>
                    <div class="head">Вт</div>
                    <div class="head">Ср</div>
                    <div class="head">Чт</div>
                    <div class="head">Пт</div>
                    <div class="head red">Сб</div>
                    <div class="head red">Вс</div>

                    <div v-for="(row, index) in row.days"
                         v-bind:key="index"
                         :class="{red: row.weekend, on: row.day && data.excursion.dates[row.date]}"
                         v-on:click.prevent="showCart = row.day && data.excursion.dates[row.date] ? data.excursion.excursion_id : null;showCartDate = row.day && data.excursion.dates[row.date] ? row.date : null"
                    >
                      {{row.day || ''}}
                    </div>
                  </div>
                </template>


              </div>
            </template>

            <template v-else>
              На эту экскурсию пока нет расписания
            </template>

          </div>

          <div class="text mb80">
            <h2>Что включено в стоимость</h2>
            <p>
              Перечень бесплатных услуг, включенных в стоимость. Экскурсии, дополнительные услуги и входные билеты, не включенные в стоимость, оплачиваются отдельно.
            </p>

            <div class="info">
              <div v-for="(item, index) in data.excursion.free_service" v-bind:key="index">{{ item }}</div>
            </div>
          </div>

          <div class="text mb80">
            <h2>Цены на объектах посещения</h2>
            <p>
              Стоимость билетов и других услуг на объектах, посещаемых во время экскурсионного тура, которые оплачиваются на месте.
            </p>
            <div class="object" v-for="(row, index) in data.excursion.object_price_data" v-bind:key="index">
              <div>
                <b>{{row.object_price_title}}</b>
                <div>
                  {{data.excursion.object_data.find(x => x.object_id === row.object_id).object_title }},
                  {{data.excursion.object_data.find(x => x.object_id === row.object_id).object_city }}
                </div>
              </div>
              <div class="prices" v-if="priceType">
                <div>
                  {{formatRUB(row.object_price_adult)}}
                  <div>
                    {{priceType.find(x => x.value === row.object_price_adult_type).label }}
                  </div>
                </div>
                <div>
                  {{formatRUB(row.object_price_children_free)}}
                  <div>
                    {{priceType.find(x => x.value === row.object_price_children_free_type).label }}
                  </div>
                </div>
                <div>
                  {{formatRUB(row.object_price_children)}}
                  <div>
                    {{priceType.find(x => x.value === row.object_price_children_type).label }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text">
            <div class="card mb80" v-for="(tab, index) in data.excursion.excursion_tabs" v-bind:key="index">
              <div v-on:click.prevent="toggleTabs(index)" :class="{show: index === 0}">
                <div class="card-header">
                  <h2>{{tab.title}}</h2>
                </div>
              </div>
              <div :class="{show: index === 0}" v-html="tab.desc"></div>
            </div>
          </div>

          <div class="text mb80" v-if="data.excursion.place_data && data.excursion.place_data.length">
            <h2>Места посадки</h2>

            <YandexMap
                :coordinates="[44.9218, 37.3136]"
                :zoom="12"
                style="height: 370px;"
                map-type="map"
            >
              <YandexMarker
                    v-for="(row, index) in data.excursion.place_data"
                    v-bind:key="index"
                    :marker-id="row.place_id"
                    marker-type="placemark"
                    :coordinates="row.place_geo"
                    :properties="{
                      balloonContentHeader: row.place_title,
                      balloonContentBody: row.place_address,
                      hintContent: row.place_address
                    }"
                    :icon="{color: 'blue'}"
                ></YandexMarker>
            </YandexMap>
          </div>

          <div class="text">

            <h2 v-if="!data.excursion.comments">Отзывы об экскурсии</h2>
            <h2 v-else>
              Отзывы об экскурсии ({{data.excursion.comments.length}})
            </h2>

            <div class="prices mb20" v-if="data.excursion.comments">
              <div>
                {{ data.excursion.excursion_rating }}
                <div>Было отлично!</div>
              </div>

              <div>
                {{ data.excursion.excursion_rating_1 }}
                <div>Цена / Качество</div>
              </div>

              <div>
                {{ data.excursion.excursion_rating_2 }}
                <div>Транспорт</div>
              </div>

              <div>
                {{ data.excursion.excursion_rating_3 }}
                <div>Услуги экскурсовода</div>
              </div>

              <div>
                {{ data.excursion.excursion_rating_5 }}
                <div>Было интересно</div>
              </div>
            </div>

            <div class="mb80" v-if="!data.excursion.comments">
              <h4 class="mb0">
                Отзывов пока нет
              </h4>

              <template v-if="allowedComment">
                Добавьте первый отзыв об экскурсии.
              </template>
              <template v-else>
                Посетите эту экскурсию, чтобы оставить отзыв.
              </template>
            </div>

            <div class="comment add" v-if="allowedComment">
              <div class="comment-rating">
                <div>
                  <div class="input-placeholder">
                    Цена / Качество<b>{{ newComment.comment_rating_1 }}</b>
                  </div>
                  <input type="range" step="1" min="1" max="5" v-model.number="newComment.comment_rating_1">
                </div>

                <div>
                  <div class="input-placeholder">
                    Транспорт<b>{{ newComment.comment_rating_2 }}</b>
                  </div>
                  <input type="range" step="1" min="1" max="5" v-model.number="newComment.comment_rating_2">
                </div>

                <div>
                  <div class="input-placeholder">
                    Услуги экскурсовода<b>{{ newComment.comment_rating_3 }}</b>
                  </div>
                  <input type="range" step="1" min="1" max="5" v-model.number="newComment.comment_rating_3">
                </div>

                <div>
                  <div class="input-placeholder">
                    Было интересно<b>{{ newComment.comment_rating_4 }}</b>
                  </div>
                  <input type="range" step="1" min="1" max="5" v-model.number="newComment.comment_rating_4">
                </div>

                <button v-on:click="addComment" class="btn btn-primary">Добавить отзыв</button>
              </div>

              <Textarea name="comment_text" text="Ваш отзыв" v-model="newComment.comment_text" />

            </div>

            <div class="comment-list" v-if="data.excursion.comments">

              <div class="item" v-for="(row, index) in data.excursion.comments" v-bind:key="index">
                <div class="flex">
                  <div class="user">
                    {{row.comment_user_text}}
                  </div>
                  <div>
                    <div class="rating dropdown-event">
                        <b :data-r="row.comment_rating.toFixed(2)" :style="{'--p': `${row.comment_rating.toFixed(2) / 0.05 * 3.6}deg`}"></b>
                        <div class="dropdown-content bottom right">
                          <div>
                            Цена / Качество:
                            {{ row.comment_rating_1 }}
                          </div>
                          <div>
                            Транспорт:
                            {{ row.comment_rating_2 }}
                          </div>
                          <div>
                            Услуги экскурсовода:
                            {{ row.comment_rating_3 }}
                          </div>
                          <div>
                            Было интересно:
                            {{ row.comment_rating_4 }}
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                <p>
                  {{row.comment_text}}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="right">
          <Cart
              @updateCart="updateCart()"
              v-if="exLoaded"
              :ex="data.excursion"
              @addBasket="addBasket = true"
              :excursionId="data.excursion.excursion_id"
              :isw="addedWishlist"
          />
        </div>
      </main>

      <template v-if="showPhoto">
        <div class="window-bg" v-on:click="showPhoto = null"></div>
        <div class="window window-img">
          <div class="window-header-image">
            <button v-on:click="showPhoto = null" class="window-close"></button>
          </div>
          <button
              v-on:click="
              currentIndex === 1 ?
              (currentIndex = data.excursion.excursion_images.length - 1) :
              currentIndex--;
              showPhoto = '/img/excursion/photo/preview/' + data.excursion.excursion_images[currentIndex]"
              class="prev"
          />
          <button
              v-on:click="
              currentIndex === data.excursion.excursion_images.length - 1 ?
              currentIndex = 1 :
              currentIndex++;
              showPhoto = '/img/excursion/photo/preview/' + data.excursion.excursion_images[currentIndex]"
              class="next"
          />

          <img :src="`${ showPhoto }`" />
        </div>
      </template>

      <template v-if="showCart">
        <div class="window-bg" v-on:click="showCart = 0"></div>
        <div class="window">
          <a class="window-close window-close-inner" v-on:click="showCart = 0"></a>
          <Cart
              :datesset="showCartDate"
              @updateCart="updateCart()"
              v-if="exLoaded"
              :ex="data.excursion"
              :excursionId="showCart"
              :window="true"
          />
        </div>
      </template>

      <template v-if="showFAQ">
        <div class="window-bg" v-on:click="showFAQ = false"></div>
        <div class="window">
          <div class="window-header">
            Инструкция при покупки экскурсии
          </div>
          <div class="window-desc">
            <ol>
              <li>Выберете экскурсию.</li>
              <li>Нажмите на кнопку «купить билет».</li>
              <li>Выберете дату поездки, время, количество взрослых и детей (не забудьте указать возраст детей).</li>
              <li>Нажмите оформить заказ.</li>
              <li>Введите Ваши Фамилию и Имя.</li>
              <li>Номер телефона.</li>
              <li>Напишите ваш адрес проживания или выберете более удобную точку посадки из предложенных.</li>
              <li>Нажмите Оформить и оплатить.</li>
              <li>Оплатите заказ.</li>
              <li>В течении 10 минут после обработки заказа менеджером, вам поступит смс сообщение со ссылкой на электронный билет. </li>
            </ol>
            <p>В билете будут указаны данные:</p>
            <ul>
              <li>Название экскурсии</li>
              <li>Дата и время отправления</li>
              <li>Количество человек </li>
              <li>Ваши места в автобусе</li>
              <li>Точка посадки</li>
              <li>Номер автобуса (появляется в билете за час до отправления)</li>
            </ul>
            <p>
              При посадке в автобус Вам необходимо будет показать электронный
              билет экскурсоводу. Вы можете как открыть его на месте,
              так же сделать скриншот билета.</p>
          </div>
        </div>
      </template>
    </div>

  </div>
</template>

<script>

import mixins from '../../helpers/mixins.js';
import $ from "jquery";
import Ajax from '../../helpers/ajax';

import Cart from "../cart/_cart";
import ImageHeader from '../default/ImageHeader';

import { YandexMap, YandexMarker } from 'vue-yandex-maps'

import moment from "moment";
import User from "@/user";
import Textarea from "@/components/Textarea";

export default {
  mixins: [mixins],

  components: {
    Textarea,
    YandexMap,
    YandexMarker,
    ImageHeader,
    Cart
  },

  watch: {
    '$route'() {
      this.category = this.$route.params.category || 'all';
      this.excursion = this.$route.params.excursion || null;

      if (this.category !== 'all' && this.category !== 'search') {
        this.loadCategory();
        this.loadEx();
      }
    }
  },

  created() {

    this.loadPriceType();

    if (this.category !== 'all' && this.category !== 'search') {
      this.loadCategory();
      this.loadEx();
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.backUrl = from
    })
  },

  data() {
    return {
      showDesc: false,
      currentIndex: 1,
      newComment: this.defaultComment(),
      showCommentForm: false,
      allowedComment: false,
      showPhoto: null,
      selectedPhoto: null,
      showFAQ: false,
      priceType: null,
      addBasket: false,
      exLoaded: false,
      account: User.currentUser.account || {},
      loggedIn: User.isLoggedIn(),
      showCartDate: null,
      data: {
        category: {
          category_id: null,
          category_title: '',
          category_page_title: '',
          category_page_subtitle: '',
          category_image: '',
        },
        excursion: {
          free_service: [],
          dates: [],
          excursion_id: null,
          excursion_place: [],
          comments: [],
          excursion_images: [],
          object_price_data: [],
          place_data: null,
          object_data: [],
          excursion_start_date: null,
          excursion_end_date: null,
          excursion_time_type: false,
          excursion_time1: [],
          excursion_time2: [],
          excursion_time3: [],
          excursion_time4: [],
          excursion_time5: [],
          excursion_time6: [],
          excursion_time7: [],
        },
      },
      showCart: 0,
      backUrl: '',
      showShare: false,
      descHeight: 200,
      addedWishlist: false,
      error: null,
      report: null,
      category: this.$route.params.category || 'all',
      excursion: this.$route.params.excursion || null,

      schedule: {
        schedule_all: [],
        calendar: [],
        times: [
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ]
        ],
        mount: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь'
        ],
        days: [
          'Понедельник',
          'Вторник',
          'Среда',
          'Четверг',
          'Пятница',
          'Суббота',
          'Воскресенье'
        ],
      },
      mount: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
      ],
      types: {
        group: 'Групповой тур',
        individual: 'Индивидуальный тур',
        you: 'Самостоятельно'
      }
    }
  },
  methods: {

    defaultComment() {
      return {
        comment_text: '',
        comment_rating: 5,
        comment_rating_1: 5,
        comment_rating_2: 5,
        comment_rating_3: 5,
        comment_rating_4: 5,
        account_id: User.currentUser.account ? User.currentUser.account.account_id : 0,
        comment_user_text: User.currentUser.account ? User.currentUser.account.account_title : '',
        comment_target_id: 0,
      }
    },

    async addComment() {

      let data = this.newComment;

      data.comment_target_id = this.data.excursion.excursion_id
      data.comment_rating = [
        data.comment_rating_1,
        data.comment_rating_2,
        data.comment_rating_3,
        data.comment_rating_4
      ].reduce( ( p, c ) => p + c, 0 ) / 5

      let list = [];
      list.push(Ajax.post(`/comment/save/0`, data));

      let [commentResult] = await Promise.all(list);

      if (commentResult.error) {
        this.setError(commentResult.error);
      }

      if (commentResult.response) {
        this.data.excursion.comments.push(commentResult.response);
        this.newComment = this.defaultComment()
      }
    },

    setPhoto(photo) {
      this.selectedPhoto = photo
    },

    async loadPriceType() {
      let price_type = await Ajax.get('/misc/price_type?query=');
      if (price_type) {
        this.priceType = price_type
      }
    },

    createCalendar() {

      let sm = parseInt(moment(this.data.excursion.excursion_start_date).format('M'));
      let em = parseInt(moment(this.data.excursion.excursion_end_date).format('M'));
      let sd = parseInt(moment(this.data.excursion.excursion_start_date).format('DD'));
      let ed = parseInt(moment(this.data.excursion.excursion_end_date).format('DD'));
      let y = parseInt(moment().format('YYYY'));

      let cm = parseInt(moment(new Date()).format('M'));
      let cd = parseInt(moment(new Date()).format('DD'));

      for (let i = 0; i <= (em - cm); i++) {
        let mount = cm + i;
        let mountDate = moment().month(mount - 1).startOf('month');
        let days = [];
        let countDays = mountDate.daysInMonth();
        let firstDay = moment().month(mount - 1).startOf('month').isoWeekday() - 2

        for (let i = 0; i <= countDays + firstDay; i++) {

          let day = i - firstDay;

          let times = this.data.excursion.excursion_time_type === 'false' ? this.schedule.times[0] :
              this.schedule.times[moment().month(mount - 1).startOf('month').add(day - 1, 'days').isoWeekday()-1];
          let time = [];

          $.each(times, function (k, v) {
            if ((v.h + v.m > 0)) {
              time[k] = v;
            }
          })

          if (!time.length) {
            time = false
          }

          days[i] = {
            day: day >= 0 ? day : false,
            date: day >= 0 ? moment().month(mount - 1).startOf('month').add(day - 1, 'days').format('YYYY-MM-DD') : false,
            weekend: (moment().month(mount - 1).startOf('month').add(day-1, 'days').isoWeekday() > 5),
            times: (mount <= cm && day <= cd) ? false : (mount === sm && mount === em) ? ((day <= ed && day >= sd) ? time : false) : (time && day > 0 && ((day >= sd && mount === sm) || (day <= ed && mount === em ) || (mount < em && mount > sm))) ? time : false
          }
        }

        this.schedule.calendar[i] = {
          mountEnabled: false,
          mount: this.schedule.mount[mount - 1],
          year: y,
          days: days
        }
      }

    },

    async loadCategory() {
      let result = await Ajax.get('/s/category/'+  this.category);
      if (result.response) {
        this.data.category = result.response;
      } else {
        window.location.href = '/404/'
      }
    },

    async loadEx() {
      let result = await Ajax.get('/excursion/get-alias/' + this.category + '/' + this.excursion);
      if (result.response && result.response.excursion_id) {

        this.data.excursion = result.response;

        this.allowedComment = (this.loggedIn && this.account.allowed && this.account.allowed.length && this.account.allowed.find(i => i === this.data.excursion.excursion_id))

        this.selectedPhoto = this.data.excursion.excursion_images[1];

        document.title = result.response.excursion_seo_title ? result.response.excursion_seo_title : result.response.excursion_title;

        $('meta[name="description"]').attr('content', result.response.excursion_seo_desc ? result.response.excursion_seo_desc : result.response.excursion_desc.replace(/<(.|\n)*?>/g, '').slice(0, 155))
        $('meta[name="keywords"]').attr('content', result.response.excursion_seo_keyword ? result.response.excursion_seo_keyword : $('meta[name="keywords"]').attr('content'))

        for (let day = 0, len = 7; day < len; day++) {
          this.schedule.times[day] = this.data.excursion['excursion_time' + (day + 1)] && this.data.excursion['excursion_time' + (day + 1)].map((i) => i = {h: i.split(':')[0], m: i.split(':')[1]}) || this.schedule.times[day]
        }

        let dates = this.data.excursion.dates;
        this.data.excursion.dates = {}

        $.each(dates, function (k, date) {
          let flight_id = date.flight_id

          date = date.date

          let d = date.split(' ');
          let t = d[1].split(':');
          d = d[0];
          t = t[0] + ':' + t[1]

          this.data.excursion.dates = Object.keys(this.data.excursion.dates).sort().reduce(
              (obj, key) => {
                obj[key] = this.data.excursion.dates[key];
                return obj;
              },
              {}
          );

          if (this.data.excursion.dates[d]) {
            let new_times = this.data.excursion.dates[d].times;
            let new_flight_ids = this.data.excursion.dates[d].flight_ids;

            new_times.push(t)
            new_times.sort()

            new_flight_ids.push({time: t, flight_id: flight_id})

            this.data.excursion.dates[d] = {
              times: new_times,
              flight_ids: new_flight_ids
            }

          } else {
            this.data.excursion.dates[d] = {
              times: [t],
              flight_ids: [{time: t, flight_id: flight_id}]
            }
          }

        }.bind(this));

        this.createCalendar();

      } else {
        window.location.href = '/404/'
      }

      this.exLoaded = true
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;
    },

    updateCart() {
      this.$emit('updateCart')
    }
  }
}

</script>
