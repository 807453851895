<template>
  <div class="pager">
    <template v-if="!disabledPageValue">
      <a href="#" class="pager-prev disabled" v-if="currentPage === 1" v-on:click="noClick"></a>
      <a href="#" class="pager-prev" v-else v-on:click="pageDown"></a>

      <div class="pager-input">
        <div class="input">
          <input type="text" ref="page" name="page" v-bind:value="currentPage" v-on:keyup.enter="updatePage($event.target.value)" v-on:keyup.esc="restorePage" v-on:blur="restorePage" class="page" maxlength="10">
        </div>

        <div class="pager-label">/ {{maxPage}}</div>
      </div>

      <a href="#" class="pager-next disabled" v-if="currentPage >= maxPage" v-on:click="noClick"></a>
      <a href="#" class="pager-next" v-else v-on:click="pageUp"></a>
    </template>

    <div class="input select">
      <select name="per_page" v-model.number="limit">
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="500">500</option>
      </select>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Pager',

  props: {
    pageDisabled: String
  },

  computed: {
    disabledPageValue() {
      return this.pageDisabled === 'true'
    }
  },

  data () {
    return {
      currentPage: 1,
      maxPage: 1,
      limit: 50
    }
  },

  watch: {
    currentPage() {
      if (this.currentPage > this.maxPage) {
        this.currentPage = this.maxPage;
      } else if (this.currentPage < 1) {
        this.currentPage = 1;
      }

      this.$emit('change', this.currentPage, this.limit);
    },
    limit() {
      this.$emit('change', this.currentPage, this.limit);
    }
  },

  methods: {
    noClick(e) {
      e.preventDefault();
    },

    restorePage() {
      this.$refs.page.value = this.currentPage;
    },

    updatePage(page) {
      this.currentPage = parseInt(page) || 1;
    },

    pageDown(e) {
      e.preventDefault();
      this.currentPage--;
    },

    pageUp(e) {
      e.preventDefault();
      this.currentPage++;
    }
  },
}
</script>