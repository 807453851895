<template>
  <div class="w page">
    <div class="page-header">
      <h1 class="mb0">Точки продаж</h1>
    </div>
    <main>
      <nav class="breadcrumb">
        <router-link to="/">Главная</router-link>
        <router-link to="/about/">Информация о компании</router-link>
        <span class="bread-current">Точки продаж</span>
      </nav>
      <AboutMenu />
      <div class="text">
        <h3>
          Точки продаж экскурсий
        </h3>
        <div id="map-point">
          <Report
              ref="report"
              url="/points/list"
              :def-params="{
                  order: {
                    field: 'point_id',
                    order: 'desc'
                  }
                }"
              v-on:loading="reportLoading" v-on:done="reportLoaded">
            <template v-if="report && report.rows_count > 0">

              <YandexMap
                  :coordinates="[44.9218, 37.3136]"
                  :zoom="12"
                  style="height: 370px;"
                  :cluster-options="{
                        1: {clusterDisableClickZoom: true}
                  }"
              >
                  <YandexMarker v-for="(row, index) in report.rows" v-bind:key="index"
                                :marker-id="row.point_id"
                                marker-type="Point"
                                :coordinates="row.point_geo"
                                :properties="{
                                  balloonContentHeader: row.point_title,
                                  balloonContentBody: row.point_address,
                                  hintContent: row.point_address
                                }"
                                :hint-content="row.point_title"
                                :icon="{color: 'blue'}"
                  ></YandexMarker>
              </YandexMap>
            </template>
          </Report>
        </div>
      </div>

      <div class="text">
        <p>Если Вы, по какой либо причине, не можете приобрести экскурсии на нашем сайте он-лайн, то вы всегда можете приобрести их в городе на одной из точек продаж. Наша сеть по продаже экскурсий в Анапе одна из самых больших, в этом году для вас работает {{report && report.rows_count ? report.rows_count : 0}} точек в центре города.</p>
        <p>Продавцы-консультанты всегда с удовольствием ответят на ваши вопросы и проконсультируют по всем предоставляемым нами туристическим услугам.</p>
      </div>
    </main>
  </div>
</template>
<script>

import AboutMenu from './_menu';

import Report from '../../components/report/Report';
import mixins from '../../helpers/mixins.js';

import { YandexMap, YandexMarker } from 'vue-yandex-maps';
import $ from "jquery";

export default {
  mixins: [mixins],

  created() {
    document.title = 'Точки продаж в ' + this.getCityTitle(3)
    $('meta[name="description"]').attr('content', 'Точки продаж в ' + this.getCityTitle(3) + '  - ТЭФ Визит')
  },

  components: {
    Report,
    AboutMenu,
    YandexMap,
    YandexMarker
  },
  data () {
    return {
      data: {},
      error: null,
      report: null,
    }
  },
  methods: {
    getReport() {
      return this.$refs.report;
    },
    reportLoading() {
      this.error = null;
    },
    reportLoaded(report) {
      if (report) {
        if (report.response) {
          this.report = report.response;
          this.error = null;
        } else {
          this.report = null;
          this.error = 'Неизвестная ошибка.';
        }
      }
    },
  }
}
</script>