<template>
  <div class="window-target">

    <ImageHeader
        v-if="category === 'all'"
        bg="excursion/header/anapa-excursion.webp"
        :title="`Экскурсии из ${getCityTitle(1)}`"
    />

    <ImageHeader
        v-if="category === 'schedule'"
        bg="excursion/header/anapa-excursion.webp"
        title="Расписание"
    />

    <ImageHeader
        v-if="category === 'search'"
        bg="excursion/header/anapa-excursion.webp"
        title="Поиск экскурсий"
    />

    <ImageHeader
        v-if="category !== 'all' && category !== 'search' && category !== 'schedule'"
        :bg="'excursion/header/' + data.category.category_image"
        :title="data.category.category_page_title"
    />

    <main class="w">

      <nav class="breadcrumb">
        <router-link to="/">Главная</router-link>
        <router-link v-if="category !== 'all' && category !== 'search'" :to="`/${getCityCode()}/excursion/`">Экскурсии</router-link>
        <span v-else>Экскурсии</span>
        <span v-if="category !== 'all' && category !== 'search' && category !== 'schedule'">{{data.category.category_title}}</span>
        <span v-if="category === 'schedule'">Расписание</span>
      </nav>


      <component :is="'script'" type="application/ld+json">
        {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "{{getCityTitle()}}",
            "item": "https://vizit.tours{{`/${getCityCode()}/`}}"
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "Экскурсии",
            "item": "https://vizit.tours{{`/${getCityCode()}/excursion/`}}"
        },
        {
            "@type": "ListItem",
            "position": 3,
            "name": "{{ (category !== 'all' && category !== 'search' && category !== 'schedule') ? data.category.category_title : ( category === 'schedule' ? 'Расписание' : 'Поиск' ) }}",
            "item": "https://vizit.tours{{'/' + getCityCode() + '/excursion/' +category+'/'}}"
          }
        ]
        }
      </component>


      <Search :order="ex_order" v-if="category !== 'schedule'" />

      <template v-if="category === 'schedule'">

        <Report
            v-if="load === true"
            ref="report"
            url="/excursion/schedule"
            :def-params="{limit:500, filters: {
              excursion_city: 'in|' + this.getCityId(),
              'e.excursion_publication': 'eq|enabled'
            }}"
            :key="$route.params.category"
            v-on:loading="reportLoading" v-on:done="reportLoaded">

          <Loading v-if="!load" />

          <div v-if="error">
            {{error}}
          </div>

          <div v-if="report">
            <div v-if="report.rows_count === 0">
              <h2>Ничего не нашлось</h2>
              <p class="text">
                <b>к нашему сожалению на ближайшие 7 дней экскурсии не запланированы,</b>
                <br>
                ближайшие экскурсии запланированы на 1 июня 2024 года,<br>
                воспользуйтесь каталогом экскурсий, укажите в параметрах поиска интересующую вас дату
              </p>
            </div>
            <template v-else>

              <div class="sh" v-for="i in 7" v-bind:key="i">
                <div class="sh-header" :data-id="r = 0">
                  <h3>
                    {{moment(new Date()).add(i - 1, 'days').format('DD MMMM, dddd')}}
                  </h3>
                  <div v-if="dcGet(i)">
                    {{dc['dc' + i]}} {{declination(dc['dc' + i], ['экскурсия', 'экскурсии', 'экскурсий'])}}
                  </div>
                  <div class="trip" v-else>
                    Нет экскурсий
                  </div>
                </div>

                <template v-for="(row, index) in report.rows">
                  <template v-if="row.schedule_date && row.schedule_date.indexOf(moment(new Date()).add(i - 1, 'days').format('YYYY-MM-DD')) > -1">
                    <div class="sh-item" v-bind:key="index" :data-id="r++" v-show="r < show['dc' + i]">
                      <div class="date">
                        {{convertDate(row.schedule_date, 'HH:mm')}}
                      </div>

                      <router-link :to="`/${getCityCode()}/excursion/${row.excursion_alias}/`">
                        <v-lazy-image v-bind:src="domain+'/img/excursion/list/' + (row.excursion_images ? row.excursion_images[0] : 'n.webp')" :alt="row.excursion_title" />
                      </router-link>

                     <div class="title">
                       <router-link :to="`/${getCityCode()}/excursion/${row.excursion_alias}/`">
                         {{row.excursion_title}}
                       </router-link>
                       <div class="prices">
                         <div>
                           {{formatRUB(row.excursion_price_adult)}}
                           <div>взрослый</div>
                         </div>
                         <div>
                           {{formatRUB(row.excursion_price_children)}}
                           <div>дети ({{row.excursion_age_children_s}}-{{row.excursion_age_children_e}}) лет</div>
                         </div>
                         <div>
                           {{formatRUB(row.excursion_price_children_free)}}
                           <div>дети ({{row.excursion_age_children_free_s}}-{{row.excursion_age_children_free_e}}) лет</div>
                         </div>
                         <div v-if="row.excursion_price_children_free2_allow === 'true'">
                           {{formatRUB(row.excursion_price_children_free2)}}
                           <div>дети без места</div>
                         </div>
                       </div>
                     </div>
                    </div>
                  </template>
                </template>

                <a v-if="dc['dc' + i] > show['dc' + i]" class="btn btn-primary mt20" v-on:click.prevent="show['dc' + i] = show['dc' + i] + 5">
                  Показать ещё
                </a>


              </div>

            </template>
          </div>
        </Report>

      </template>

      <Report
          v-if="category !== 'schedule' && load === true"
          ref="report"
          url="/excursion/list"
          class="ex-list"
          :def-params="getParams()"
          :key="$route.params.category"
          v-on:loading="reportLoading" v-on:done="reportLoaded">

        <Loading v-if="!load"/>

        <div v-if="error">
          {{error}}
        </div>

        <template v-if="report">
          <template v-if="report.rows_count === 0">
            <div class="page-message">
              <h2>Экскурсии не найдены...</h2>
              попробуйте изменить параметры поиска или откройте <router-link :to="`/${getCityCode}/excursion/`">каталог экскурсий</router-link> без каких либо условий
            </div>
          </template>
          <template v-else>

            <router-link :to="`/${getCityCode()}/excursion/${row.excursion_alias}/${getParamSelectedDate()}`"
                v-for="(row, index) in report.rows"
                class="item"
                v-bind:key="index"
            >
              <span class="item-bage" v-if="row.excursion_bage">{{row.excursion_bage}}</span>

              <component :is="'script'" type="application/ld+json">
                {
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": "{{row.excursion_title}}",
                "image": [
                "https://vizit.tours/img/excursion/list/{{ row.excursion_images ? row.excursion_images[0] : 'n.webp' }}"
                ],
                "description": "{{row.excursion_desc}}",
                "sku": "{{row.excursion_id}}",
                "mpn": "r{{row.excursion_id}}",
                "brand": {
                "@type": "Brand",
                "name": "ТЭФ Визит"
                },
                "review": {
                "@type": "Review",
                "reviewRating": {
                "@type": "Rating",
                "ratingValue": "{{row.excursion_rating}}",
                "bestRating": "{{row.excursion_rating}}"
                },
                "author": {
                "@type": "Person",
                "name": "ТЭФ Визит"
                }
                },
                "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "{{row.excursion_rating}}",
                "reviewCount": "{{parseInt(row.excursion_rating*4)}}"
                },
                "offers": {
                "@type": "AggregateOffer",
                "offerCount": "100",
                "lowPrice": "{{ row.excursion_price_children_free }}",
                "highPrice": "{{row.excursion_price_adult}}",
                "priceCurrency": "RUB"
                }
                }

              </component>


                <span>
                  <v-lazy-image v-bind:src="domain+'/img/excursion/list/' + (row.excursion_images ? row.excursion_images[0] : 'n.webp')" :alt="row.excursion_title" />
                </span>


              <div class="desc">
                <div>
                  <h3>
                      <strong>{{row.excursion_title}}</strong>
                  </h3>

                  <p v-html="row.excursion_desc + '...'"></p>

                  <div class="info">

                    <div>
                      <i>
                        Длительность:
                      </i>
                      <b>
                        {{prepareDuration(row.excursion_duration)}}
                      </b>
                    </div>
                    <div>
                      <i>
                        Ближайший рейс:
                      </i>
                      <b>
                        {{row.next ? convertDate(row.next, 'DD MMM в HH:mm') : 'нет в расписании'}}
                      </b>
                    </div>
                  </div>
                </div>

                <div class="flex flex-jc_sp">
                  <div class="prices">
                    <div>
                      {{formatRUB(row.excursion_price_adult)}}
                      <div>взрослый</div>
                    </div>
                    <div>
                      {{formatRUB(row.excursion_price_children)}}
                      <div>дети ({{row.excursion_age_children_s}}-{{row.excursion_age_children_e}})</div>
                    </div>
                    <div>
                      {{formatRUB(row.excursion_price_children_free)}}
                      <div>дети ({{row.excursion_age_children_free_s}}-{{row.excursion_age_children_free_e}})</div>
                    </div>
                    <div v-if="row.excursion_price_children_free2_allow === 'true'">
                      {{formatRUB(row.excursion_price_children_free2)}}
                      <div>дети без места</div>
                    </div>
                  </div>

                </div>
              </div>
            </router-link>

          </template>
          <Pager />
        </template>
      </Report>

    </main>
    <template v-if="showCart">
      <div class="window-bg" v-on:click="showCart = 0"></div>
      <div class="window" tabindex="-1" style="overflow: hidden auto;">
        <a class="window-close window-close-inner" v-on:click="showCart = 0"></a>
        <Cart :ex="ex" :excursionId="showCart" :isw="checkWishlist(showCart)" @addWishlist="addWishlist(showCart)" :window="true" />
      </div>
    </template>

  </div>
</template>
<script>

import mixins from '../../helpers/mixins.js';

import $ from 'jquery';

import Ajax from '../../helpers/ajax';
import ImageHeader from '../default/ImageHeader';
import Search from './_search';
import Loading from '../../components/report/Loading';
import Report from '../../components/report/Report';
import Pager from '../../components/Pager';
import Cart from "../cart/_cart";
import moment from "moment";

export default {
  mixins: [mixins],

  components: {
    ImageHeader,
    Search,
    Loading,
    Pager,
    Cart,
    Report
  },

  watch: {

    '$route'() {

      this.showCart = 0;
      this.category = this.$route.params.category || 'all';

      this.load = false;

      if (this.category !== 'all' && this.category !== 'search' && this.category !== 'schedule') {

        this.getReport().changeReportParams({
          filters: {
            excursion_city: 'in|' + this.getCityId(),
            't.excursion_publication': 'eq|enabled',
            excursion_start_date: this.$route.query['filters[excursion_start_date]'],
            excursion_end_date: this.$route.query['filters[excursion_end_date]']
          },
          order: {
            field: this.ex_order.split('|')[0],
            order: this.ex_order.split('|')[1]
          },
        }, true);


        this.loadCategory();

      } else {

        if (this.category === 'schedule') {
          document.title = 'Расписание экскурсий в '+ this.getCityTitle(3) +' на 2024 год';
          $('meta[name="description"]').attr('content', 'Полное расписание экскурсий в '+ this.getCityTitle(3) +' на 2024 год')
          this.show = {
            dc1: 5,
            dc2: 5,
            dc3: 5,
            dc4: 5,
            dc5: 5,
            dc6: 5,
            dc7: 5,
          }

        } if (this.category === 'search') {
          document.title = 'Поиск экскурсий в ' + this.getCityTitle(3);
          $('meta[name="description"]').attr('content', 'Поиск экскурсий в '+ this.getCityTitle(3) +' на 2024 год, Крым, Абхазия, Сочи и многое другое')

          this.getReport().changeReportParams({
            filters: {
              excursion_city: 'in|' + this.getCityId(),
              't.excursion_publication': 'eq|enabled',
              excursion_title: this.$route.query['filters[excursion_title]'],
             // category_id: this.$route.query['filters[category_id]'],
              excursion_type: this.$route.query['filters[excursion_type]'],
              excursion_start_date: this.$route.query['filters[excursion_start_date]'],
              excursion_end_date: this.$route.query['filters[excursion_end_date]']
            },
            order: {
              field: this.ex_order.split('|')[0],
              order: this.ex_order.split('|')[1]
            },
          }, true);

        } else {
          document.title = 'Каталог экскурсий из ' + this.getCityTitle(1);
          $('meta[name="description"]').attr('content', 'Каталог экскурсий из '+ this.getCityTitle(1) +' на 2024 год в Крым, Абхазия, Сочи и многие другие места')

          this.getReport().changeReportParams({
            filters: {
              excursion_city: 'in|' + this.getCityId(),

              excursion_start_date: this.$route.query['filters[excursion_start_date]'],
              excursion_end_date: this.$route.query['filters[excursion_end_date]']
            },
            order: {
              field: this.ex_order.split('|')[0],
              order: this.ex_order.split('|')[1]
            },
          }, true);
        }
      }

      this.load = true;
    },
  },


  created() {

    this.show = {
      dc1: 5,
      dc2: 5,
      dc3: 5,
      dc4: 5,
      dc5: 5,
      dc6: 5,
      dc7: 5,
    }

    $(document).off('click', '.window-target .mfp-wrap').on('click', '.window-target .mfp-wrap', function (e) {
      if ($(e.target).hasClass('mfp-container') || $(e.target).hasClass('mfp-content')) {
        this.showCart = 0;
        this.showLogin = false;
        this.showInfo = false;
        this.showCancel = false;
      }
    }.bind(this))

    window.onpopstate = function() {

      if (this.category === 'search') {
        this.$router.push({ path: '/' });
      }

    }.bind(this);

    if (this.category !== 'all' && this.category !== 'search' && this.category !== 'schedule') {
      this.loadCategory();

    } else {
      if (this.category === 'schedule') {
        document.title = 'Расписание экскурсий в '+ this.getCityTitle(3) +' на 2024 год';
        $('meta[name="description"]').attr('content', 'Полное расписание экскурсий в '+ this.getCityTitle(3) +' на 2024 год')
      } else {
        document.title = 'Каталог экскурсий из ' + this.getCityTitle(1);
        $('meta[name="description"]').attr('content', 'Каталог экскурсий из '+ this.getCityTitle(1) +' на 2024 год в Крым, Абхазия, Сочи и многие другие места')

      }
    }

    this.load = true;


    $(document).off('change', '#order').on('change', '#order', function (k) {
      let value = $(k.target).val();
      localStorage.ex_order = value;
      this.ex_order = value;

      this.getReport().changeReportParams({
        order: {
          field: this.ex_order.split('|')[0],
          order: this.ex_order.split('|')[1]
        },
      }, false);


    }.bind(this))
  },

  data() {
    return {
      load: false,
      addBasket: false,
      data: {
        category: {
          category_id: null,
          category_title: '',
          category_page_title: '',
          category_page_subtitle: '',
          category_image: ''
        }
      },
      moment: moment,
      sd: moment(new Date()),
      ed: moment(new Date()).add(7, 'days'),
      dc: {
        dc1: 0,
        dc2: 0,
        dc3: 0,
        dc4: 0,
        dc5: 0,
        dc6: 0,
        dc7: 0,
      },
      show: {
        dc1: 5,
        dc2: 5,
        dc3: 5,
        dc4: 5,
        dc5: 5,
        dc6: 5,
        dc7: 5,
      },
      ex: {},
      showCart: 0,
      ex_order: localStorage.ex_order || 'excursion_id|desc',
      error: null,
      report: null,
      category: this.$route.params.category || 'all',
      types: {
        group: 'Групповой тур',
        individual: 'Индивидуальный тур',
        you: 'Самостоятельно'
      }
    }
  },
  methods: {

    dcGet(i) {
      let res = 0;
      $.each(this.report.rows, function (index, row) {
        if (
            index > -1 &&
            row.schedule_date && row.schedule_date.indexOf(moment(new Date()).add(i - 1, 'days').format('YYYY-MM-DD')) > -1
        ) {
          res++
        }
      })

      this.dc['dc' + i] = res;
      return res;
    },
    addWishlist(id) {
      id = id + '';

      let w = localStorage.wishlist.split(',') || [];

      if (w.indexOf(id) > -1) {
        w.splice(w.indexOf(id), 1);
      } else {
        w.push(id+'')
      }

      if (w[0] === '') {
        w.splice(0, 1);
      }

      localStorage.wishlist = w;

      $('.wish_count').html(w.length)

      this.getReport().reload();
    },

    checkWishlist(id) {
      id = id + '';
      let w = localStorage.wishlist.split(',') || [];
      return w.indexOf(id) > -1;
    },

    getReport() {
      return this.$refs.report;
    },


    async loadCategory() {
      let result = await Ajax.get('/s/category/'+  this.category);
      if (result.response && result.response.category_id) {
        this.data.category = result.response;
        document.title =  result.response.category_seo_title ? result.response.category_seo_title.replace('{city|1}', this.getCityTitle(1)).replace('{city|2}', this.getCityTitle(3)) : result.response.category_title;

        $('meta[name="description"]').attr('content', result.response.category_seo_desc ? result.response.category_seo_desc.replace('{city|1}', this.getCityTitle(1)).replace('{city|2}', this.getCityTitle(3)) : result.response.category_page_subtitle ? result.response.category_page_subtitle : 'Каталог экскурсий из '+this.getCityTitle(1)+', Сочи, Крым, абхазия')
        $('meta[name="keywords"]').attr('content', result.response.category_seo_keyword ? result.response.category_seo_keyword : $('meta[name="keywords"]').attr('content'))
      } else {
        window.location.href = '/404/'
      }
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    getParams() {

      let obj = JSON.parse(localStorage.category).find((o) => {
        if (o.category_url === this.$route.params.category) {
          return o;
        }
      });
      let data = {
        order: {
          field: this.ex_order.split('|')[0],
          order: this.ex_order.split('|')[1]
        },
        filters: {
          excursion_city: 'in|' + this.getCityId(),
          't.excursion_publication': 'eq|enabled'
        }
      }

      if (obj && this.$route.params.category !== 'search') {
        data['filters'] = {
          category_id: 'in|' + obj.category_id,
          excursion_city: 'in|' + this.getCityId(),
          't.excursion_publication': 'eq|enabled'
        }
      }

      return data;

    },

    reportLoading() {
      this.error = null;
    },


    reportLoaded(report) {

      if (report) {
        if (report.response) {

          report.response.rows = report.response.rows.sort((a, b) => {
            return new Date(a.next || '2099-09-13T21:00:00.000Z').getTime() - new Date(b.next || '2099-09-13T21:00:00.000Z').getTime();
          })

          this.report = report.response;
          this.error = null;
        } else {
          this.report = null;
          this.error = 'Неизвестная ошибка.';
        }
      }
    },

    updateCart() {
      this.$emit('updateCart')
    },

    getParamSelectedDate() {

      if (this.$route.query['filters[excursion_start_date]']) {
        return '?d=' + this.$route.query['filters[excursion_start_date]'].split('|')[1];
      } else{
        return ''
      }


    }


  }
}



</script>