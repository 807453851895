<template>
    <aside :class="!window ? 'col-md-3 left-filter-tours' : ''">

      <div class="period-wrap">
        <a class="nav prev" :class="{show: showPrev}" v-on:click="sliderSet('prev')"></a>
        <div class="period" ref="period">
          <div>
            <div>
              <div class="item" :class="{fixed: day.d === '01' || day.current}" v-for="(day, index) in dates" v-bind:key="index">
                <span v-if="day.d === '01' || day.current">{{ day.m }}</span>
              </div>
            </div>
            <div>
              <div
                  class="item selection"
                  :class="{
                  weekend: day.isWeekend,
                  selected: currentDate === day.date
                }"
                  v-for="(day, index) in dates"
                  v-bind:key="index"
                  v-on:click="currentDate === day.date ? currentDate = null : currentDate = day.date"

              >
                <b>{{day.d}}</b>
                <i>{{day.dt}}</i>
              </div>
            </div>
          </div>
        </div>
        <a class="nav next" :class="{show: showNext}" v-on:click="sliderSet('next')"></a>
      </div>

      <div class="category" ref="category">
        <router-link
            class="item"
            v-for="(row, index) in category"
            v-bind:key="index"
            :class="{ selected: $route.params.category === row.category_url }"
            :to="getCategoryLink(`/${getCityCode()}/excursion${$route.params.category === row.category_url ? '' : '/' + row.category_url}/`)"
            :title="row.category_title"
        >
          {{ row.category_title }}
        </router-link>
      </div>



    </aside>
</template>

<script>

import moment from 'moment';

import mixins from '../../helpers/mixins.js';

export default {
  mixins: [mixins],
  components: {
  },

  watch: {

    'currentDate'() {

      let q = {
        'order[field]': 'excursion_id',
        'order[order]': 'desc',
      };

     q["filters[excursion_start_date]"] = 'lte|' + this.currentDate
     q["filters[excursion_end_date]"] = 'gte|' + this.currentDate

      this.$router.push({
        query: this.currentDate ? q : null
      })

    },

    'excursion_type'() {
      this.search()
    },

    'excursion_title'() {
      this.search()
    },

    '$route'() {
      if (this.$route.query['people']) {
        this.peopleCount = parseInt(this.$route.query['people'].split('|')[0])
        this.peopleCount2 = parseInt(this.$route.query['people'].split('|')[1])
        this.peopleCount3 = parseInt(this.$route.query['people'].split('|')[2])
      }

    }
  },

  mounted() {

    let startDate = moment();
    let endDate = moment().add(3, 'months').endOf('month');

    let dayCount = endDate.diff(startDate, 'days')

    let i = 0;

    while (i < dayCount) {

      let day = moment().add(i, 'days')

      this.dates.push({
        date: day.format('YYYY-MM-DD'),
        d: day.format('DD'),
        m: day.format('MMMM'),
        dt: day.format('ddd'),
        current: i === 0,
        isWeekend: day.day() % 6==0

      })

      i++;
    }
  },

  props: {
    window: {
      type: Boolean,
      default: false,
    },
    order: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      showPrev: false,
      showNext: true,
      dates: [],
      currentDate: null,
      showClear: this.$route.params.category === 'search',
      showMore: false,
      ex_order: localStorage.ex_order || 'excursion_id|desc',
      data: {
        peopleCount: 1,
      },
      category: JSON.parse(localStorage.category) || [],
      error: null,
      report: null,
      showPeople: false,
      peopleCount: 1,
      peopleCount2: 0,
      peopleCount3: 0,
      excursion_title: '',
      excursion_type: '',
      excursion_start_date: null,
      excursion_end_date: null,
      category_id: null
    }
  },


  created () {

    if (this.$route.query['people']) {
      this.peopleCount = parseInt(this.$route.query['people'].split('|')[0])
      this.peopleCount2 = parseInt(this.$route.query['people'].split('|')[1])
      this.peopleCount3 = parseInt(this.$route.query['people'].split('|')[2])
    }

    if (this.$route.query['filters[excursion_title]']) {
      this.excursion_title = this.$route.query['filters[excursion_title]'].split('|')[1]
    }

    if (this.$route.query['filters[excursion_start_date]']) {
      this.currentDate = this.$route.query['filters[excursion_start_date]'].split('|')[1];
    }

  },

  methods: {

    getCategoryLink(link) {
      return link + '?' + this.$route.href.split('?')[1]?.replace('filters[category_id]=in|', 'filterq[category_id]=in|');
    },

    sliderScroll(e) {
      this.$refs.period.scrollLeft = this.$refs.period.scrollLeft + (e.deltaX + e.deltaY)
      this.setShowPrev(e.deltaX + e.deltaY)
    },

    setShowPrev(count){
      this.showPrev = count > 0
      this.showNext = count + this.$refs.period.offsetWidth + 20 <= this.$refs.period.scrollWidth
    },

    sliderSet(type) {
      if (type === 'next') {
        let left = this.$refs.period.scrollLeft + this.$refs.period.offsetWidth / 3
        this.setShowPrev(left)

        this.$refs.period.scrollTo({
          left: left,
          behavior: 'smooth'
        })

      } else {
        let left = this.$refs.period.scrollLeft - this.$refs.period.offsetWidth / 3
        left = left < 20 ? 0 : left
        this.setShowPrev(left)

        this.$refs.period.scrollTo({
          left: left,
          behavior: 'smooth'
        })
      }

    },

    converDate(date, split) {
      date = date.split(split)
      let d = split === '.' ? '-' : '.'
      return date[2] + d + date[1] + d + date[0]
    },

    search() {

      let q = {
        'order[field]': 'excursion_id',
        'order[order]': 'desc',
        'people': this.peopleCount + '|' + this.peopleCount2 + '|' + this.peopleCount3,
      };

      if (this.excursion_title) {
        q["filters[excursion_title]"] = 'like|' + this.excursion_title
      }
      if (this.excursion_type) {
        q["filters[excursion_type]"] = 'eq|' + this.excursion_type
      }

      q["filters[excursion_city]"] = 'in|' + this.getCityId()
      q["filters[excursion_publication]"] = 'eq|enabled'

      if (this.category_id && this.category_id !== '9999') {
     //   q["filters[category_id]"] = 'in|' + this.category_id
      } else {
        q["filters[category_id]"] = 'not_in|9999'
      }

      if (this.$refs.pick.value) {
        q["filters[excursion_start_date]"] = 'lte|' + this.converDate(this.$refs.pick.value, '.')
        q["filters[excursion_end_date]"] = 'gte|' + this.converDate(this.$refs.pick.value, '.')
      }

      this.$router.push({
        path: '/' + this.getCityCode() + '/excursion/search',
        query: q
      })

      this.$emit('update')
    },

  }
}
</script>