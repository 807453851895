<template>
  <div class="auth">
    <div class="window-header">
      <template v-if="tab === 'log_in'">
        Вход на сайт
      </template>
      <template v-if="tab === 'sign_in'">
        Регистрация
      </template>
      <template v-if="tab === 'forgot'">
        Напомнить пароль
      </template>
    </div>

    <div class="tabs">
      <a class="tab" :class="{active: tab === 'log_in'}" v-on:click.prevent="tab = 'log_in'">Вход</a>
      <a class="tab" :class="{active: tab === 'sign_in'}" v-on:click.prevent="tab = 'sign_in'">Регистрация</a>
      <a class="tab" :class="{active: tab === 'forgot'}" v-on:click.prevent="tab = 'forgot'">Напомнить пароль</a>
    </div>

    <div v-if="errorText !== ''" class="error-box">{{errorText}}</div>

    <template v-if="tab === 'log_in'">

      <div class="lg">
        <Input mask="+#(###)###-##-##" name="user_login" text="Телефон" type="text" required="true" v-model.trim="user_login" />
        <Input name="user_password" text="Пароль" required="true" type="password" v-model="user_password" />
      </div>

      <button class="btn btn-primary" v-on:click="login">Войти</button>

    </template>

    <template v-if="tab === 'sign_in'">

      <div class="si">
        <Input name="account_title" text="ФИО" type="text" required="true" v-model.trim="account_title" />
        <Input name="user_login" text="Электронная почта" type="text" required="true" v-model.trim="user_login" />
        <Input name="user_phone" mask="+7(###)###-##-##" text="Номер телефона" type="text" required="true" v-model.trim="user_phone" />
        <Input name="user_password" text="Пароль" required="true" type="password" v-model="user_password" />
        <Input name="confirm_password" text="Повторите пароль" required="true" type="password" v-model="confirm_password" />
      </div>

      <button class="btn btn-primary" v-on:click="signIn">Зарегистрироваться</button>

    </template>

    <template v-if="tab === 'forgot'">

      <div class="window-desc" v-if="is_forgot">
        На указанный номер телефона мы отправили смс с кодом восстановления, введите его в поле ниже.<br>
        После успешного ввода кода вы будете авторизованы и сможете изменить пароль в личном кабинете.
      </div>

      <div>
        <Input v-if="!is_forgot" mask="+#(###)###-##-##" text="Телефон" name="user_login" type="text" required="true" v-model.trim="user_login" />
        <Input v-if="is_forgot" name="auth_code" type="text" required="true" v-model.trim="auth_code" />
      </div>

      <button class="btn btn-primary" v-on:click="forgot">Восстановить</button>

    </template>
  </div>
</template>

<script>
    import mixins from '../../helpers/mixins.js';

    import User from '../../user';
    import Input from '../../components/Input';
    import Ajax from '../../helpers/ajax';


    export default {
      mixins: [mixins],

        components: {
            Input
        },

      props: {
        setTab: {
          type: String,
          default: 'log_in'
        },
      },

        data () {
            return {
              is_forgot: false,
              account_title: '',
              auth_code: null,
              user_login: '',
              user_phone: '',
              user_password: '',
              confirm_password: '',
              recaptcha: '',
              showCaptcha: false,
              errorText: '',
              tab: this.setTab
            }
        },
        methods: {
            verifyRecaptcha(response) {
                this.recaptcha = response;
            },

            resetRecaptcha() {
                this.recaptcha = '';
            },

          async signIn () {

            this.clearError();

            if (this.confirm_password !== this.user_password) {
              this.setError({
                confirm_password: 'Пароли не совпадают',
                user_password: 'Пароли не совпадают'
              });

              return false;
            }

              let data = {
                user_phone: this.user_phone.match(/[\d]/g).join(''),
                user_login: this.user_login,
                user_password: this.user_password,
                confirm_password: this.confirm_password,
                account_title: this.account_title
              };


              let list = [];
              list.push(Ajax.post(`/account/save/0`, data));

              let [userResult] = await Promise.all(list);

              if (userResult.error) {
                this.setError(userResult.error);
              }

            if (userResult.response) {

              let result = await User.login(this.user_login, this.user_password, this.recaptcha);
              if (result === true) {
                this.showCaptcha = false;
              }

              this.$router.go()
            }
          },

          async forgot () {
            this.errorText = '';

            if (!this.user_login) {
              this.errorText = 'Введите номер телефона';
              return;
            }

            if (!this.is_forgot) {
              let result = await User.forgot(this.user_login);

              if (result.status === 200) {
                this.is_forgot = true;
              } else {

                const errors = {
                  299: 'Неверный номер телефона',
                  308: 'Не нашлось аккаунта с таким номером телефона'
                };

                if (errors[result.code]) {
                  this.errorText = errors[result.code];
                  return;
                }

                if (result.code === 304) {
                  this.showCaptcha = true;
                  this.errorText = 'Невозможно проверить капчу. Пожалуйста, попробуйте снова.';
                  return;
                }

                this.errorText = 'Что-то пошло не так:(';
              }
            } else {
              if (!this.auth_code) {
                this.errorText = 'Введите код из смс';
                return;
              }
              let result = await User.forgot(this.user_login, this.auth_code);

              if (result) {
                if (result.auth_code) {
                  this.errorText = result.auth_code;
                  return;
                }
                if (result.auth) {

                  let resultLogin = await User.login(this.user_login.match(/[\d]/g).join(''), 99999, this.recaptcha, true);

                  if (resultLogin === true) {
                    this.showCaptcha = false;
                  }

                //  this.$router.push({ path: '/cabinet/profile' });
                }
              }
            }
          },

          async login () {
                this.errorText = '';

                if ((!this.user_login) || (this.user_password.length < 5)) {
                    this.errorText = 'Некорректный номер телефона или пароль';
                    return;
                }

                let result = await User.login(this.user_login.match(/[\d]/g).join(''), this.user_password, this.recaptcha);
                if (result === true) {
                    this.showCaptcha = false;
                } else {
                    this.recaptcha = '';
                    if (this.$refs.recaptcha) {
                        this.$refs.recaptcha.reset();
                    }

                    const errors = {
                        299: 'Некорректный номер телефона',
                        300: 'Некорректный номер телефона или пароль',
                        303: 'Некорректный номер телефона или пароль',
                        307: 'Используйте номер телефона для входа',
                        333: 'Ваш аккаунт заблокирован администрацией, подробности вы можете уточнить, написав нам через форму обратной связи на сайте.'
                    };

                    if (errors[result.code]) {
                        this.errorText = errors[result.code];
                        return;
                    }

                    if (result.code === 304) {
                        this.showCaptcha = true;
                        this.errorText = 'Невозможно проверить капчу. Пожалуйста, попробуйте снова.';
                        return;
                    }

                    this.errorText = 'Что-то пошло не так:(';
                }
            }
        }
    }
</script>