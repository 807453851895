<template>

  <div class="load-cabinet">
    <div class="col-lg-12 add_bottom_15 cabinet">
      <div class="form_title">
        <h3><strong><div font-awesome-icon icon="fas fa-shopping-bag" /></strong>Ваша корзина</h3>
        <p>Список товаров и услуг отобранных вами для оформления заказа</p>
      </div>
      <div class="step load-basket-info">



        <template v-if="cart.length">
          <div class='row o-row' v-for="(row, index) in cart" v-bind:key="index">
            <div class='sch_row wow fadeIn'>
              <div class='image'>
                <v-lazy-image v-bind:src="'/img/excursion/list/' + (row.excursion_images ? row.excursion_images[0] : 'n.webp')" :alt="row.excursion_title" />
              </div>
              <div class='txt_info'>

                <div style='width:100%;padding-bottom: 0'>
                  <span style='font-size: 9px;'><span class='green' style='color: #20c997;font-size: 6px;'><i class='icon-record'></i></span> места есть</span>
                </div>

                <router-link :to="`/excursion/${row.excursion_alias}/`"><b>{{row.excursion_title}}</b></router-link>
                <span style='display:block;font-size: 11px;'>
                {{row.excursion_date}},
                {{row.excursion_adults}} взр.
                 <template v-if="row.excursion_children">
                   {{row.excursion_children}} реб.
                 </template>
                , {{ formatRUB(row.excursion_item_sum) }}
              </span>
              </div>
              <div class='basket-del-item'>
              <span>
                <i class="fas fa-times order-row-delete" @click="removeCartItem(index)"></i>
              </span>
              </div>
            </div>
          </div>

          <hr/>
          <div class='row price-row'>
            <div class='col-12 cab-basket-sum'>ВСЕГО НА СУММУ <span class='orders_sum'>{{ formatRUB(Math.ceil(cartSum / 95 * 100)) }}</span></div>
          </div>

          <div class='row price-row'>
            <div class='col-12 cab-basket-sum' style='background:#ffeaea;'>Сумма скидок <span class='orders_sum'>{{ formatRUB(Math.ceil(cartSum / 95 * 5)) }}</span></div>
          </div>

          <div class='row price-row'>
            <div class='col-12 cab-basket-sum' style='background:#fff;'>Сумма с учетом скидок <span class='orders_sum'>{{ formatRUB(Math.ceil(cartSum)) }}</span></div>
          </div>

          <div class='row price-row'>
            <div class='col-12 cab-basket-sum' style='background: #fff;'>Итого <span class='orders_sum'><b>{{ formatRUB(Math.ceil(cartSum)) }}</b></span></div>
          </div>

          <hr/>

          <div class="row price-row">
            <div class="col-12" style="padding: 0">
              <router-link to="/booking" class="btn_full">Оформить заказ</router-link>
            </div>
          </div>

        </template>



        <div class="row" v-else>
          <div class="load-orders"></div>
          <div class="title_no_txt"><h2>Корзина пуста!</h2>Вы не отобрали ни одного товара или услуги для формирования заказа</div>
        </div>
      </div>
    </div>
  </div>



</template>

<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from "@/helpers/ajax";


export default {
  mixins: [mixins],

  created () {
    document.title = 'Корзина - личный кабинет';
    this.getCart()
  },

  components: {
  },


  data () {
    return {
      cartSum: 0,
      cart: []
    }
  },

  methods: {
    removeCartItem(i) {
      let cart = JSON.parse(localStorage.cart);
      cart.splice(i, 1);
      localStorage.cart = JSON.stringify(cart);
      this.getCart();
    },
    async getCart() {
      this.cart = [];
      this.cartSum = 0;

      if (!localStorage.cart) {
        localStorage.cart = ''
      } else {
        if (localStorage.cart.length) {
          let ex_ids = [];
          JSON.parse(localStorage.cart).find(i => {ex_ids.push(i.excursion_id)})
          let result = await Ajax.report('/excursion/list?filters[excursion_id]=ins|'+ ex_ids.join(',') +'&page=1&limit=100');

          if (result.response) {
            JSON.parse(localStorage.cart).find(i => {
              result.response.rows.find(ex => {
                if (i.excursion_id === ex.excursion_id) {
                  let itemSum = Math.ceil((
                      (ex.excursion_price_adult * i.excursion_adults) + (ex.excursion_price_children_free * i.excursion_children_count1) + (ex.excursion_price_children * i.excursion_children_count2)
                  ) / 100 * 95);
                  this.cart.push(
                      Object.assign(i, ex, {excursion_item_sum: itemSum})
                  )

                  this.cartSum = Math.ceil(this.cartSum + itemSum)
                }
              })
            });
          }
        }
      }
    },
  }
}

</script>