<template>

  <div class="load-cabinet">
    <div class="col-lg-12 add_bottom_15">
      <div class="form_title">
        <h3><strong><div font-awesome-icon icon="fas fa-gift" /></strong>Бонусный счет</h3>
        <p>Список всех полученых и потраченых бонусов</p>
      </div>
      <div class="step">
        <div class="row">
          <div class="load-orders"></div>
          <div class="title_no_txt"><h2>Счет пуст</h2>Начислений и списаний по вашему бонусному счету нет</div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import mixins from '../../../helpers/mixins.js';


export default {
  mixins: [mixins],

  created () {
    document.title = 'Бонусный счёт - личный кабинет';
  },

  components: {
  }
}

</script>
