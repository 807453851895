<template>
  <div>
    <Report
        ref="report"
        url="orders/list"
        :def-params="{order: {field: 'order_id', order: 'desc'}, filters: {account_id: 'eq|' + account}}"
        v-on:loading="reportLoading"
        v-on:done="reportLoaded"
    >
      <div v-if="error">
        {{error}}
      </div>

      <template v-if="report">
        <template v-if="report.rows_count === 0">
          <h2>Заказов нет</h2>
          <p>Вы еще не оформили ни одного заказа на нашем сайте</p>
        </template>

        <div class="orders" v-else>
          <div class="item" v-for="(row, index) in report.rows" v-bind:key="index">
            <div>
              <h3>
                <a v-on:click.prevent="showInfo = row">
                  Заказ № {{row.order_id}}
                </a>
              </h3>
              <p class="mb20">
                  от {{convertDate(row.order_date, 'DD.MM.YYYY HH:mm')}}
              </p>

                <div class="prices mb20">
                  <div class="prices-item">
                    {{row.order_pay_sum ? formatRUB(row.order_pay_sum) : formatRUB(row.order_sum_all)}}
                    <div>
                      Сумма
                    </div>
                  </div>

                  <div class="prices-item">
                    {{status[row.order_status]?.title}}
                    <div>
                      Статус
                    </div>
                  </div>
                </div>

             <div>
               <a class="btn btn-primary" v-if="['pending'].indexOf(row.order_status) > -1" v-on:click.prevent="showPay = row">
                 Оплатить
               </a>
             </div>

            </div>
          </div>
        </div>
      </template>

    </Report>

    <template v-if="showPay">
      <div class="window-bg" v-on:click="showPay = false"></div>
      <div class="window">
        <div class="window-header">Оплата заказа №{{showPay.order_id}} от {{convertDate(showPay.order_date, 'DD.MM.YYYY HH:mm')}}</div>
        <div class="text">
          <a
              class="btn btn-primary mb20"
              :href="`https://yoomoney.ru/checkout/payments/v2/contract?orderId=${showPay.order_pay_resp_id}`"
          >
            Оплатить {{formatRUB(showPay.order_sum_all)}}
          </a>
          <div>
            *оплата производится через сервис
            <a href="https://yookassa.ru" target="_blank">ЮKassa (Сбербанк)</a>
          </div>
        </div>
      </div>
    </template>

    <template v-if="showInfo">
      <div class="window-bg" v-on:click="showInfo = false"></div>
      <div class="window">
        <div class="window-header">Информация о заказе № {{showInfo.order_id}}</div>

        <div class="text">
        <h3 class="mt0">
          <router-link :to="`/anapa/excursion/`">
            {{showInfo.order_title}}
          </router-link>
        </h3>
          <div>
            отправление
            {{convertDate(showInfo.order_date_ot)}},
            {{showInfo.order_adult + (showInfo.order_children_2 || 0) + (showInfo.order_children_3 || 0) + (showInfo.order_children_free || 0)}} чел.
            (
            {{showInfo.order_adult}} взр.
            <template v-if="showInfo.order_children_1 + (showInfo.order_children_2 || 0) + (showInfo.order_children_3 || 0) + (showInfo.order_children_free || 0)">
              {{showInfo.order_children_1 + (showInfo.order_children_2 || 0) + (showInfo.order_children_3 || 0) + (showInfo.order_children_free || 0)}} реб.
            </template>
            )
          </div>
        </div>

      </div>
    </template>
    <template v-if="showCancel">
      <div class="window-bg" v-on:click="showCancel = false"></div>
      <div class="window text">
        <template v-if="!checkDate(showCancel.order_date_ot,showCancel.excursion_refund)">
          <div class="window-header">Отмена заказа невозможна!</div>
          <div>
            Время и дата возможной отмены, согласно<br>
            условиям бронирования, истекло<br>
            <b>{{getExpDate(showCancel.order_date_ot,showCancel.excursion_refund)}}</b>
          </div>
        </template>
        <template v-if="checkDate(showCancel.order_date_ot,showCancel.excursion_refund) && ['waiting_for_capture'].indexOf(showCancel.order_status) > -1">
          <div class="window-header">Ваш заказ отменен!</div>
          <div>
            Зарезервированые денежные средства будут возращены вам на счет в ближайшее время
          </div>
        </template>
        <template v-if="checkDate(showCancel.order_date_ot,showCancel.excursion_refund) && ['pending'].indexOf(showCancel.order_status) > -1">
          <div class="window-header">Ваш заказ отменен!</div>
        </template>
        <template v-if="checkDate(showCancel.order_date_ot,showCancel.excursion_refund) && ['order'].indexOf(showCancel.order_status) > -1">
          Началась процедура отмены заказа.<br/>
          По мере завершения который мы проинформируем вас о ее результатах.
        </template>
      </div>
    </template>
</div>


</template>

<script>
import mixins from '../../../helpers/mixins.js';

import moment from "moment";
import Report from '../../../components/report/Report';
import $ from "jquery";
import Ajax from "@/helpers/ajax";


export default {
  mixins: [mixins],

  created () {
    document.title = 'Заказы - личный кабинет';

    $(document).off('click', '.load-cabinet .mfp-wrap').on('click', '.load-cabinet .mfp-wrap', function (e) {
      if ($(e.target).hasClass('mfp-container') || $(e.target).hasClass('mfp-content')) {
        this.showCart = 0;
        this.showLogin = false;
        this.showInfo = false;
        this.showCancel = false;
      }
    }.bind(this))
  },

  props: {
    account: {
      type: Number,
      default: 0,
    }
  },

  components: {
    Report
  },
  data () {
    return {
      data: {},
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован',

        order: {
          title: 'Заказ оплачен'
        },
        cancel: {
          title: 'Заказ отменён'
        },
        cancel_refund: {
          title: 'Заказ отменён'
        },
        cancel_success: {
          title: 'Заказ отменён, деньги возвращены'
        },
        waiting_for_capture: {
          title: 'Заказ оплачен, ожидает подтверждения'
        },
        pending: {
          title: 'Заказ ожидает оплаты'
        }
      },
      error: null,
      report: null,
      showCancel: false,
      showInfo: false,
      showPay: false
    }
  },

  methods: {

    getExpDate(date, hours) {
      return moment(date).add(-hours, 'hours').format('DD.MM.YYYY HH:mm');
    },

    checkDate(date, hours) {

      let currentDate = new Date().getTime();
      let endDate = moment(date).add(-hours, 'hours').unix() * 1000

      return currentDate < endDate;


    },

    async orderCancel(row) {


      if (this.checkDate(row.order_date_ot, row.excursion_refund)) {

        let yes = confirm("Вы действительно хотите отменить заказ?");

        if (yes) {
          row.status = 'cancel';
          await Ajax.post(`/order/cancel/${row.order_id}`, row);
          this.showCancel = row;
          this.getReport().reload();
        }

      } else {
        this.showCancel = row
      }
    },
    getReport() {
      return this.$refs.report;
    },

    reportLoading() {
      this.error = null;
    },
    reportLoaded(report) {

      if (report) {
        if (report.response) {
          this.report = report.response;
          this.error = null;
        } else {
          this.report = null;
          this.error = 'Неизвестная ошибка.';
        }
      }
    },
  }
}

</script>