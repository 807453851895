<template>

  <div class="load-cabinet">
    <div class="col-lg-12 add_bottom_15 cabinet">
      <div class="form_title">
        <h3><strong style="background-color: #ff8f00;"><div font-awesome-icon icon="fab fa-gripfire" /></strong>Важные сообщения</h3>
        <p>Сообщения и рекомендации по ведению вашего профиля</p>
      </div>
      <div class="step">
        <div class="row"></div>
      </div>

      <div class="form_title">
        <h3><strong><div font-awesome-icon icon="fas fa-gift" /></strong>Акции</h3>
        <p>Участвуйте в наших закрытых акциях и розыгрышах</p>
      </div>
      <div class="step">
        <div class="row">
          <p>
            Оценивайте товары из списка этой акции Получайте бонусы после публикации отзыва
          </p>
        </div>
        <div class="row">
          <div class="banner_3" style="background: url(/img/adw/fullsize/abhazia-tours.webp) center center no-repeat;background-size: cover;">
            <div class="wrapper d-flex align-items-center opacity-mask" style="background-color: rgba(0, 0, 0, 0.3); ">
              <div>
                <h3 style="font-size:25px;">Бонусы за отзывы</h3>
                <p style="font-size:16px;">расскажите о своем отдыхе, получите бонусы или подарок</p>
                <a href="/cabinet/#basket" class="cab_link_hash"></a>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import mixins from '../../../helpers/mixins.js';



export default {
  mixins: [mixins],

  created () {
    document.title = 'Личный кабинет';
  },

  components: {
  }
}

</script>
