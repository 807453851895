import { createWebHistory, createRouter } from "vue-router";
import user from "./user";
import Login from "./pages/auth/Login";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";
import DefaultHome from "./pages/home/Index";
import About from "./pages/about/Index";
import AboutContacts from "./pages/about/Contacts";
import AboutOffer from "./pages/about/Offer";
import AboutRefund from "./pages/about/Refund";
import AboutPointSale from "./pages/about/PointSale";
import AboutPrivacy from "./pages/about/Privacy";
import Excursion from "./pages/excursion/Excursion";
import ExcursionShow from "./pages/excursion/Show";

import Blog from "./pages/blog/Index";
import PostShow from "./pages/blog/Show";
import Cabinet from "./pages/cabinet/Index";
import Wishlist from "./pages/wishlist/Index";
import Booking from "./pages/booking/Index";
import BookingCheck from "./pages/booking/Check";
import Info from "./pages/booking/i";
import Error404 from "./pages/error/404";
import Ajax from "@/helpers/ajax";

export default async function() {
    await user.loadCurrentUser();

    const router = createRouter({
        history: createWebHistory(),
        base: __dirname,
        routes: [


            /* About */
            { path: '/about', component: About },
            { path: '/about/contacts', component: AboutContacts },
            { path: '/about/offer', component: AboutOffer },
            { path: '/about/refund', component: AboutRefund },
            { path: '/:city/about/point-sale', component: AboutPointSale },
            { path: '/about/privacy', component: AboutPrivacy },

            /* Booking */
            { path: '/booking', component: Booking },
            { path: '/check/:id', component: BookingCheck },

            { path: '/i/:id', component: Info },

            /* Excursion */
            { path: '/:city/excursion', component: Excursion },
            { path: '/:city/excursion/:category', component: Excursion },
            { path: '/:city/excursion/:category/:excursion', component: ExcursionShow },

            /* Blog */
            { path: '/:city/blog', component: Blog },
            { path: '/:city/blog/:category', component: Blog },
            { path: '/:city/blog/:category/:post', component: PostShow },

            /* Auth */
            { path: '/auth/login', component: Login },
            { path: '/auth/forgot', component: Forgot },
            { path: '/auth/reset', component: Reset },

            /* Cabinet */
            { path: '/cabinet', component: Cabinet },
            { path: '/cabinet/:page', component: Cabinet },

            /* Wishlist */
            { path: '/wishlist', component: Wishlist },
            { path: '/', component: DefaultHome, name: 'home' },
            { path: '/:city', component: DefaultHome },

            { path: '/logout', component: DefaultHome },
            /* Other */
            { path: '/404', component: Error404 },

            { path: '/404:pathMatch(.*)*', name: 'NotFound', component: Error404 },


        ]
    });

    router.afterEach(() => {
        let cl = 'https://vizit.tours' + window.location.pathname

        if (window.location.pathname === '/anapa' || window.location.pathname === '/anapa/') {
            cl = 'https://vizit.tours/'
        }

        if (cl.slice(-1) !== '/') {
            cl = cl + '/'
        }

        document.getElementById('cl').setAttribute('href', cl)
    })
    router.beforeEach(async (to, from, next) => {

        if (['/logout/'].indexOf(to.path) > -1) {
            user.logout()
            next({path: '/'});
        }

        if (to.query.p && to.query.p > 0) {
            window.localStorage.setItem('partner', to.query.p)

            let sid = await Ajax.post('/v/', {
                p: to.query.p,
                sid: window.localStorage.vsid || null
            });

            if (sid.response) {
                window.localStorage.setItem('vsid', sid.response)
            }
            next({path: '/anapa/excursion'});
        }
        if (to.params.city) {

            if (to.params.city === 'logout') {
                user.logout()
                next({path: '/'});
            }

            if (!localStorage.getItem('c_code')) {
                localStorage.setItem('c_code', 'anapa')
            }
            let ct = ['anapa', 'vityazevo', 'dzhemete']

            if (localStorage.getItem('c_code') !== to.params.city && ct.indexOf(to.params.city) > -1) {
                localStorage.setItem('c_code', to.params.city)
            }
        }

        if (user.currentUser.is_logged_in && user.currentUser.account.account_status !== 'active') {
            user.logout();
            window.location.href = '/'
        }

        if (!from.params.excursion && from.fullPath.indexOf('/excursion') > -1) {
            window.ex_top = window.scrollY;
        }

        if (!to.params.excursion && from.fullPath.indexOf('/excursion') > -1 && window.ex_top) {

            window.scrollTo(0, window.ex_top || 0);

            setTimeout(function () {
                window.scrollTo(0, window.ex_top || 0);
            }, 100)

            setTimeout(function () {
                window.scrollTo(0, window.ex_top || 0);
            }, 200)

            setTimeout(function () {
                window.scrollTo(0, window.ex_top || 0);
                window.ex_top = 0;
            }, 300)

        } else {
            if (to.fullPath.indexOf('cabinet') === -1) {
                window.scrollTo(0, 0)
            } else {
                if (window.outerWidth < 480) {
                    window.scrollTo(0, 645)
                }
            }
        }

        next();

    });

    return router;
}
