import { createApp } from 'vue'

let cities = [
  {
    city_id: 1,
    city_code: 'anapa',
    city_title: 'Анапа'
  },
  {
    city_id: 2,
    city_code: 'vityazevo',
    city_title: 'Витязево'
  },
  {
    city_id: 3,
    city_code: 'dzhemete',
    city_title: 'Джемете'
  }
];

localStorage.setItem('cities', JSON.stringify(cities))

import App from './pages/App';
import routes from './routes';

import VueCookies from 'vue-cookies'
import mitt from 'mitt';
import mask from 'vue-the-mask'
import VLazyImage from "v-lazy-image";
import VueScrollactive from 'vue-scrollactive';
const emitter = mitt();


if (!process.env.VUE_APP_SERVER_URL) {
  throw new Error('VUE_APP_SERVER_URL is undefined');
}
if (!process.env.VUE_APP_IMAGE_ADDRESS) {
  throw new Error('VUE_APP_IMAGE_ADDRESS is undefined');
}

routes().then((router) => {
  global.router = router;
  const app = createApp(App)
  app.use(router)
  app.use(VueCookies)
  app.use(mask)
  app.component('v-lazy-image', VLazyImage)
  app.component('Scrollactive', VueScrollactive);
  app.config.globalProperties.emitter = emitter;
  app.config.globalProperties.domain = process.env.VUE_APP_IMAGE_ADDRESS;
  app.mount('#app');
});
