<template>
  <nav class="tabs">
    <router-link class="tab" to="/cabinet/orders/">
      Мои заказы <i>{{orders}}</i>
    </router-link>
    <router-link class="tab" to="/cabinet/profile/">
      Профиль
    </router-link>
  </nav>
</template>

<script>

export default {
  components: {
  },

  props: {
    orders: {
      type: Number,
      default: 0,
    }
  },


  data () {
    return {
      data: {},
      wishlistCount: 0,
      cart: localStorage.cart.length ? JSON.parse(localStorage.cart).length : 0
    }
  },

  methods: {

    setWishlistCount() {
      let w = localStorage.wishlist !== '' ? localStorage.wishlist.split(',') : '';
      this.wishlistCount = w.length;
    }
  },

  mounted () {
    this.setWishlistCount()
  },

  created () {
    this.setWishlistCount()
  },

}
</script>