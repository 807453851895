<template>
  <div class="w">

    <div class="page-header">
      <h1 class="mb0">
        {{data.post.blog_post_title}}
      </h1>
    </div>
    <nav class="breadcrumb">
      <router-link to="/">Главная</router-link>
      <router-link :to="`/${getCityCode()}/blog/`">Наш блог</router-link>
      <router-link :to="'/' + getCityCode() + '/blog/' + data.category.blog_category_url+'/'">{{data.category.blog_category_title}}</router-link>
      <span>{{data.post.blog_post_title}}</span>
    </nav>

    <component :is="'script'" type="application/ld+json">
      {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "{{getCityTitle()}}",
      "item": "https://vizit.tours{{`/${getCityCode()}/`}}"
      },
      {
      "@type": "ListItem",
      "position": 2,
      "name": "Блог",
      "item": "https://vizit.tours{{`/${getCityCode()}/blog/`}}"
      },
      {
      "@type": "ListItem",
      "position": 3,
      "name": " {{data.category.blog_category_title}}",
      "item": "https://vizit.tours{{'/' + getCityCode() + '/blog/' +category+'/'}}"
      },
      {
      "@type": "ListItem",
      "position": 4,
      "name": " {{data.post.blog_post_title}}",
      "item": "https://vizit.tours{{'/' + getCityCode() + '/blog/' +category+'/' +data.post.blog_post_url+'/'}}"
      }
      ]
      }
    </component>

    <main>
      <div class="post text">
        <v-lazy-image v-if="data.post.blog_post_image" :src="'/img/blog/list/' + data.post.blog_post_image" :alt="data.post.blog_post_title" class="img-fluid" />
        <div class="date">
          Опубликовано: {{convertDate(data.post.ts)}}
        </div>
        <div class="post-text" v-html="data.post.blog_post_text"></div>
      </div>
    </main>

  </div>
</template>
<script>

import mixins from '../../helpers/mixins.js';
import Ajax from '../../helpers/ajax';
import $ from "jquery";

export default {
  mixins: [mixins],

  components: {
  },

  watch: {
    '$route'() {
      this.category = this.$route.params.category || 'all';
      this.post = this.$route.params.post || null;

      if (this.category !== 'all') {
        this.loadCategory();
        this.loadPost();
      }
    }
  },

  created() {
    if (this.category !== 'all') {
      this.loadCategory();
      this.loadPost();
    }
  },

  data() {
    return {
      data: {
        category: {
          category_id: null,
          category_title: '',
          category_page_title: '',
          category_page_subtitle: '',
          category_image: '',
        },
        post: {
          excursion_images: [],
          object_price_data: [],
          object_data: []

        }
      },
      error: null,
      report: null,
      category: this.$route.params.category || 'all',
      post: this.$route.params.post || null
    }
  },
  methods: {

    async loadCategory() {
      let result = await Ajax.get('/s/blog-category/'+  this.category);
      if (result.response && result.response.blog_category_id) {
        this.data.category = result.response;
      } else {
        window.location.href = '/404/'
      }
    },

    async loadPost() {
      let result = await Ajax.get('/blog/get-alias/'+  this.post);
      if (result.response && result.response.blog_post_id) {
        this.data.post = result.response;

        document.title = result.response.blog_post_seo_title ? result.response.blog_post_seo_title : result.response.blog_post_title;

        $('meta[name="description"]').attr('content', result.response.blog_post_seo_desc ? result.response.blog_post_seo_desc : result.response.blog_post_text ? result.response.blog_post_text.replace(/<(.|\n)*?>/g, '').slice(0, 155) : $('meta[name="description"]').attr('content'))
        $('meta[name="keywords"]').attr('content', result.response.blog_post_seo_keyword ? result.response.blog_post_seo_keyword : $('meta[name="keywords"]').attr('content'))

      } else {
        window.location.href = '/404/'
      }
    },


    reportLoading() {
      this.error = null;
    },


  }
}



</script>