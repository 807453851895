<template>
  <div class="window-target">
    <ImageHeader
        title="Информация об оплате"
        desc=""
    />

    <main class="w">

      <nav class="breadcrumb">
        <router-link to="/">Главная</router-link>
        <span class="bread-current">Информация об оплате</span>
      </nav>

      <div class="container margin_60">

        <template v-if="status === 'loading'">
          <div class='row'>
            <div class='col-12 text-center' style='margin:60px 0;'>
              <i class='icon-meh' style='font-size:120px;'></i>
              <h3 style='font-size: 30px;'>Подождите немножко...</h3>
              <p style='font-size: 14px;'>
                <v-lazy-image src="/img/loading.gif" />
              </p>
            </div>
          </div>
        </template>

        <template v-if="status === 'pending'">
          <div class='row'>
            <div class='col-12 text-center' style='margin:60px 0;'>
              <i class='icon-meh' style='font-size:120px;'></i>
              <h3 style='font-size: 30px;'>Что то пошло не так...</h3>
              <p style='font-size: 14px;'>
                Ваш заказ №{{this.$route.params.id}} от {{convertDate(this.$route.params.id, 'DD.MM.YYYY в HH:mm', true)}} не был оплачен.<br>
                Оформление заказа не гарантирует резервацию услуг, если заказ не оплачен.<br>
                Условия и наличие мест по сформированному заказу могут быть другими в момент отложеной оплаты.
                <br>
                <br>
                Оплатить сформированный заказ вы можете через свой <router-link to="/cabinet/">личный кабинет</router-link>.
                <br>
                <br>
                Если у Вас возникли трудности при оплате или другие вопросы по заказу,
                <br>
                позвоните нам по бесплатному телефону
                <br/>
                <br/>
                <a href="tel://+79883406585" style="font-size:20px;font-weight: bold">+7 (988) 340-65-85</a>
              </p>
            </div>
          </div>
        </template>

        <template v-if="status === 'waiting_for_capture'">
          <div class='row'>
            <div class='col-12 text-center' style='margin:60px 0;'>
              <i class='icon-smile' style='font-size:120px;'></i>
              <h3 style='font-size: 30px;'>Платеж подтвержден</h3>
              <p style='font-size: 14px;'>
                <b>Деньги на оплату заказа зарезервированы сервисом Яндекс.Касса.</b>
                <br/>
                <br/>
                Списание денежных средств возможно только после подтверждения заказа, которое мы пришлем Вам в СМС.
                <br/>
                В случае невозможности оформления заказа денежные средства будут возращены Вам на карту.
                <br/>
                Оформление и подтверждение заказа занимает не более 15 минут в рабочее время.
              </p>
              <p>
                Информация о состоянии статуса ваших заказов всегда доступна в вашем личном кабинете или билете.
              </p>
              <router-link v-if="pay_id" :to="'/i/' + pay_id" class='btn_full' style='color:#fff;width: 220px;margin-left:calc(50% - 110px);'>
                Ваш билет
              </router-link>
              <router-link to='/cabinet' class='btn_full' style='color:#fff;width: 220px;margin-left:calc(50% - 110px);'>
                Перейти в Кабинет
              </router-link>
            </div>
          </div>
        </template>

        <template v-if="status === 'succeeded'">
          <div class='row'>
            <div class='col-12 text-center' style='margin:60px 0;'>

              <i class='icon-smile' style='font-size:120px;'></i>
              <h3 style='font-size: 30px;'>Платеж подтвержден</h3>
              <p style='font-size: 14px;'>
                <b>Оплата по заказу №{{this.$route.params.id}} от {{convertDate(this.$route.params.id, 'DD.MM.YYYY в HH:mm', true)}} успешно завершена.</b>
                <br/>
                После обработки заказа, мы пришлем вам на указанный номер телефона подтверждение и ссылку на туристический ваучер (билет).
              </p>
              <router-link v-if="pay_id" :to="'/i/' + pay_id" class='btn_full' style='color:#fff;width: 220px;margin-left:calc(50% - 110px);'>
                Ваш билет
              </router-link>
              <br/>
              <p>
                Оформление и подтверждение заказа занимает не более 15 минут в рабочее время.
              </p>
              <p>
                Информация о состоянии статуса ваших заказов всегда доступна в вашем личном кабинете.
              </p>



              <router-link to='/cabinet' class='btn_full' style='color:#fff;width: 220px;margin-left:calc(50% - 110px);'>
                Перейти в Кабинет
              </router-link>
            </div>
          </div>
        </template>

        <template v-if="status === 'canceled'">
          <div class='row'>
            <div class='col-12 text-center' style='margin:60px 0;'>

              <i class='icon-meh' style='font-size:120px;'></i>
              <h3 style='font-size: 30px;'>Что то пошло не так...</h3>
              <p style='font-size: 14px;'>
                Ваш заказ №{{this.$route.params.id}} от {{convertDate(this.$route.params.id, 'DD.MM.YYYY в HH:mm', true)}} не был оплачен.
                <br>
                <br/>
                Оформление заказа не гарантирует резервацию услуг, если заказ не оплачен.
                <br/>
                Условия и наличие мест по сформированному заказу могут измениться в момент отложеной оплаты.
                <br/>
                <br/>
                Оплатить сформированный заказ вы можете через свой
                <router-link to='/cabinet'>личный кабинет</router-link>

              </p>
            </div>
          </div>
        </template>

        <template v-if="status === 404">
          <div class='row'>
            <div class='col-12 text-center' style='margin:60px 0;'>

              <i class='icon-meh' style='font-size:120px;'></i>
              <h3 style='font-size: 30px;'>Что то пошло не так...</h3>
              <p style='font-size: 14px;'>
                Срок действия кода для оплаты истёк или не существует.
              </p>
            </div>
          </div>
        </template>


      </div>
    </main>

  </div>
</template>

<script>

import mixins from '../../helpers/mixins.js';

import ImageHeader from "@/pages/default/ImageHeader";
import Ajax from "@/helpers/ajax";

export default {
  mixins: [mixins],

  components: {
    ImageHeader,

  },

  watch: {

  },

  data () {
    return {
      data: {},
      status: 'loading',
      pay_id: null
    }
  },


  mounted () {
    setTimeout(function () {
      this.check()
    }.bind(this), 8000)
  },

  methods: {

    async check() {

      let result = await Ajax.post('/order/check/'+  this.$route.params.id);

      if (result.error) {
        this.status = result.error.code
      }
      if (result.response) {
        this.status = result.response.status;
        this.pay_id = result.response.pay_id;
      }

    }

  }
}

</script>

