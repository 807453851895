<template>
  <div class="w page">

    <div class="page-header">
      <h1 class="mb0" v-if="category === 'all'">
        Блог - {{ getCityTitle(2) }}
      </h1>
      <h1 class="mb0" v-else>
        {{ data.category.blog_category_page_title }}
      </h1>
    </div>

    <main>

      <nav class="breadcrumb">
        <router-link to="/">Главная</router-link>
        <router-link v-if="category !== 'all'" :to="`/${getCityCode()}/blog/`">
          Наш блог
        </router-link>
        <span v-else class="bread-current">
          Наш блог
        </span>
        <span v-if="category !== 'all'" class="bread-current">
          {{data.category.blog_category_title}}
        </span>
      </nav>

      <component :is="'script'" type="application/ld+json">
        {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "{{getCityTitle()}}",
        "item": "https://vizit.tours{{`/${getCityCode()}/`}}"
        },

        {
        "@type": "ListItem",
        "position": 2,
        "name": "Блог",
        "item": "https://vizit.tours{{`/${getCityCode()}/blog/`}}"
        },
        {
        "@type": "ListItem",
        "position": 3,
        "name": " {{data.category.blog_category_title || 'Все записи'}}",
        "item": "https://vizit.tours{{'/' + getCityCode() + '/blog/' +category+'/'}}"
        }

        ]
        }
      </component>

      <Search />

      <Report
          ref="report"
          url="/blog/list"
          lazy-report="route"
          :key="category"
          :def-params="{
                  order: {
                    field: 'blog_post_id',
                    order: 'desc'
                  },
                  filters: {
                    blog_category_url: ((category && category !== 'all') ? 'eq' : 'ignore') + '|' + category
                  }
                }"
          v-on:loading="reportLoading" v-on:done="reportLoaded">
        <Loading v-if="!report" />
        <div v-if="error">
          {{error}}
        </div>
        <template v-if="report">
          <div v-if="report.rows_count === 0"></div>
          <template v-else>
            <div class="post text" v-for="(row, index) in report.rows" v-bind:key="index">
              <h2>
                <router-link :to="'/' + getCityCode() + '/blog/' + row.blog_category_url + '/' + row.blog_post_url + '/'">
                  {{row.blog_post_title}}
                </router-link>
              </h2>
              <router-link v-if="row.blog_post_image" :to="'/' + getCityCode() + '/blog/' + row.blog_category_url + '/' + row.blog_post_url + '/'">
                <v-lazy-image :src="domain+'/img/blog/list/' + row.blog_post_image" :alt="row.blog_post_title" />
              </router-link>
              <div class="desc">
                {{convertDate(row.ts)}}
                <router-link :to="'/' + getCityCode() + '/blog/' + row.blog_category_url + '/'">{{row.blog_category_title}}</router-link>
              </div>
            </div>
          </template>
          <Pager />
        </template>
      </Report>
    </main>
  </div>
</template>
<script>

import mixins from '../../helpers/mixins.js';

import Ajax from '../../helpers/ajax';
import Search from './_search';
import Loading from '../../components/report/Loading';
import Report from '../../components/report/Report';
import Pager from '../../components/Pager';
import $ from "jquery";

export default {
  mixins: [mixins],

  components: {
    Search,
    Loading,
    Pager,
    Report
  },

  watch: {
    '$route'() {
      if (this.category !== this.$route.params.category) {
        this.category = this.$route.params.category || 'all';
        if (this.category !== 'all') {
          this.loadCategory();
        } else {
          document.title = 'Блог - ' + this.getCityTitle(2);
          $('meta[name="description"]').attr('content', 'Новости компании, анонсы и отчеты с различных мероприятий в ' + this.getCityTitle(1))
        }
      }
    }
  },

  created() {
    if (this.category !== 'all') {
      this.loadCategory();
    } else {
      document.title = 'Блог - '+  this.getCityTitle(2);
      $('meta[name="description"]').attr('content', 'Новости компании, анонсы и отчеты с различных мероприятий в ' + this.getCityTitle(1))
    }
  },

  data() {
    return {
      data: {
        category: {
          category_id: null,
          category_title: '',
          category_page_title: '',
          category_page_subtitle: '',
          category_image: ''
        }
      },
      error: null,
      report: null,
      category: this.$route.params.category || 'all',
    }
  },
  methods: {

    getReport() {
      return this.$refs.report;
    },

    async loadCategory() {
      let result = await Ajax.get('/s/blog-category/'+  this.category);
      if (result.response && result.response.blog_category_id) {
        this.data.category = result.response;
        document.title = result.response.blog_category_seo_title ? result.response.blog_category_seo_title : (this.category === 'all' ? 'Все новости '+ this.getCityTitle() : result.response.blog_category_title);

        if (result.response.blog_category_seo_desc) {
          result.response.blog_category_seo_desc = result.response.blog_category_seo_desc.replace(/Анапе/ig, this.getCityTitle(1))
        } else {
          result.response.blog_category_seo_desc = 'Досуг и шопинг, анонсы и отчеты с различных мероприятий в ' + this.getCityTitle(1)
        }

        $('meta[name="description"]').attr('content', result.response.blog_category_seo_desc ? result.response.blog_category_seo_desc : result.response.blog_category_page_subtitle ? result.response.blog_category_page_subtitle : (this.category === 'all' ? 'Все новости '+ this.getCityTitle() : result.response.blog_category_title) )
        $('meta[name="keywords"]').attr('content', result.response.blog_category_seo_keyword ? result.response.blog_category_seo_keyword : $('meta[name="keywords"]').attr('content'))

      } else {
        window.location.href = '/404/'
      }
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;
    },

    reportLoaded(report) {

      if (report) {
        if (report.response) {
          this.report = report.response;
          this.error = null;
        } else {
          this.report = null;
          this.error = 'Неизвестная ошибка.';
        }
      }
    },
  }
}



</script>