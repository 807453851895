<template>
  <div class="w page">

    <div class="page-header">
      <h1 class="mb0">Контакты</h1>
    </div>

    <main>

      <nav class="breadcrumb">
        <router-link to="/">Главная</router-link>
        <router-link to="/about/">Информация о компании</router-link>
        <span class="bread-current">Контакты</span>
      </nav>

      <AboutMenu />

      <div class="flex contacts">
        <div class="mr80">

          <h4>Обратная связь</h4>
          <p>Воспользуйтесь формой для отправки нам сообщения</p>

          <form v-if="!sended">

            <div class="input">
              <label class="input-placeholder">Как вас зовут?</label>
              <input type="text" class="form-control" name="q_name" v-model="q.q_name" placeholder="Как вас зовут?">
            </div>

            <div class="flex flex-g2">
              <div class="input">
                <label class="input-placeholder">Электронная почта</label>
                <input type="email" name="q_mail" class="form-control" v-model="q.q_mail" placeholder="Ваша электронная почта">
              </div>
              <div class="input">
                <label class="input-placeholder">Телефон</label>
                <input type="text" name="q_phone" class="form-control" v-mask="phoneMask" v-model="phone" placeholder="Номер телефона">
              </div>
            </div>

            <div class="input">
              <label class="input-placeholder">Сообщение</label>
              <textarea rows="5" name="q_message" class="form-control" v-model="q.q_message" placeholder="Напишите сообщение" style="height:200px;" data-rule="text"></textarea>
            </div>


            <div>
              <label class="checkbox">
                <input type="checkbox" v-model="q.check" class="form-control validate" name="check">
                <i></i>
                подтверждаю свое согласие на обработку персональных данных
              </label>
            </div>

            <button v-on:click.prevent="save" type="submit" value="Отправить" class="btn btn-primary" :disabled="(saved ? true : (valid ? false : true))">
              <template v-if="saved">
                Подождите
              </template>

              <template v-else>
                Отправить сообщение
              </template>
            </button>

          </form>

          <template v-else>
            Спасибо за обращение, в ближайшее время мы вам ответим.
          </template>


        </div>
        <div>
          <h4>Адрес офиса </h4>
          <p><a href="https://yandex.ru/maps/org/vizit/145490521719/?ll=37.316206%2C44.894958&z=12">Краснодарский край, Анапа, ул. Омелькова 14в</a></p>
          <h4>Телефоны </h4>
          <p>Центр поддержки клиентов работает ежедневно с 08:00 до 22:00, вам всегда оперативно ответят и окажут помощь</p>

          <h4 class="mb0">
            <a href="tel://88003332821">
              +7 (988) 340-65-85
            </a>
          </h4>
          <div>бесплатный номер телефона</div>

        </div>
      </div>


    </main>
  </div>
</template>
<script>

import AboutMenu from './_menu';
import User from '../../user';
import Ajax from "@/helpers/ajax";
import $ from "jquery";

export default {

  created() {
    document.title = 'Контакты';
    $('meta[name="description"]').attr('content', 'Контактная информация компании ТЭФ Визит')
  },

  watch: {
    'q.q_name'(){
      this.checkForm();
    },
    'q.q_mail'(){
      this.checkForm();
    },
    'q.q_message'(){
      this.checkForm();
    },
    'q.check'(){
      this.checkForm();
    },
    'phone'(s, e) {

      if (s.length < e.length) {
        if (e[e.length-1] === '-' || e[e.length-1] === '(' || e[e.length-1] === ')') {
          this.phone = this.phone.substring(0, s.length - 1)
        }
      }

      this.phone = this.phone
          .replace(/^8/, "7")
          .replace(/^9/, "7")

      if (this.q.q_phone === '' || this.q.q_phone === '+') {
        this.phone = '+7'
      }

      // this.phoneMask = this.phoneMask  === '+7(###)###-##-## ' ? '+7(###)###-##-##' : '+7(###)###-##-## '

      this.checkForm();
      this.q.q_phone = this.phone
    }
  },

  data () {
    return {
      saved: false,
      sended: false,
      phoneMask: '+7(###)###-##-##',
      phone: User.isLoggedIn() ? User.currentUser.user.user_phone.slice(1) : '+7',
      q: {
        check: false,
        q_name: this.getUserName(),
        q_phone: User.isLoggedIn() ? User.currentUser.user.user_phone.slice(1) : '+7',
        q_message: null,
        q_mail: User.isLoggedIn() ? User.currentUser.user.user_login : ''
      },
      valid: false
    }
  },

  components: {
    AboutMenu
  },

  methods: {
    validMail(mail) {
      var re = /^[\w-\\.]+@[\w-]+\.[a-z]{2,4}$/i;
      return  re.test(mail);
    },

    validPhone(phone) {
      phone = phone.match(/[\d]/g).join('')
      var re = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;
      return  re.test(phone);
    },
    checkForm() {
      if (this.validPhone(this.q.q_phone) && this.validMail(this.q.q_mail) && this.q.q_name && this.q.q_name.length > 4 && this.q.q_message && this.q.q_message.length > 10 && this.q.check) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
    getUserName() {
      if (User.isLoggedIn()) {
        return User.currentUser.user.account_title;
      } else {
        return ''
      }
    },
    async save() {

      this.saved = true;

      if (this.valid) {
        let q = this.q;

        q.q_phone = q.q_phone.match(/[\d]/g).join('')

        let list = [];
        list.push(Ajax.post(`/q/save/0`, q));
        let [qResult] = await Promise.all(list);
        if (qResult.error) {
          console.log(qResult.error)
        }
        if (qResult.response) {
          this.saved = false;
          this.sended = true
        }

      } else {
        this.saved = false;
      }

    },
  }

}



</script>