<template>
  <div>
    <Report ref="report" url="orders/list" :def-params="{order: {field: 'order_id', order: 'desc'}, filters: {order_resp_id: 'eq|' + $route.params.id}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <div v-if="error">
        {{error}}
      </div>
      <template v-if="report">
        <div v-if="report.rows_count === 0">
          <h2>404</h2>
        </div>
        <template v-else>
          <div v-for="(row, index) in report.rows" v-bind:key="index">

            <div class="ticket" ref="ticket">

              <router-link to="/" class="logo"></router-link>

              <h2>Заказ №{{row.order_id}} от {{convertDate(row.order_date, 'DD.MM.YYYY')}}</h2>

              <Barcode
                  :value="row.order_id"
                  :format="'CODE39'"
                  text=" "
                  :lineColor="'#000'"
                  :width="4"
                  :height="80"
                  :elementTag="'img'"
              />

              <template v-if="row && row.transport_number  === '3'">
                <a class="btn btn-primary mb20" :href="'https://work.vizit.tours:8081/order/ticket?filters%5Border_pay_resp_id%5D=eq%7C'+row.order_pay_resp_id+'&order%5Bfield%5D=order_id&order%5Border%5D=asc&page=1&limit=500'" download target="_blank">Скачать билет</a>
              </template>

              <div class="prices">
                <div>
                  <template v-if="row.order_partial_pay && row.order_partial_sum < row.order_sum_pre">
                    {{ formatRUB(row.order_partial_sum) }} из {{ formatRUB(row.order_sum_pre) }}
                  </template>
                  <template v-else-if="row.order_status === 'cancel_refund'">
                    {{ formatRUB(row.order_refund_sum) }} из {{ formatRUB(row.order_sum_pre) }}
                  </template>
                  <template v-else>
                    {{ formatRUB(row.order_sum_pre) }}
                  </template>
                  <div v-if="row.order_status === 'pending'">К оплате</div>
                  <div v-else-if="row.order_status === 'cancel_refund'">Возвращено</div>
                  <div v-else>Оплачено</div>
                </div>
                <div v-if="row.order_sum_pre < row.order_sum_all">
                  {{ formatRUB( row.order_sum_all -  row.order_sum_pre ) }}
                  <div>Гиду при посадке</div>
                </div>
               </div>

              <div class="label">
                Статус
              </div>
              <div class="value">
                {{(row.order_partial_pay && row.order_sum_pre > row.order_partial_sum) ? status['pending2'] : status[row.order_status]}}
                <div v-if="row.order_status === 'pending' && row.order_pay_processing === 1">
                  Ожидаем ответа от платёжной системы
                </div>
              </div>

              <div v-if="row.order_status === 'pending' && row.order_pay_processing === 1">
                <button v-on:click="reload" class="btn btn-primary mb20">
                  Обновить информацию
                </button>
              </div>

              <button v-on:click="pay(row)" class="btn btn-primary mb20" :disabled="payLoading" v-if="(row.order_status === 'pending' && row.order_pay_processing === 0) || (row.order_partial_pay && row.order_sum_pre > row.order_partial_sum)">
                <span class="btn-loading" v-if="payLoading">Подождите</span>
                Оплатить {{ formatRUB(row.order_sum_pre - (row.order_partial_pay ? row.order_partial_sum : 0)) }}
              </button>

              <div class="label">
                Экскурсия
              </div>
              <div class="value">
                {{row.order_title}}
              </div>

              <div class="label">
                Дата и время отправления
              </div>
              <div class="value">
                <template v-if="row.order_place_time">
                  {{convertDate(row.order_date_ot, 'DD.MM.YYYY в')}} {{row.order_place_time}}
                </template>
                <template v-else>
                  {{convertDate(row.order_date_ot, 'DD.MM.YYYY в HH:mm')}}
                </template>
                <div>Прийти на посадку за 10 минут</div>
              </div>

              <div class="label">
                Количество человек
              </div>
              <div class="value">
                {{row.order_adult + ( row.order_children_1 || 0) + ( row.order_children_free || 0) + ( row.order_children_2 || 0) + ( row.order_children_3 || 0)}}
              </div>

              <div class="label">
                Места
              </div>
              <div class="value" v-if="row.transport_place_title && row.transport_place_title.length">
                {{ row.order_tickets ? row.order_tickets.split(',').map(i => {return row.transport_place_title[parseInt(i)]}).join(',') : '' }}
              </div>
              <div class="value" v-else>
                {{row.order_tickets}}
              </div>

              <div class="label">
                Точка посадки
              </div>
              <div class="value">
                 <div style="margin-bottom: 10px">
                   {{row.place_address}}
                 </div>
                <a class="btn btn-primary" v-if="row.place_geo" :href="`https://yandex.ru/maps/?pt=${row.place_geo[1]},${row.place_geo[0]}&z=18&l=map`" target="_blank">
                  Посмотреть точку посадки на карте
                </a>
              </div>

              <div class="label">
                Номер автобуса
              </div>
              <div class="value">
                {{row.transport_number ? row.transport_number : 'Номер автобуса будет назначен за час до отправления.'}}
              </div>

              <template v-if="(row.order_sum_all - parseInt(row.order_pay_sum)) > 0">
                <div class="label">
                  Остаток платежа (отдаётся при посадке)
                </div>
                <div class="value pre-line">
                  {{formatRUB(row.order_sum_all - row.order_pay_sum)}}
                </div>
              </template>

              <div>
                <template v-if="ex && ex.excursion_tabs.find(e => e.title === 'Что взять с собой?' )">
                  <button class="btn btn-primary mb20" v-if="!isShowWhat" v-on:click="showWhat(row)">
                    Что взять с собой?
                  </button>
                  <template v-if="isShowWhat">
                    <div class="msg-info" v-html="ex.excursion_tabs.find(e => e.title === 'Что взять с собой?' ).desc"></div>
                    <div class="msg-info">
                      Не забудьте взять наличные на дополнительные расходы.
                    </div>
                  </template>
                </template>
              </div>

              <button v-on:click.prevent="showPrice = !showPrice" class="btn btn-middle mb20">
                <template v-if="!showPrice">Показать цены на объектах</template>
                <template v-else>Скрыть цены</template>
              </button>

              <template v-if="showPrice">
                <div v-for="(row, index) in ex.object_price_data" v-bind:key="index">
                  <div class="name-trip w-100">
                    <b>{{row.object_price_title}}</b>
                    <div>
                    {{ex.object_data.find(x => x.object_id === row.object_id).object_title }},
                    {{ex.object_data.find(x => x.object_id === row.object_id).object_city }}
                  </div>
                  </div>
                  <div class="prices" v-if="priceType">
                    <div>
                      {{formatRUB(row.object_price_adult)}}
                      <div>
                        {{priceType.find(x => x.value === row.object_price_adult_type).label }}
                      </div>
                    </div>
                    <div>
                      {{formatRUB(row.object_price_children_free)}}
                      <div>
                        {{priceType.find(x => x.value === row.object_price_children_free_type).label }}
                      </div>
                   </div>
                    <div>
                    {{formatRUB(row.object_price_children)}}
                      <div>
                        {{priceType.find(x => x.value === row.object_price_children_type).label }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>


              <div class="msg-info">
                Возникли вопросы? звоните: <br>

                <template v-if="row.user_phone && row.account_type !== 'partner'">
                  <div class="label">
                    Телефон продавца:
                  </div>
                  <div class="value">
                    <a :href="`tel:+${row.user_phone}`">{{ formatPhone(row.user_phone) }}</a>
                  </div>
                  <div class="label mt-10 mb20">
                    {{ row.account_title }}
                  </div>
                </template>
                <div class="label">
                  Телефон технической поддержки:
                </div>
                <div class="value mb0">
                  <a href="tel:+79883406585">+7 (988) 340-65-85</a>
                </div>

              </div>
              <div class="mb20">
                <a href="/about/refund/">Правила возврата</a>
              </div>
            </div>

          </div>
        </template>
      </template>
    </Report>
    <div :class="{show: payShow}" id="payment-form">
      <button v-if="report" class="window-close pay-close" v-on:click="close(report.rows[0])"></button>
    </div>
  </div>
</template>

<script>


import mixins from '../../helpers/mixins.js';

import Report from '../../components/report/Report';
import Ajax from "@/helpers/ajax";
import Barcode from "../../components/Barcode.vue";

export default {
  mixins: [mixins],

  components: {
    Barcode,
    Report
  },


  created() {
    let checkoutWidget = document.createElement('script')
    checkoutWidget.setAttribute('src', 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js')
    document.head.appendChild(checkoutWidget)
    this.loadPriceType();
  },

  data () {
    return {
      checkout: null,
      payShow: false,
      payLoading: false,
      isShowWhat: false,
      download: null,
      showPrice: false,
      priceType: null,
      data: {},
      cartSum: 0,
      disabled: true,
      status: {
        order: 'Заказ оплачен и подтверждён.',
        cancel: 'Заказ отменён',
        cancel_success: 'Заказ отменён, деньги возвращены',
        cancel_refund: 'Заказ отменён, деньги частично возвращены',
        pending: 'Заказ не оплачен',
        pending2: 'Ожидает полной оплаты'
      },
      error: null,
      report: null,
      ex: null
    }
  },
  methods: {
    reload() {
      window.location.reload()
    },
    async close(order) {
      if ( this.checkout ) {
        this.checkout.destroy();

        if (this.report.rows[0].order_pay_processing !== 1) {
          let data = {
            order_title: order.order_title,
            order_pay_resp_id: 0,
            order_id: order.order_id
          }

          await Ajax.post(`/order/save/${order.order_id}`, data);
        }
      }

      this.payShow = false;
    },

    async pay(order) {
      this.payLoading = true;

      let resp = await Ajax.post(`/order/get_pay/${order.order_id}`, order);

      this.payShow = true;

      this.checkout = new window.YooMoneyCheckoutWidget({
        confirmation_token: resp.response.id,

        error_callback: function(error) {
          console.log(error)
        }
      });
      this.checkout.on('complete', async () => {

        this.payShow = false;
        this.checkout.destroy();

        this.order_pay_processing = true;

        this.report.rows[0].order_pay_processing = true;

        let data = {
          order_title: order.order_title,
          order_pay_processing: 1,
          order_id: order.order_id
        }

        await Ajax.post(`/order/save/${order.order_id}`, data);


      });
      this.checkout.render('payment-form');

      this.payLoading = false;
    },

    async showWhat(order) {
      this.isShowWhat = true;

      if (!order.order_read_it) {
        let data = {
          order_title: order.order_title,
          order_read_it: 1,
          order_id: order.order_id
        }

        await Ajax.post(`/order/save/${order.order_id}`, data);
      }

    },


    async loadPriceType() {
      let price_type = await Ajax.get('/misc/price_type?query=');
      if (price_type) {
        this.priceType = price_type
      }
    },

    getReport() {
      return this.$refs.report;
    },
    reportLoading() {
      this.error = null;
    },
    async reportLoaded(report) {
      if (report) {
        if (report.response) {
          this.report = report.response;
          this.error = null;


          let result = await Ajax.get('/excursion/get-alias/' + report.response.rows[0].excursion_alias);
          if (result.response) {
            this.ex = result.response
          }

        } else {
          this.report = null;
          this.error = 'Неизвестная ошибка.';
        }
      }
    },
  }
}
</script>

<style>
  .ticket {
    padding: 20px;
    color: #333;
  }
  .ticket h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .ticket .label {

  }
  .ticket .value {
    margin-bottom: 14px;
    font-weight: bold;
    font-size: 16px;
    padding: 5px 0;
  }
  .msg-info {
    padding: 14px;
    font-weight: 600;
    background: #faf5c4;
    margin: 0 0 20px;
    border: 1px solid #e6e09c;
  }
  .pre-line {
    white-space: pre-line
  }
  img {
    width: 100%;
  }
</style>