<template>
   <div :id="cartId">
     <div class="book-tfr">
       <div id="booking-form">

         <template v-if="addBasket">
           <div>
             <b>Добавлено в корзину</b>
             <div>
               в корзине покупок находится <b>{{cart.length}}</b>
               &nbsp;услуги<br/>
               на общую сумму <b>{{formatRUB(Math.ceil(cartSum))}}</b>
             </div>
           </div>
         </template>

         <template v-else>
           <div v-if="window" class="window-header">Бронирование</div>

           <div class="flex flex-g2">
             <div class="input drop-icon">
               <label class="input-placeholder">Дата поездки</label>
               <input ref="pick" class="date-pick-booking" type="text" id="check_date" name="check_date" placeholder="Когда" autocomplete="off" readonly="">
             </div>

             <div class="input">
               <label class="input-placeholder">Время</label>
               <select id="dt_time" name="dt_time">
                 <option v-for="i in times" v-bind:key="i" :value="i">{{i}}</option>
               </select>
             </div>
           </div>

           <div class="flex flex-g2">
             <div class="input">
               <label class="input-placeholder">Взрослые</label>
               <div class="input-numeric">
                 <a class="btn" v-on:click="adults > 0 ? adults = adults - 1 : ''">-</a>
                 <input type="text" autocomplete="off" id="adults" class="qty2 form-control" name="quantity[]" :value="adults">
                 <a class="btn" v-on:click="adults = adults + 1">+</a>
               </div>
             </div>

             <div class="input">
               <label class="input-placeholder">Кол-во детей</label>
               <div class="input-numeric">
                 <a class="btn" v-on:click="(children > 0 ? children = children - 1 : '');setChildrenCount()">-</a>
                 <input type="text" autocomplete="off" id="child" class="qty2 form-control" name="quantity[]" :value="children">
                 <a class="btn" v-on:click="(children = children + 1);setChildrenCount()">+</a>
               </div>
             </div>
           </div>
           <div class="flex-g2 flex flex-jc_e flex-ai_c" v-for="n in children" v-bind:key="n">
             <b class="mb20">Возраст {{n}} реб.</b>
             <div>
               <div class="input">
                 <select name="age[]" v-model="childrenValues[n-1]" @change="setChildrenCount">
                   <option value="0">Нет</option>
                   <option value="1">1 год</option>
                   <option value="2">2 года</option>
                   <option value="3">3 года</option>
                   <option value="4">4 года</option>
                   <option value="5">5 лет</option>
                   <option value="6">6 лет</option>
                   <option value="7">7 лет</option>
                   <option value="8">8 лет</option>
                   <option value="9">9 лет</option>
                   <option value="10">10 лет</option>
                   <option value="11">11 лет</option>
                 </select>
               </div>
               <div v-if="ex.excursion_price_children_free2_allow === 'true' && childrenValues[n-1] > 0 && childrenValues[n-1] <= this.ex.excursion_age_children_free2_e">
                 <label class="checkbox mt-10">
                   <input autocomplete="off" type="checkbox" name="age_place[]" value="true" v-model="childrenValuesPlace[n-1]" @change="setChildrenCount">
                   <i>Без места</i>
                 </label>
               </div>
             </div>
           </div>

           <div :class="{'error-box': ((childrenError > 0) || (dt_time === '--:--' && times[0] === '--:--'))}">

             <template v-if="childrenError > 0">
              Укажите возраст {{childrenError}} ребенка
             </template>

             <template v-else>

               <template v-if="dt_time === '--:--' && times[0] === '--:--'">
                 Данный тур на дату {{date}} отсутствует
                 <br> в расписании
               </template>
               <template v-else>
                 <div v-if="ex">

                   <div class="price-table">

                     <div>
                       <div>Взрослые</div>
                       <div>{{ adults }} чел.</div>
                       <div>{{ formatRUB(ex.excursion_price_adult) }}</div>
                       <div>{{ formatRUB(ex.excursion_price_adult * adults) }}</div>
                     </div>

                     <div v-if="childrenValuesCount1">
                       <div>Дети ({{ ex.excursion_age_children_free_s }}-{{ ex.excursion_age_children_free_e }})</div>
                       <div>{{ childrenValuesCount1 }} чел.</div>
                       <div>{{ formatRUB(ex.excursion_price_children_free) }}</div>
                       <div>{{ formatRUB(ex.excursion_price_children_free * childrenValuesCount1) }}</div>
                     </div>

                     <div v-if="childrenValuesCount2">
                       <div>Дети ({{ ex.excursion_age_children_s }}-{{ ex.excursion_age_children_e }})</div>
                       <div>{{ childrenValuesCount2 }} чел.</div>
                       <div>{{ formatRUB(ex.excursion_price_children) }}</div>
                       <div>{{ formatRUB(ex.excursion_price_children * childrenValuesCount2) }}</div>
                     </div>

                     <div v-if="childrenValuesCount3">
                       <div>Дети ({{ ex.excursion_age_children_free2_s }}-{{ ex.excursion_age_children_free_e }})<br>без места</div>
                       <div>{{ childrenValuesCount3 }} чел.</div>
                       <div>{{ formatRUB(ex.excursion_price_children_free2) }}</div>
                       <div>{{ formatRUB(ex.excursion_price_children_free2 * childrenValuesCount3) }}</div>
                     </div>

                     <div>
                       <div>ИТОГО:</div>
                       <div>
                         {{ formatRUB(
                           Math.ceil(getSale(
                               (
                                   (ex.excursion_price_adult * adults) +
                                   (ex.excursion_price_children_free * childrenValuesCount1) +
                                   (ex.excursion_price_children * childrenValuesCount2) +
                                   (ex.excursion_price_children_free2 * childrenValuesCount3)
                               ),
                               (
                                   (ex.excursion_prepay_adult * adults) +
                                   (ex.excursion_prepay_children_free * childrenValuesCount1) +
                                   (ex.excursion_prepay_children * childrenValuesCount2) +
                                   (ex.excursion_prepay_children_free2 * childrenValuesCount3)
                               )
                           ))
                       )}}
                       </div>
                     </div>
                     <div v-if="ex.excursion_prepay_allow === 'true'">
                       <div>
                         при заказе вы оплачиваете всего
                         {{ formatRUB(
                           Math.ceil(getSalePrepay(
                               (
                                   (ex.excursion_prepay_adult * adults) +
                                   (ex.excursion_prepay_children_free * childrenValuesCount1) +
                                   (ex.excursion_prepay_children * childrenValuesCount2) +
                                   (ex.excursion_prepay_children_free2 * childrenValuesCount3)
                               ),
                               (
                                   (ex.excursion_price_adult * adults) +
                                   (ex.excursion_price_children_free * childrenValuesCount1) +
                                   (ex.excursion_price_children * childrenValuesCount2) +
                                   (ex.excursion_price_children_free2 * childrenValuesCount3)
                               ),
                           ))
                       )}}
                         остальное гиду при посадке
                       </div>
                     </div>
                   </div>


                   <div class="error-box" v-if="ex.excursion_sale_amount > 0">
                     при покупке на сайте, скидка
                     <template v-if="ex.excursion_sale_type === 'percent'">
                       {{ex.excursion_sale_amount}}%
                     </template>
                     <template v-else>
                       {{formatRUB(ex.excursion_sale_amount)}}
                     </template>
                   </div>
                 </div>
               </template>
             </template>
           </div>

         </template>

         <div class="error-box" v-if="seatsMessage">
           {{seatsMessage}}
         </div>

         <button :disabled="childrenError || seatsError" v-on:click="addToCart" class="btn btn-primary" type="submit">Оформить заказ</button>
       </div>
     </div>
     <template v-if="showLogin">
       <div class="window-bg" v-on:click="showLogin = false"></div>
       <div class="window">
         <Login />
       </div>
     </template>
   </div>
</template>
<script>


import mixins from "@/helpers/mixins";
import $ from 'jquery';
import moment from 'moment';

import Ajax from '@/helpers/ajax';

// eslint-disable-next-line
/* eslint-disable */
import daterangepicker from 'daterangepicker';
import User from "@/user";
import Login from '../auth/Login';

export default {
  mixins: [mixins],

  props: {
    ex:  {
      type: Object,
      default: {}
    },
    excursion_id: String,
    isw: {
      type: Boolean,
      default: false
    },
    window: {
      type: Boolean,
      default: false
    },
    datesset: String
  },

  data() {
    return {
      seatsMessage: null,
      seatsError: true,
      tr: new Date().getTime(),
      seats_loading:false,
      cartId: 'cart' + new Date().getTime(),
      loggedIn: User.isLoggedIn(),
      showLogin: false,
      addBasket: false,
      adults: 1,
      children: 0,
      childrenValues: [],
      childrenValuesCount1: 0,
      childrenValuesCount2: 0,
      childrenValuesCount3: 0,
      childrenValuesPlace: [],
      date: '',
      childrenError: 0,
      dt_time: '--:--',
      times: ['--:--'],
      f_date: null,
      cart: null,
      cartSum: 0
    }
  },
  components: {
    Login
  },

  created() {

  },

  watch: {
    adults(){
      this.getSeats()
    },
    childrenValuesPlace(){
      this.setChildrenCount()
    },

    childrenValues() {
      this.setChildrenCount()
    },

  },

  mounted() {
    if (this.ex) {
      if (this.ex.dates && this.ex.dates.length && this.ex.dates[0].date.split(':').length) {
        let dates = this.ex.dates;

        this.ex.dates = {}

        $.each(dates, function (k, date) {
          let flight_id = date.flight_id

          date = date.date

          let d = date.split(' ');
          let t = d[1].split(':');


          d = d[0];
          t = t[0] + ':' + t[1]

          this.ex.dates = Object.keys(this.ex.dates).sort().reduce(
              (obj, key) => {
                obj[key] = this.ex.dates[key];
                return obj;
              },
              {}
          );

          if (this.ex.dates[d]) {

            let new_times = this.ex.dates[d].times;
            let new_flight_ids = this.ex.dates[d].flight_ids;

            new_times.push(t)
            new_times.sort()

            new_flight_ids.push({time: t, flight_id: flight_id})

            this.ex.dates[d] = {
              times: new_times,
              flight_ids: new_flight_ids
            }
          } else {
            this.ex.dates[d] = {
              times: [t],
              flight_ids: [{time: t, flight_id: flight_id}]
            }
          }

        }.bind(this));

      } else {
        if (this.ex.dates) {
          this.ex.dates = Object.keys(this.ex.dates).sort().reduce(
              (obj, key) => {
                obj[key] = this.ex.dates[key];
                return obj;
              },
              {}
          );
        } else {
          this.ex.dates = {}
        }
      }

      const params = {
        autoApply: true,
        autoUpdateInput: false,
        locale: {
          format: "DD.MM.YY",
          cancelLabel: 'Очистить',
          applyLabel: 'Применить',
          firstDay: 1
        },
        "linkedCalendars": true,
        "showCustomRangeLabel": false,
        "showRangeLabel": false,
        "alwaysShowCalendars": true,
        "singleDatePicker": true,
        "timePicker": false,
        "timePicker24Hour": false,
      };

      params.isInvalidDate = function(date) {
        if (this.ex.dates && !this.ex.dates[date.format('YYYY-MM-DD')]) {
          return true;
        }
      }.bind(this)

      $(this.$refs.pick).daterangepicker(params);

      let now = (this.ex && this.ex.excursion_start_date && (moment.utc(this.ex.excursion_start_date).unix() * 1000) >= new Date().getTime()) ? moment.utc(this.ex.excursion_start_date)._d : (Object.keys( this.ex.dates ).length ? new Date(Object.keys( this.ex.dates ).sort()[0]) : new Date());
      if (this.$route.query['d']) {
        now = new Date(this.$route.query['d']);
      }
      if (this.datesset) {
        now = new Date(this.datesset)
      }



      $(this.$refs.pick).data('daterangepicker').setStartDate(now)

      this.$refs.pick.value = moment(now).format('DD.MM.YY');
      this.date = moment(now).format('DD.MM.YY');
      this.f_date = moment(now).format('YYYY-MM-DD');


      let times = this.ex.dates[this.f_date].times;


      this.times = times ? times : ['--:--'];
      this.dt_time = times ? times[0] : '--:--';

      $(this.$refs.pick).on('apply.daterangepicker', (ev, picker) => {
        this.$refs.pick.value = picker.startDate.format('DD.MM.YY');
        this.date = picker.startDate.format('DD.MM.YY');
        this.f_date = picker.startDate.format('YYYY-MM-DD');

        if (picker.startDate.format('DD.MM.YY') === moment(new Date()).format('DD.MM.YY')) {
          let times = this.ex.dates[picker.startDate.format('YYYY-MM-DD')].times;

          this.times = times.length ? times : ['--:--'];
          this.dt_time = times.length ? times[0] : '--:--';
        } else {
          let times = this.ex.dates[picker.startDate.format('YYYY-MM-DD')].times;

          this.times = times ? times : ['--:--'];
          this.dt_time = times ? times[0] : '--:--';
        }
        this.getSeats()
      });

      if (this.ex && this.ex.dates && Object.keys( this.ex.dates ).length) {


        if (moment(now).format('DD.MM.YY') === moment(new Date()).format('DD.MM.YY')) {
          let lrt = this.ex.dates[Object.keys( this.ex.dates )[0]].times
          let times = [];

          $.each(lrt, function (k, v) {
            if (v > moment(new Date()).add(1, 'hours').format('HH:mm')) {
              times.push(v)
            }
          })

          this.times = times.length ? times : ['--:--'];
          this.dt_time = times.length ? times[0] : '--:--';
        } else {

          this.times = this.ex.dates[this.f_date].times.length ? this.ex.dates[this.f_date].times : ['--:--'];
          this.dt_time = this.ex.dates[this.f_date].times.length ? this.ex.dates[this.f_date].times[0] : '--:--';
        }

      }


      $('#' + this.cartId + ' #dt_time').on('change', () => {
        this.getSeats()
      })

      this.getSeats()

    }
  },

  methods: {
    getSale(sum, prepay = 0) {
      if (this.ex.excursion_sale_amount > 0) {
        if (this.ex.excursion_sale_type === 'percent') {
          if (this.ex.excursion_sale_prepay_type === 'all') {
            return sum - (sum / 100 * this.ex.excursion_sale_amount)
          }
          if (this.ex.excursion_sale_prepay_type === 'prepay') {
            return sum - (sum / 100 * this.ex.excursion_sale_amount)
          }
          if (this.ex.excursion_sale_prepay_type === 'other') {
            return sum - ((sum - prepay) / 100 * this.ex.excursion_sale_amount)
          }
        } else {
          return sum - this.ex.excursion_sale_amount
        }
      } else {
        return sum
      }
    },

    getSalePrepay(sum, price = 0) {
      if (this.ex.excursion_sale_amount > 0) {
        if (this.ex.excursion_sale_type === 'percent') {
          if (this.ex.excursion_sale_prepay_type === 'all') {
            return sum
          }
          if (this.ex.excursion_sale_prepay_type === 'prepay') {
            return sum - (price / 100 * this.ex.excursion_sale_amount)
          }
          if (this.ex.excursion_sale_prepay_type === 'other') {
            return sum
          }
        } else {
          if (this.ex.excursion_sale_prepay_type === 'prepay') {
            return sum - this.ex.excursion_sale_amount
          } else {
            return sum
          }
        }
      } else {
        return sum
      }
    },

    setChildrenCount() {

      this.childrenValuesCount1 = 0;
      this.childrenValuesCount2 = 0;
      this.childrenValuesCount3 = 0;

      this.childrenValues.forEach((e, k) => {
        if (e <= this.ex.excursion_age_children_free_e && k < this.children) {
          if (this.childrenValuesPlace[k] && !!this.childrenValuesPlace[k]) {
            this.childrenValuesCount3++
          } else {
            this.childrenValuesCount1++
          }
        }
      });
      this.childrenValues.forEach((e, k) => {
        if (e > this.ex.excursion_age_children_s && k < this.children) {
          this.childrenValuesCount2++
        }
      });

      this.childrenError = 0;

      let i = 0;
      while (i < this.children) {
        this.childrenValues[i] = this.childrenValues[i] || '0';

        if (!this.childrenValues[i] || this.childrenValues[i] === '0') {
          this.childrenError = i + 1;
        }

        i++;
      }

      this.getSeats()
    },

    addWishlist() {
      this.$emit('addWishlist')
    },

    async getSeats() {

      this.seatsError = true

      setTimeout(async () => {

        this.dt_time = $('#' + this.cartId + ' #dt_time').val();

        if (this.dt_time !== '--:--') {

          let flight_data = this.ex.dates[this.f_date].flight_ids.find( i => i.time === this.dt_time)

          let count =
              this.adults +
              this.childrenValuesCount1 +
              this.childrenValuesCount2 +
              this.childrenValuesCount3 - (this.childrenValuesPlace.filter(x => x === true).length)

          if (flight_data && flight_data.flight_id) {
            let seats = await Ajax.get(`/orders/seats/?fl=${flight_data.flight_id}&c=${count}&tr=9999`);
            if (seats.response) {
              if (seats.response.success) {
                this.seatsError = false;
                this.seatsMessage = null
              } else {
                this.seatsMessage = 'К сожалению свободных мест больше не осталось, выберите другую дату или время.'
                this.seatsError = true
              }
            }
          }

        }
      }, 100)

    },
    async addToCart() {

      this.dt_time = $('#' + this.cartId + ' #dt_time').val();

      if (this.dt_time !== '--:--' && !this.seatsMessage) {
        let cartItem = {
          excursion_id: this.ex.excursion_id,
          excursion_date: this.date,
          excursion_time: this.dt_time,
          excursion_adults: this.adults,
          excursion_children: this.children,
          excursion_children_values: this.childrenValues,
          excursion_children_count1: this.childrenValuesCount1,
          excursion_children_count2: this.childrenValuesCount2,
          excursion_children_count3: this.childrenValuesCount3
        }
        let cart = [];

        cart.push(cartItem)

        localStorage.setItem("cart", JSON.stringify(cart));
        this.$emit('updateCart')
        this.$router.push({ path: '/booking' });
      }
    },
  }
}

</script>
