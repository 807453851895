<template>
  <div class="w page">

    <div class="page-header">
      <h1 class="mb0">Оформление заказа</h1>
    </div>

    <main class="text">

      <nav class="breadcrumb">
        <router-link to="/">Главная</router-link>
        <span class="bread-current">Оформление заказа</span>
      </nav>

      <div class="flex booking">
        <div class="mr80">
          <h2>Контактные данные</h2>
          <p v-if="loggedIn">Проверьте свои персональные данные для связи с вами</p>
          <template v-else>
            <p>
              Укажите свои персональные данные для оформления заказа и создания нового аккаунта.
            </p>
            <p>
              Если у вас уже есть аккаунт, <a href="#" v-on:click.prevent="showLogin = true">авторизуйтесь</a>
            </p>
          </template>

          <form>
            <div class="step">
              <div class="flex flex-g2">
                <div class="input">
                  <label class="input-placeholder">ФИО</label>
                  <input type="text" name="name" autocomplete="off" v-model="order.name">
                  <div class="msg-error" v-if="errors.name">{{errors.name}}</div>
                </div>
                <div class="input">
                  <label class="input-placeholder">Телефон</label>
                  <input type="text" v-mask="phoneMask" placeholder="+7(999)999-99-99" name="phone" class="phone_mask" autocomplete="off" v-model="phone">
                  <div class="msg-error" v-if="errors.phone">{{errors.phone}}</div>
                </div>
              </div>

              <template v-if="!loggedIn && authCode">

                <p>
                  Мы отправили вам смс с кодом для авторизации, введите его в поле "код из смс" для использования введённх данных
                </p>

                <div class="flex flex-g2">
                  <div class="input">
                    <label class="input-placeholder">Код из смс</label>
                    <input type="text" name="auth_code" class="form-control" autocomplete="off" v-model="order.auth_code">
                    <div class="msg-error" v-if="errors.auth_code">{{errors.auth_code}}</div>
                  </div>
                  <div></div>
                </div>
              </template>

            </div>


            <h2>Место посадки</h2>
            <p>Выберите место посадки на карте или укажите свой адрес и менеджер поможет вам с выбором.</p>

            <div class="place" v-if="ex" :class="{showFull: fullScreen}">
              <div class="place-list">
                <div v-for="(row, index) in ex.place_data" v-bind:key="index" v-on:click="select(row.place_id)" :class="{selected: row.place_id === order.order_place}">
                  {{ row.place_address }}
                </div>
              </div>
             <div class="wrap-map">
               <i class="full-map" v-on:click="fullScreen = !fullScreen"></i>
               <YandexMap
                   v-if="fullScreen"
                   :key="order.order_place"
                   :settings="{
                  apiKey: 'acf11884-2173-4f23-9f2f-625069ddd6ca',
                  lang: 'ru_RU',
                  coordorder: 'latlong',
                  enterprise: false,
                  version:'2.1'
              }"
                   :coordinates="[44.9218, 37.3136]"
                   :zoom="12"
                   style="height: 370px;max-width: 530px"

               >

                 <YandexMarker v-for="(row, index) in ex.place_data" v-bind:key="index"
                               :marker-id="row.place_id"
                               :coordinates="row.place_geo"
                               type="Point"
                               v-on:click="select(row.place_id)"
                               :properties="{
                                balloonContentHeader: row.place_title,
                                balloonContentBody: row.place_address,
                                balloonContentFooter: row.place_address,
                                hintContent: row.place_address
                              }"
                               :options="{
                              openBalloonOnClick:true,
                               balloonContentHeader: row.place_title,
                                balloonContentBody: row.place_address,
                                balloonContentFooter: row.place_address,
                              iconColor: order.order_place === row.place_id ? '#0b0b8f' : '#ccc'
                            }"

                 >
                 </YandexMarker>
               </YandexMap>
               <YandexMap
                   v-if="!fullScreen"
                   :key="order.order_place"
                   :settings="{
                  apiKey: 'acf11884-2173-4f23-9f2f-625069ddd6ca',
                  lang: 'ru_RU',
                  coordorder: 'latlong',
                  enterprise: false,
                  version:'2.1'
              }"
                   :coordinates="[44.9218, 37.3136]"
                   :zoom="12"
                   style="height: 370px;max-width: 530px"

               >

                 <YandexMarker v-for="(row, index) in ex.place_data" v-bind:key="index"
                               :marker-id="row.place_id"
                               :coordinates="row.place_geo"
                               type="Point"
                               v-on:click="select(row.place_id)"
                               :properties="{
                                balloonContentHeader: row.place_title,
                                balloonContentBody: row.place_address,
                                balloonContentFooter: row.place_address,
                                hintContent: row.place_address
                              }"
                               :options="{
                              openBalloonOnClick:true,
                               balloonContentHeader: row.place_title,
                                balloonContentBody: row.place_address,
                                balloonContentFooter: row.place_address,
                              iconColor: order.order_place === row.place_id ? '#0b0b8f' : '#ccc'
                            }"

                 >
                 </YandexMarker>
               </YandexMap>
             </div>

            </div>

            <p>
              Если вы затрудняетесь с выбором места посадки, напишите свой адрес в поле ниже и мы с вами свяжемся для уточнения.
            </p>
            <div class="input">
              <input type="text" name="address_location" autocomplete="off" v-model="order.address">
            </div>
          </form>

          <h2>Промокоды</h2>
          <p>Если у Вас есть промокод или сертификат активируйте его ниже</p>

          <div class="input">
            <input type="text" autocomplete="off" id="promo_code" name="promo_code" v-model="promo">

            <div class="status-error" v-if="promoData && !promoData.length">
              Промокод не найден
            </div>
            <div class="status-success" v-if="promoData && promoData.length">
              Промокод активирован
            </div>
          </div>

          <button class="btn btn-primary" :disabled="promo.length < 3" v-on:click="checkPromo">
            Активировать
          </button>

        </div>

        <div>
          <div class="pay-card">


              <div v-for="(row, index) in cart" v-bind:key="index">
                <img :src="`${domain}/img/excursion/list/${row.excursion_images[0]}`" />
                <h4>
                  <router-link :to="`/${getCityCode()}/excursion/${row.excursion_alias}/`">
                    {{row.excursion_title}}
                  </router-link>
                </h4>
                <p class="desc">
                  {{row.excursion_date}} в {{row.excursion_time}} |
                  {{row.excursion_adults + row.excursion_children}}
                  {{declination(row.excursion_adults + row.excursion_children, ['человек', 'человека', 'человек'])}}
                </p>
              </div>

              <div class="price-table">

                <div>
                  <div>Сумма заказа</div>
                  <div v-if="cart && cart[0]">
                    {{ formatRUB(cart[0].excursion_item_sum_full) }}
                  </div>
                </div>
                <div>
                  <div>Сумма всех скидок</div>
                  <div>
                    <template v-if="promoData && promoData.length">
                      {{ formatRUB(Math.floor(getSaleSum(cartSumFull))) }}
                    </template>
                    <template v-else>
                      {{ formatRUB(Math.floor(getSaleSum(cartSumFull))) }}
                    </template>
                  </div>
                </div>
                <div>
                  <div>Итого</div>
                  <div v-if="cart && cart[0]">
                    <template v-if="promoData && promoData.length">
                      {{ formatRUB(getSum(cartSumFull) ) }}
                    </template>
                    <template v-else>
                      {{ formatRUB(getSum(cartSumFull) ) }}
                    </template>
                  </div>
                </div>
                <div v-if="cartSumPrepay === cartSum">
                  <div>К ОПЛАТЕ</div>
                  <div>
                    <template v-if="promoData && promoData.length">
                      {{ formatRUB(getSum(cartSumFull)) }}
                    </template>
                    <template v-else>
                      {{ formatRUB(getSum(cartSumFull)) }}
                    </template>
                  </div>
                </div>

                <div v-if="cartSumPrepay < cartSum">
                  <div>Предоплата</div>
                  <div>
                    <template v-if="promoData && promoData.length">
                      {{ formatRUB(getSalePrepay(cartSumPrepay)) }}
                    </template>
                    <template v-else>
                      {{ formatRUB(getSalePrepay(cartSumPrepay)) }}
                    </template>
                  </div>
                </div>

                <div v-if="cartSumPrepay < cartSum">
                  <div>Гиду при посадке</div>
                  <div>
                    <template v-if="promoData && promoData.length">
                      {{ formatRUB(getSum(cartSumFull) - getSalePrepay(cartSumPrepay)) }}
                    </template>
                    <template v-else>
                      {{ formatRUB(getSum(cartSumFull) - getSalePrepay(cartSumPrepay)) }}
                    </template>
                  </div>
                </div>
              </div>

              <div class="mb20">
                <template v-if="!saved">
                  <template v-if="cartSumPrepay === cartSum">
                    <button class="btn btn-primary" :disabled="disabled" v-on:click="save()">
                      Оплатить {{formatRUB(getSum(cartSumFull))}}
                    </button>
                  </template>
                  <template v-else>
                    <button class="btn btn-primary" :disabled="disabled" v-on:click="save()">
                      Оплатить {{ formatRUB(getSalePrepay(cartSumPrepay)) }}
                    </button>
                  </template>
                </template>
                <button v-if="saved" class="btn btn-primary" :disabled="disabled">Подождите</button>
              </div>

              <small>
                Оплачивая заказ вы принимаете
                <a href="/about/privacy" target="_blank">
                  правила и условия
                </a>
                <a href="/about/offer" target="_blank">
                  договора оферты
                </a>
                и даёте разрешение на обработку персональных данных
              </small>

          </div>
        </div>
      </div>
    </main>

    <template v-if="showLogin">
      <div class="window-bg" v-on:click="showLogin = false"></div>
      <div class="window">
        <Login />
      </div>
    </template>

    <div :class="{show: payShow}" id="payment-form">
       <!--button class="window-close pay-close" v-on:click="close"></button-->
    </div>

  </div>
</template>
<script>

import mixins from '../../helpers/mixins.js';

import Login from '../auth/Login';

import User from '../../user';

import Ajax from "@/helpers/ajax";
import $ from "jquery";

import moment from 'moment-timezone';

import { YandexMap, YandexMarker } from 'vue-yandex-maps'



export default {
  mixins: [mixins],

  components: {
    YandexMap,
    YandexMarker,
    Login
  },

  data () {
    return {
      checkout: null,
      fullScreen: false,
      payShow: false,
      phoneMask: '+7(###)###-##-##',

      phone: User.isLoggedIn() ? User.currentUser.user.user_phone.slice(1) : '+7',
      ex: null,
      order_place_title: null,
      authCode: false,
      showLogin: false,
      data: {},
      promo: '',
      promoData: null,
      cartSumPrepay: 0,
      saved: false,
      cartSum: 0,
      cartSumFull: 0,
      disabled: true,
      errors: {
        name: null,
        email: null,
        phone: null,
        confirm_password: null,
        user_password: null,
        auth_code: null,
      },
      user: User.currentUser.user || {},
      loggedIn: User.isLoggedIn(),
      cart: [],
      pay_url: null,
      pay_id: null,
      order: {
        name: this.getUserName(),
        email: User.isLoggedIn() ? User.currentUser.user.user_login : '',
        phone: User.isLoggedIn() ? User.currentUser.user.user_phone.slice(1) : '+7',
        auth_code: '',
        order_place: null,
        address: '',
        ex: null,
        confirm_password: null,
        user_password: null,
      }
    }
  },

  watch: {
    'phone'(s, e) {

      if (s.length < e.length) {
        if (e[e.length-1] === '-' || e[e.length-1] === '(' || e[e.length-1] === ')') {
          this.phone = this.phone.substring(0, s.length - 1)
        }
      }

      this.phone = this.phone
          .replace(/^8/, "7")
          .replace(/^9/, "7")

      if (this.order.phone === '' || this.order.phone === '+') {
        this.phone = '+7'
      }

      // this.phoneMask = this.phoneMask  === '+7(###)###-##-## ' ? '+7(###)###-##-##' : '+7(###)###-##-## '

      this.order.phone = this.phone
    }
  },

  created() {
    let checkoutWidget = document.createElement('script')
    checkoutWidget.setAttribute('src', 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js')
    document.head.appendChild(checkoutWidget)
  },

  mounted () {

    if (!User.isLoggedIn()) {
      let pass = this.generatePassword();

      this.order.confirm_password = pass;
      this.order.user_password = pass;
    }

    this.getCart();
    if (!this.errors.name && !this.errors.email && !this.errors.phone) {
      this.disabled = false
    } else {
      this.disabled = true
    }
  },

  methods: {

    close() {
      this.checkout.destroy();
      this.payShow = false;
    },

    select(n) {
      this.order.order_place = n;
      // let i = 0;
      $.each(this.ex.place_data, function (k, v) {
        // i = i + this.ex.place_time[k] ? this.ex.place_time[k]

        if (v.place_id == n) {
          if (this.ex.place_time && this.ex.place_time['t' + n]) {

            let time = 0;
            let i = this.ex.place_time['t' + n];
            let date = this.cart[0].excursion_date.split(('.'))
            moment.tz.setDefault("Europe/Moscow");

            if (i.indexOf('-') > -1) {
              time = moment('20' + date[2]+'-'+date[1]+'-'+date[0]+' '+ this.cart[0].excursion_time +':00').subtract(i.replace('-', ''), 'minutes').format('HH:mm')
            } else {
              time = moment('20' + date[2]+'-'+date[1]+'-'+date[0]+' '+ this.cart[0].excursion_time +':00').add(i, 'minutes').format('HH:mm')
            }

            this.order.order_place_time = time
          }
          this.order_place_title = v.place_address

          return false;
        }

      }.bind(this))

      setTimeout(() => {
        $(".place-list .selected")[0].scrollIntoView(
            { behavior: "smooth", block: "nearest", inline: "nearest"}
        );
      }, 200)



    },

    async checkPromo() {
      let list = [];
      list.push(Ajax.get('/promo?query=' + this.promo + '&ex=' + this.cart[0].excursion_id));

      let [promoResult] = await Promise.all(list);

      if (promoResult) {
        this.promoData = promoResult;
      }

    },

    getSale(sum, prepay = 0) {
      if (this.ex.excursion_sale_amount > 0) {
        if (this.ex.excursion_sale_type === 'percent') {
          if (this.ex.excursion_sale_prepay_type === 'all') {
            return sum - (sum / 100 * this.ex.excursion_sale_amount)
          }
          if (this.ex.excursion_sale_prepay_type === 'prepay') {
            return sum - (prepay / 100 * this.ex.excursion_sale_amount)
          }
          if (this.ex.excursion_sale_prepay_type === 'other') {
            return sum - ((sum - prepay) / 100 * this.ex.excursion_sale_amount)
          }
        } else {
          return sum - this.ex.excursion_sale_amount
        }
      } else {
        return sum
      }
    },

    getSalePrepay(sum) {

      let promoSale = 0;
      if (this.promoData && this.promoData.length) {
        if (this.promoData[0].promo_type2 === 2) {
          if (this.promoData[0].promo_type === 'percent') {
            if (this.promoData[0].promo_summed === 'on') {
              promoSale = (this.cartSumFull / 100 * this.promoData[0].promo_sale)
            } else {
              return sum - (this.cartSumFull / 100 * this.promoData[0].promo_sale)
            }
          } else {
            if (this.promoData[0].promo_summed === 'on') {
              promoSale = this.promoData[0].promo_sale * (this.cart[0].excursion_adults + this.cart[0].excursion_children)
            } else {
              return sum - this.promoData[0].promo_sale * (this.cart[0].excursion_adults + this.cart[0].excursion_children)
            }
          }
        }
      }

      if(this.ex) {
        if (this.ex.excursion_sale_amount > 0) {
          if (this.ex.excursion_sale_type === 'percent') {
            if (this.ex.excursion_sale_prepay_type === 'all') {
              return sum - promoSale
            }
            if (this.ex.excursion_sale_prepay_type === 'prepay') {
              return sum - (this.cartSumFull / 100 * this.ex.excursion_sale_amount) - promoSale
            }
            if (this.ex.excursion_sale_prepay_type === 'other') {
              return sum - promoSale
            }
          } else {
            if (this.ex.excursion_sale_prepay_type === 'prepay') {
              return sum - this.ex.excursion_sale_amount - promoSale
            } else {
              return sum - promoSale
            }
          }
        } else {
          return sum - promoSale
        }
      }
    },

    getSaleSum(sum, prepay = 0) {

      if (this.ex) {
        if (this.promoData && this.promoData.length) {
          if (this.promoData[0].promo_type2 === 2 && this.promoData[0].promo_summed === 'off') {
            return this.cartSumPrepay - this.getSalePrepay(this.cartSumPrepay)
          }
          if (this.promoData[0].promo_type === 'percent') {
            if (this.promoData[0].promo_summed === 'on') {
              // add sale
              if (this.ex.excursion_sale_amount > 0) {
                if (this.ex.excursion_sale_type === 'percent') {
                  if (this.ex.excursion_sale_prepay_type === 'all') {
                    return sum / 100 * (this.ex.excursion_sale_amount + this.promoData[0].promo_sale)
                  }
                  if (this.ex.excursion_sale_prepay_type === 'prepay') {
                    return sum / 100 * (this.ex.excursion_sale_amount + this.promoData[0].promo_sale)
                  }
                  if (this.ex.excursion_sale_prepay_type === 'other') {
                    return ((sum - prepay) / 100 * (this.ex.excursion_sale_amount + this.promoData[0].promo_sale))
                  }
                } else {
                  return (sum / 100 * this.promoData[0].promo_sale) + this.ex.excursion_sale_amount
                }
              } else {
                sum = sum / 100 * this.promoData[0].promo_sale
              }
            } else {
              sum = sum / 100 * this.promoData[0].promo_sale
            }
          } else {
            if (this.promoData[0].promo_summed === 'on') {
              // add sale
              if (this.ex.excursion_sale_amount > 0) {
                if (this.ex.excursion_sale_type === 'percent') {
                  if (this.ex.excursion_sale_prepay_type === 'all') {
                    return sum / 100 * (this.ex.excursion_sale_amount) + this.promoData[0].promo_sale * (this.cart[0].excursion_adults + this.cart[0].excursion_children)
                  }
                  if (this.ex.excursion_sale_prepay_type === 'prepay') {
                    return sum / 100 * (this.ex.excursion_sale_amount) + this.promoData[0].promo_sale * (this.cart[0].excursion_adults + this.cart[0].excursion_children)
                  }
                  if (this.ex.excursion_sale_prepay_type === 'other') {
                    return ((sum - prepay) / 100 * (this.ex.excursion_sale_amount)) + this.promoData[0].promo_sale * (this.cart[0].excursion_adults + this.cart[0].excursion_children)
                  }
                } else {
                  return (this.promoData[0].promo_sale * (this.cart[0].excursion_adults + this.cart[0].excursion_children)) + this.ex.excursion_sale_amount
                }
              } else {
                sum = this.promoData[0].promo_sale * (this.cart[0].excursion_adults + this.cart[0].excursion_children)
              }

            } else {
              sum = this.promoData[0].promo_sale
            }
          }
        } else {

          if (this.ex.excursion_sale_amount > 0) {
            if (this.ex.excursion_sale_type === 'percent') {
              if (this.ex.excursion_sale_prepay_type === 'all') {
                return sum / 100 * this.ex.excursion_sale_amount
              }
              if (this.ex.excursion_sale_prepay_type === 'prepay') {
                return sum / 100 * this.ex.excursion_sale_amount
              }
              if (this.ex.excursion_sale_prepay_type === 'other') {
                return ((sum - prepay) / 100 * this.ex.excursion_sale_amount)
              }
            } else {
              return this.ex.excursion_sale_amount
            }
          } else {
            return 0
          }

        }
      }

      return sum
    },

    getSum(sum) {
      return Math.floor(this.cartSumFull - this.getSaleSum(sum))
    },

    validMail(mail) {
      let re = /^[\w-\\.]+@[\w-]+\.[a-z]{2,4}$/i;
      return  re.test(mail);
    },

    validPhone(phone) {
      phone = phone.match(/[\d]/g).join('')
      return  phone.length === 11;
    },

    checkError() {
      if (this.order.name === '') {
        this.errors.name = 'Заполните поле!'
      } else {
        this.errors.name = null
      }

      if (this.order.email === '') {
        this.order.email = new Date().getTime() + '@vizit.tours'
      } else {
        if (!this.validMail(this.order.email)) {
          this.order.email = new Date().getTime() + '@vizit.tours'
        }
      }
      this.errors.email = null

      if (this.order.phone === '') {
        this.errors.phone = 'Заполните поле!'
      } else {
        this.errors.phone = null

        if (!this.validPhone(this.order.phone)) {
          this.errors.phone = 'Укажите корректный номер телефона'
        }
      }

      if (this.authCode) {
        if (this.order.auth_code === '') {
          this.errors.auth_code = 'Заполните поле!'
        } else {
          this.errors.auth_code = null
        }
      }

      if (!this.loggedIn) {
        if (this.order.confirm_password === '') {
          this.errors.confirm_password = 'Заполните поле!'
        } else {
          this.errors.confirm_password = null
        }
        if (this.order.user_password === '') {
          this.errors.user_password = 'Заполните поле!'
        } else {
          this.errors.user_password = null
        }

        if (!this.errors.confirm_password && !this.errors.user_password && this.order.user_password !== this.order.confirm_password) {
          this.errors.confirm_password = 'Пароли не совпадают'
          this.errors.user_password = 'Пароли не совпадают'
        } else {
          this.errors.user_password = null
          this.errors.confirm_password = null
        }
      }

      this.scrollToError()
    },

    scrollToError() {
      $.each(this.errors, function (k ,v) {
        if (v) {
          window.scrollTo(0, $('input[name="'+k+'"]').offset().top - 250)
          return false
        }
      })
    },

    getUserName() {
      if (User.isLoggedIn()) {
        return User.currentUser.user.account_title;
      } else {
        return ''
      }
    },

    generatePassword() {
      let length = 8,
          charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
          retVal = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },

    async signIn() {

      let newPhone = this.order.phone;

      let data = {
        user_phone: newPhone.match(/[\d]/g).join(''),
        user_login: this.order.email,
        user_password: this.order.user_password,
        confirm_password: this.order.confirm_password,
        account_title: this.order.name
      };

      if (this.authCode) {
        data.auth_code = this.order.auth_code
      }


      let list = [];
      list.push(Ajax.post(`/account/save/0`, data));

      let [userResult] = await Promise.all(list);

      if (userResult.error) {

        if (userResult.error.error_field && userResult.error.error_field === 'user_login') {
          this.errors.email = userResult.error.error_message
        }
        if (userResult.error.error_field && userResult.error.error_field === 'user_phone') {
          this.errors.phone = userResult.error.error_message
        }

        if (userResult.error.code && userResult.error.code === 191) {
          this.authCode = true
        }

        if (!userResult.error.code && userResult.error.auth_code) {
          this.errors.auth_code = userResult.error.auth_code
        }

        if (userResult.error.elements && userResult.error.elements.user_password) {
          this.errors.user_password = userResult.error.elements.user_password
        }

        this.scrollToError()

        this.saved = false;
        return false;
      }

      if (userResult.response) {

        if (userResult.response.auth) {
          let result = await User.login(data.user_phone, data.user_password, '', true);
          if (result === true) {
            this.showCaptcha = false;
          }
        } else {
          let result = await User.login(data.user_login, data.user_password, '');
          if (result === true) {
            this.showCaptcha = false;
          }
        }
        return true;
      }
    },

    async save() {

      this.checkError();

      this.saved = true;

      this.scrollToError()

      let signInError = true;

      if (this.authCode) {
        signInError = !this.errors.auth_code;
      } else {
        signInError = !this.loggedIn ? (!this.errors.user_password && !this.errors.confirm_password) : true;
      }


      if (!this.errors.name && !this.errors.email && !this.errors.phone && signInError) {
        let order = this.order;

        let newPhone = order.phone;
        order.phone = newPhone.match(/[\d]/g).join('')

        let data = {
          order: order,
          cart: this.cart,
          cartSum: Math.ceil(this.getSum(this.cartSumFull)),
          cartSumSale: Math.floor(this.getSaleSum(this.cartSumFull)),
          cartSumPrepay: Math.ceil(this.getSalePrepay(this.cartSumPrepay)),
          cartSumFull: Math.ceil(this.cartSumFull)
        }


        if (window.localStorage.getItem('partner')) {
          data.order.order_campaign = window.localStorage.getItem('partner')
        }

        if (this.promoData && this.promoData.length) {
          data.order.order_promo = this.promoData[0].promo_code
        }

        this.clearError();

        if (!this.loggedIn) {
          let signIn = await this.signIn()

          if (signIn) {
            let list = [];
            list.push(Ajax.post(`/order/save/0`, data));
            let [orderResult] = await Promise.all(list);
            if (orderResult.error) {
              this.setError(orderResult.error);
            }
            if (orderResult.response && orderResult.response.id) {
              this.pay_url = orderResult.response.url;
              this.pay_id = orderResult.response.respond;
              this.saved = false;

              this.payShow = true;

              this.checkout = new window.YooMoneyCheckoutWidget({
                confirmation_token: orderResult.response.id,
                return_url: this.pay_url,
                error_callback: function(error) {
                  console.log(error)
                }
              });

              this.checkout.render('payment-form');

              localStorage.cart = '';
            }
          }
        } else {
          let list = [];
          list.push(Ajax.post(`/order/save/0`, data));
          let [orderResult] = await Promise.all(list);
          if (orderResult.error) {
            this.setError(orderResult.error);
          }
          if (orderResult.response && orderResult.response.id) {
            this.pay_url = orderResult.response.url;
            this.pay_id = orderResult.response.respond;

            this.saved = false;
            this.payShow = true;

            this.checkout = new window.YooMoneyCheckoutWidget({
              confirmation_token: orderResult.response.id,
              return_url: this.pay_url,

              error_callback: function(error) {
                console.log(error)
              }

            });

            this.checkout.render('payment-form');

            localStorage.cart = '';

            //localStorage.cart = '';
            //window.location.href = this.pay_url
          }
        }
      } else {
        this.saved = false;
      }

    },

    removeCartItem(i) {
      let cart = JSON.parse(localStorage.cart);
      cart.splice(i, 1);
      localStorage.cart = JSON.stringify(cart);
      this.getCart();
    },

    async getCart() {
      this.cart = [];
      this.cartSum = 0;
      this.cartSumPrepay = 0;

      if (!localStorage.cart) {
        localStorage.cart = ''
      } else {
        if (localStorage.cart.length) {

          let ex_ids = [];

          JSON.parse(localStorage.cart).find(i => {
            ex_ids.push(i.excursion_id)
          })

          let result = await Ajax.report('/excursion/list?filters[excursion_id]=ins|'+ ex_ids.join(',') +'&page=1&limit=100');

          if (result.response) {
            this.ex = result.response.rows[0];
            JSON.parse(localStorage.cart).find(i => {
              result.response.rows.find(ex => {
                if (i.excursion_id === ex.excursion_id) {
                  let itemSum = Math.ceil(
                      this.getSale(
                          ((ex.excursion_price_adult * i.excursion_adults) + (ex.excursion_price_children_free * i.excursion_children_count1) + (ex.excursion_price_children_free2 * i.excursion_children_count3) + (ex.excursion_price_children * i.excursion_children_count2)),
                          ((ex.excursion_prepay_adult * i.excursion_adults) + (ex.excursion_prepay_children_free * i.excursion_children_count1) + (ex.excursion_prepay_children_free2 * i.excursion_children_count3) + (ex.excursion_prepay_children * i.excursion_children_count2))
                      )
                  );

                  let itemSumFull = Math.ceil(
                      ((ex.excursion_price_adult * i.excursion_adults) + (ex.excursion_price_children_free * i.excursion_children_count1) + (ex.excursion_price_children_free2 * i.excursion_children_count3) + (ex.excursion_price_children * i.excursion_children_count2))
                  );
                  let itemSumPrepay = Math.ceil(
                      ((ex.excursion_prepay_adult * i.excursion_adults) + (ex.excursion_prepay_children_free * i.excursion_children_count1) + (ex.excursion_prepay_children_free2 * i.excursion_children_count3) + (ex.excursion_prepay_children * i.excursion_children_count2))
                  );
                  this.cart.push(
                      Object.assign(i, ex, {excursion_item_sum: itemSum, excursion_item_sum_full: itemSumFull})
                  )
                  this.cartSum = Math.ceil(this.cartSum + itemSum)
                  this.cartSumFull = Math.ceil(this.cartSumFull + itemSumFull)

                  this.cartSumPrepay = Math.ceil(ex.excursion_prepay_allow === 'true' ? (this.cartSumPrepay + itemSumPrepay) : (this.cartSumPrepay + itemSum))
                }
              })
            });
          }
        }
      }
    },

  }
}

</script>

<style>
.wrap-map {
  position: relative;
  width: 100%;
}
.full-map {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  z-index: 99;
  cursor: pointer;
}
.showFull {
  .yandex-container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    max-width: none !important;
    max-height: none !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 9999;
  }
  .full-map {
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
  }
}
</style>