<template>

  <div>

    <div class="text">

      <h2>Персональные данные</h2>

      <div class="flex account_avatar">
        <div>
          <Input name="first_name" text="Фамилия" type="text" required="true" v-model.trim="data.first_name" />
          <Input name="last_name" text="Имя" type="text" required="true" v-model.trim="data.last_name" />
          <Input name="account_title" text="Отчество" type="text" required="true" v-model.trim="data.middle_name" />
        </div>
      </div>

      <div>
        <h2>Контактные данные</h2>
        <div class="flex flex-g2">
          <Input name="user_login" text="Электронная почта" type="text" required="true" v-model.trim="data.user_login" />
          <Input name="user_phone" mask="+#(###)###-##-##" text="Телефон" type="text" required="true" v-model.trim="data.user_phone" />
        </div>
      </div>

      <h2>Доступ к кабинету</h2>

      <div class="flex flex-g2">
        <Input name="user_password" text="Новый пароль" type="password" required="true" v-model.trim="data.user_password" />
        <Input name="confirm_password" text="Новый пароль ещё раз" type="password" required="true" v-model.trim="data.confirm_password" />
      </div>

      <div id="policy">
        <button type="submit" class="btn btn-primary" @click="save()" :disabled="!isNew">Сохранить</button>
      </div>
    </div>

  </div>


</template>

<script>
import mixins from '../../../helpers/mixins.js';
import User from '../../../user';
import Ajax from "../../../helpers/ajax";
import Input from '../../../components/Input';


export default {
  mixins: [mixins],

  components: {
    Input
  },

  created () {
    document.title = 'Настройки профиля - личный кабинет';
  },

  mounted() {

    this.data = {
      first_name: User.currentUser.user.account_title.split(' ')[0] || '',
      last_name: User.currentUser.user.account_title.split(' ')[1] || '',
      middle_name: User.currentUser.user.account_title.split(' ')[2] || '',
      user_phone: User.currentUser.user.user_phone,
      user_login: User.currentUser.user.user_login,
      user_password: '',
      confirm_password: ''
    }

    this.old_data = JSON.stringify(this.data)

  },

  watch: {
    'data.first_name'() {
      this.checkIsNew()
    },
    'data.last_name'() {
      this.checkIsNew()
    },
    'data.middle_name'() {
      this.checkIsNew()
    },
    'data.user_phone'() {
      this.checkIsNew()
    },
    'data.user_login'() {
      this.checkIsNew()
    },
    'data.user_password'() {
      this.checkIsNew()
    },
    'data.confirm_password'() {
      this.checkIsNew()
    }
  },

  data () {
    return {
      isNew: false,
      old_data: null,
      data: {
        first_name: User.currentUser.user.account_title.split(' ')[0] || '',
        last_name: User.currentUser.user.account_title.split(' ')[1] || '',
        middle_name: User.currentUser.user.account_title.split(' ')[2] || '',
        user_phone: User.currentUser.user.user_phone,
        user_login: User.currentUser.user.user_login,
        user_password: '',
        confirm_password: ''
      },
      user: User.currentUser.user || {},
    }
  },

  methods: {

    checkIsNew() {

      if (this.old_data !== JSON.stringify(this.data)) {
        this.isNew = true
      } else {
        this.isNew = false
      }

    },

    async save () {
      this.clearError();

      if (this.data.confirm_password !== this.data.user_password) {
        this.setError({
          confirm_password: 'Пароли не совпадают',
          user_password: 'Пароли не совпадают'
        });

        return false;
      }


      let data = {
        user_phone: this.data.user_phone.match(/[\d]/g).join(''),
        user_login: this.data.user_login,
        account_title: this.data.first_name + ' ' + this.data.last_name + ' ' + this.data.middle_name
      };

      if (this.data.confirm_password && this.data.user_password) {
        data.user_password = this.data.user_password
        data.confirm_password = this.data.confirm_password
      }

      let list = [];
      list.push(Ajax.post(`/account/save/` + this.user.account_id, data));

      let [userResult] = await Promise.all(list);

      if (userResult.error) {
        this.setError(userResult.error);
      }

      if (userResult.response) {

        User.loadCurrentUser()

        this.user = User.currentUser.user;

        alert('Внесение изменений в ваши учетные данные прошло успешно')
      }
    },
  }
}

</script>
