<template>
  <div>
    <ImageHeader
        bg="other/header/wishlist-where-trip.webp"
        title="Избранное"
        desc='место где мы храним для вас все что вам понравилось'
    />

    <main>
      <div id="position">
        <div class="container">
          <ul>
            <li>
              <router-link to="/">Главная</router-link>
            </li>
            <li>
              <span class="bread-current">Избранное</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="container margin_60">
        <div class="row">
          <Search />
          <div class="col-lg-9">
            <div class="load-cabinet">

              <div class="col-lg-12 add_bottom_15">

                <Report
                    ref="report"
                    url="/excursion/list"
                    :def-params="{
                  order: {
                    field: 'excursion_id',
                    order: 'desc'
                  },
                  filters: {
                    excursion_id: 'ins|' + wishlist
                  }
                }"
                    v-on:loading="reportLoading" v-on:done="reportLoaded">

                  <template v-if="report && report.rows_count > 0">

                    <div class="row">
                      <div v-for="(row, index) in report.rows" v-bind:key="index" class="col-md-6 wow zoomIn" style="overflow: hidden; padding-top: 3px; visibility: visible; animation-delay: 0s; animation-name: zoomIn;" id="tour-ea81d571-5ea4-11e9-8933-d8d385db1288" data-wow-delay="0s">
                        <div class="tour_container">
                          <div v-if="row.excursion_sale_amount > 0" class="ribbon_3 popular">
                            <span>Скидка</span>
                          </div>
                          <div class="img_container">
                            <router-link :to="'/excursion/' + row.excursion_alias">
                              <v-lazy-image :src="'/img/excursion/list/' + (row.excursion_images ? row.excursion_images[0] : 'n.webp')" style="max-height:226px;" width="800" height="533" class="img-fluid" :alt="row.excursion_title" />
                              <div class="short_info">
                        <span class="tour-info-short">
                          <span><div font-awesome-icon icon="fas fa-bus" /> - {{types[row.excursion_type]}}
                          </span>
                          <span><div font-awesome-icon icon="far fa-clock" /> ~
                          {{prepareDuration(row.excursion_duration)}}
                          </span>
                        </span>
                                <span class="rating">
                                   <span class="rating-txt" v-if="!row.comment_count">
                                    Отзывов пока<br>ещё нет
                                  </span>
                                  <span class="rating-txt" v-else>
                                    Очень хорошо!<br>{{row.comment_count}} {{declination(row.comment_count, ['отзыв', 'отзыва', 'отзывов'])}}
                                  </span>

                          <span class="rating-box">
                            <span style="text-align: center;display: block;font-size: 14px;font-weight: 600;padding-top: 5px;line-height: 10px;">{{row.excursion_rating || 'X'}}</span>
                            <span style="display: block;font-size: 7px;text-align: center;">рейтинг</span>
                          </span>
                        </span>
                              </div>
                            </router-link>
                          </div>
                          <div class="tour_title" style="">
                            <router-link :to="'/excursion/' + row.excursion_alias">
                              <h3><strong>{{row.excursion_title}}</strong></h3>
                            </router-link>
                            <div style="line-height:12px;">
                              <span style="font-size:11px;display:block;">
                                <div font-awesome-icon icon="fas fa-map-marker-alt" style="margin:0;font-size: 8px;color: #ffdb4c;" />&nbsp;&nbsp;<span style="font-size:11px;">Анапа</span>
                              </span>
                              <span @click="removeWishlist(row.excursion_id)" class="tooltip_styled tooltip-effect-4 tooltip-item wish_remove" style="float: right;display: inline-block;position: absolute;bottom: 24px;right:20px;cursor:pointer;">
                                <i class="fa fa-heart" aria-hidden="true"></i></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                    <div v-else class="title_no_txt">
                      <i class="far fa-frown" style="font-size:100px;" aria-hidden="true"></i>
                      <h2>Пока тут пусто...</h2>
                      Вы еще не добавили в свое избранное ни одной интересной услуги
                    </div>
                </Report>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>


</template>

<script>
import mixins from '../../helpers/mixins.js';

import Report from '../../components/report/Report';

import ImageHeader from '../default/ImageHeader';
import Search from '../excursion/_search';
import $ from "jquery";


export default {
  mixins: [mixins],

  components: {
    ImageHeader,
    Search,
    Report
  },

  created() {
    document.title = 'Избранное'
  },

  data () {
    return {
      data: {},
      error: null,
      report: null,
      wishlist: localStorage.wishlist !== '' ? localStorage.wishlist : 0,

      types: {
        group: 'Групповой тур',
        individual: 'Индивидуальный тур',
        you: 'Самостоятельно'
      }
    }
  },

  methods: {
    removeWishlist(id) {
      id = id + '';

      let w = localStorage.wishlist.split(',') || [];

      w.splice(w.indexOf(id), 1);

      if (w[0] === '') {
        w.splice(0, 1);
      }

      localStorage.wishlist = w;
      this.wishlist = localStorage.wishlist ? w : 0

      $('.wish_count').html(w.length)

      this.getReport().changeReportParams({
        filters: {
          excursion_id: 'ins|' + this.wishlist
        }
      }, false);
    },

    getReport() {
      return this.$refs.report;
    },

    reportLoading() {
      this.error = null;
    },
    reportLoaded(report) {

      if (report) {
        if (report.response) {
          this.report = report.response;
          this.error = null;
        } else {
          this.report = null;
          this.error = 'Неизвестная ошибка.';
        }
      }
    },
  }

}

</script>
