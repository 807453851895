<template>
  <main>

    <ImageHeaderHome />

    <h2 class="w">
      Популярные направления
    </h2>
    <div class="w" v-if="!categoryLoaded">
      <div class="direction">
        <div class="item">
          <div class="card-box">
            <div style="height: 160px;background: #ccc;border-radius: 10px;margin-bottom: 16px"></div>
          </div>
          <h3>
            <div style="width:80%;height: 20px;background: #ccc;"></div>
          </h3>
          <div class="item-desc">
            <div style="width:90%;height: 20px;background: #ccc;"></div>
            <div style="width:40%;height: 20px;background: #ccc;"></div>
          </div>
        </div>
        <div class="item">
          <div class="card-box">
            <div style="height: 160px;background: #ccc;border-radius: 10px;margin-bottom: 16px"></div>
          </div>
          <h3>
            <div style="width:80%;height: 20px;background: #ccc;"></div>
          </h3>
          <div class="item-desc">
            <div style="width:90%;height: 20px;background: #ccc;"></div>
            <div style="width:40%;height: 20px;background: #ccc;"></div>
          </div>
        </div>
        <div class="item">
          <div class="card-box">
            <div style="height: 160px;background: #ccc;border-radius: 10px;margin-bottom: 16px"></div>
          </div>
          <h3>
            <div style="width:80%;height: 20px;background: #ccc;"></div>
          </h3>
          <div class="item-desc">
            <div style="width:90%;height: 20px;background: #ccc;"></div>
            <div style="width:40%;height: 20px;background: #ccc;"></div>
          </div>
        </div>
        <div class="item">
          <div class="card-box">
            <div style="height: 160px;background: #ccc;border-radius: 10px;margin-bottom: 16px"></div>
          </div>
          <h3>
            <div style="width:80%;height: 20px;background: #ccc;"></div>
          </h3>
          <div class="item-desc">
            <div style="width:90%;height: 20px;background: #ccc;"></div>
            <div style="width:40%;height: 20px;background: #ccc;"></div>
          </div>
        </div>
        <div class="item">
          <div class="card-box">
            <div style="height: 160px;background: #ccc;border-radius: 10px;margin-bottom: 16px"></div>
          </div>
          <h3>
            <div style="width:80%;height: 20px;background: #ccc;"></div>
          </h3>
          <div class="item-desc">
            <div style="width:90%;height: 20px;background: #ccc;"></div>
            <div style="width:40%;height: 20px;background: #ccc;"></div>
          </div>
        </div>


      </div>
    </div>
    <div class="w" v-if="data.category && data.category.length">
      <div class="direction">
        <template v-for="(row, index) in data.category.sort((a,b) => {return a.category_id - b.category_id})" >
          <router-link class="item" v-bind:key="index" v-if="[15, 14, 13, 12, 11].indexOf(row.category_id) > -1" :to="'/' + getCityCode() + '/excursion/' + row.category_url+'/'">
            <div class="card-box">
              <img :src="domain+'/img/excursion/list/' + row.category_image" :alt="row.category_title" />

              <div class="price">
                {{ row.ex_count }} {{declination(row.ex_count, ['экскурсия', 'экскурсии', 'экскурсий'])}},
                от {{ formatRUB(row.ex_min_price) }}
              </div>
            </div>
           <h3>
              {{ row.category_title }}
            </h3>
            <div class="item-desc">
              {{row.category_page_subtitle}}
            </div>
          </router-link>
        </template>
      </div>
    </div>


  <div class="h" style="min-height: 950px">
    <div class="w flex flex-jc_sp flex-ai_c">
      <h2>
        Популярные экскурсии
      </h2>
      <div class="slider-nav">
        <button class="prev" v-on:click="sliderSet('prev')"></button>
        <button class="next" v-on:click="sliderSet('next')"></button>
      </div>
    </div>

    <Report
        class="popular w"
        ref="report"
        v-on:wheel.prevent="sliderScroll"
        url="/excursion/list"
        :def-params="{
                  order: {
                    field: 'excursion_rating',
                    order: 'desc'
                  },
                  filters: {
                    excursion_city: 'in|' + getCityId(),
                    't.excursion_publication': 'eq|enabled'
                  },
                  limit: 9
                }"
        v-on:loading="reportLoading"
        v-on:done="reportLoaded"
    >

      <template v-if="report && report.rows_count > 0">
        <template v-for="(row, index) in report.rows">
          <div class="item" v-bind:key="index" v-if="index < 9">

            <router-link :to="'/' + getCityCode() + '/excursion/' + row.excursion_alias+'/'">
              <v-lazy-image
                  :src="domain+'/img/excursion/list/' + (row.excursion_images ? row.excursion_images[0] : 'n.webp')"
                  width="800"
                  height="533"
                  :alt="row.excursion_title"
              />
              <div class="desc">
                <div class="price">
                  {{formatRUB(row.excursion_price_adult)}} —
                  {{prepareDuration(row.excursion_duration)}}
                </div>

                <div class="rating dropdown-event">

                  <template v-if="row.comment_count">
                    <b :data-r="row.excursion_rating" v-if="row.excursion_rating" :style="{'--p': `${row.excursion_rating / 0.05 * 3.6}deg`}"></b>
                    <div class="dropdown-content bottom right">
                      {{row.comment_count}} {{declination(row.comment_count, ['отзыв', 'отзыва', 'отзывов'])}}
                    </div>
                  </template>
                </div>
              </div>
            </router-link>


            <div v-if="row.excursion_sale_amount > 0" class="stock">
              Скидка
            </div>

            <h3>
              <router-link :to="'/' + getCityCode() + '/excursion/' + row.excursion_alias+'/'">
                {{row.excursion_title}}
              </router-link>
            </h3>

            <component :is="'script'" type="application/ld+json">
              {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "{{getCityTitle()}}",
              "item": "https://vizit.tours{{`/${getCityCode()}/`}}"
              }
              ]
              }
            </component>

            <component :is="'script'" type="application/ld+json">
              {
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "{{row.excursion_title}}",
              "image": [
              "https://vizit.tours/img/excursion/list/{{ row.excursion_images ? row.excursion_images[0] : 'n.webp' }}"
              ],
              "description": "{{row.excursion_desc}}",
              "sku": "{{row.excursion_id}}",
              "mpn": "r{{row.excursion_id}}",
              "brand": {
              "@type": "Brand",
              "name": "ТЭФ Визит"
              },
              "review": {
              "@type": "Review",
              "reviewRating": {
              "@type": "Rating",
              "ratingValue": "{{row.excursion_rating}}",
              "bestRating": "{{row.excursion_rating}}"
              },
              "author": {
              "@type": "Person",
              "name": "ТЭФ Визит"
              }
              },
              "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "{{row.excursion_rating}}",
              "reviewCount": "{{parseInt(row.excursion_rating*4)}}"
              },
              "offers": {
              "@type": "AggregateOffer",
              "offerCount": "100",
              "lowPrice": "{{ row.excursion_price_children_free }}",
              "highPrice": "{{row.excursion_price_adult}}",
              "priceCurrency": "RUB"
              }
              }

            </component>

          </div>
        </template>
      </template>
    </Report>

    <div class="steps">
      <div class="w">

        <h4>Просто и быстро</h4>

        <h2>
          Отправьтесь в незабываемое<br>
          путешествие всего за 3 шага
        </h2>

        <ul>
          <li>
            <h4>
              Выберите подходящий вам тур
            </h4>
            Мы подготовили для вас 58 уникальный
            маршрутов, по 10 различным тематикам
          </li>
          <li>
            <h4>
              Оплатите заказ
            </h4>
            Заполните всего 3 поля и оплатите заказ
          </li>
          <li>
            <h4>
              Отправляйтесь на встречу приключениям
            </h4>
            Мы заберём вас с вашего адреса или с выбранной
            точки посадки и доставим обратно
          </li>
        </ul>

        <div class="pay-card" v-if="report && report.rows_count > 0">
          <v-lazy-image
              :src="domain+'/img/excursion/list/' + (report.rows[0].excursion_images ? report.rows[0].excursion_images[0] : 'n.webp')"
              width="800"
              height="533"
              :alt="report.rows[0].excursion_title"
          />

          <h4>
            {{report.rows[0].excursion_title}}
          </h4>

          <p class="desc">
            {{convertDate(new Date())}} | 3 человека
          </p>

          <b class="btn btn-primary">
            Оплатить
          </b>
        </div>
      </div>
    </div>
  </div>
    <Report
        ref="report_comment"
        url="/comment/list"
        v-on:loading="report_commentLoading"
        v-on:done="report_commentLoaded"
    >
    <div class="w review">
      <div>
        <h4>Отзывы</h4>
        <h2>
          Что люди говорят о нас:
        </h2>
      </div>
      <div class="review-wrap">
        <template v-if="report_comment && report_comment.rows_count > 0">
          <template v-for="(row, index) in report_comment.rows">
            <div class="item" v-if="index < 90" v-bind:key="index">
              <p v-html="row.comment_text" :class="{show: currentReview === index}"></p>
              <a href="#" class="read-more" v-on:click.prevent="currentReview = currentReview === index ? null : index">
                <template v-if="currentReview === index">
                  Скрыть
                </template>
                <template v-else>
                  Читать полностью
                </template>
              </a>
              <h4>
                {{ row.comment_user_text }}
              </h4>
              <h5>
                {{ row.excursion_title }}
              </h5>
            </div>
          </template>
        </template>
      </div>
    </div>
    </Report>

    <div class="w">
      <h4>Про отдых в Анапе и не только</h4>
      <h2>
        <router-link :to="`${ getCityCode() }/blog/`">
          Свежее в нашем блоге
        </router-link>
      </h2>

      <Report
          ref="report2"
          url="/blog/list"
          :def-params="{
                  order: {
                    field: 'ts',
                    order: 'desc'
                  },
                  limit: 3
             }"
          v-on:loading="reportLoading2"
          v-on:done="reportLoaded2"
      >
        <template v-if="report2 && report2.rows_count > 0">
          <div class="blog">
            <div class="item" v-for="(row, index) in report2.rows" v-bind:key="index">
              <router-link :to="`/${getCityCode()}/blog/${row.blog_category_url}/${row.blog_post_url}/`">
                <v-lazy-image v-if="row.blog_post_image" :src="domain+'/img/blog/preview/' + row.blog_post_image" :alt="row.blog_post_title"/>
                <v-lazy-image v-else src="/img/blog/preview/mountain-men-and-women-tur.webp" :alt="row.blog_post_title" />
              </router-link>
              <h3>
                <router-link :to="`/${getCityCode()}/blog/${row.blog_category_url}/${row.blog_post_url}/`">
                  {{row.blog_post_title}}
                </router-link>
              </h3>
              <p class="desc">
                {{row.blog_post_text}}...
              </p>
              <p class="date">
                {{convertDate(row.ts, 'DD')}} {{convertDate(row.ts, 'MMM')}}
              </p>
            </div>
          </div>
        </template>
      </Report>
    </div>
  </main>
</template>

<script>

import mixins from '../../helpers/mixins.js'

import ImageHeaderHome from '../default/ImageHeaderHome';
import Ajax from '../../helpers/ajax';
import Report from '../../components/report/Report';

export default {
  mixins: [mixins],
  name: 'home',

  components: {
    ImageHeaderHome,
    Report
  },
  created() {
    document.title = 'Экскурсии в ' + this.getCityTitle(3) + ' расписание на лето 2024 года - ТЭФ Визит';
    document.querySelector('meta[name="description"]').setAttribute("content", 'Экскурсии в ' + this.getCityTitle(3) + ', каталог с подробным описанием и ценами в 2024 году. Экскурсии в Крым, Абхазию, на Азовское море и по окрестностям Анапы.');
    this.loadCategory();
  },

  data () {
    return {
      categoryLoaded: false,
      currentReview: null,
      reviews: [],
      data: {
        category: [{
          category_title: null,
          category_url: null
        }]
      },
      error: null,
      report: null,
      error2: null,
      report2: null,
      report_comment: null,
      error_comment: null,
      showVideo: false,
      types: {
        group: 'Групповой тур',
        individual: 'Индивидуальный тур',
        you: 'Самостоятельно'
      }
    }
  },

  methods: {

    sliderScroll(e) {
      this.$refs.report.$el.scrollLeft = this.$refs.report.$el.scrollLeft + (e.deltaX + e.deltaY)
    },

    sliderSet(type) {
      if (type === 'next') {
        this.$refs.report.$el.scrollLeft = this.$refs.report.$el.scrollLeft + 360
      } else {
        this.$refs.report.$el.scrollLeft = this.$refs.report.$el.scrollLeft - 360
      }
    },

    reportLoading() {
      this.error = null;
    },
    report_commentLoading() {
      this.error = null;
    },

    reportLoading2() {
      this.error2 = null;
    },


    reportLoaded(report) {
      if (report) {
        if (report.response) {
          this.report = report.response;
          this.error = null;
        } else {
          this.report = null;
          this.error = 'Неизвестная ошибка.';
        }
      }
    },

    report_commentLoaded(report) {
      if (report) {
        if (report.response) {
          this.report_comment = report.response;
          this.error_comment = null;
        } else {
          this.report_comment = null;
          this.error_comment = 'Неизвестная ошибка.';
        }
      }
    },

    reportLoaded2(report2) {
      if (report2) {
        if (report2.response) {
          this.report2 = report2.response;
          this.error2 = null;
        } else {
          this.report2 = null;
          this.error2 = 'Неизвестная ошибка.';
        }
      }
    },

    async loadCategory() {
      let result = await Ajax.get('/category/all');
      if (result.response) {
        this.data.category = result.response;
        this.categoryLoaded = true
      }
    },

  }
}
</script>
