<template>
  <div class="w page">

    <div class="page-header">
      <h1 class="mb0">Пользовательское соглашение</h1>
    </div>

    <main>

      <nav class="breadcrumb">
        <router-link to="/">Главная</router-link>
        <router-link to="/about/">Информация о компании</router-link>
        <span class="bread-current">Пользовательское соглашение</span>
      </nav>

      <AboutMenu />


      <div class="text">
        <p><strong>Условия соглашения</strong></p>
        <p>Данное соглашение является неотъемлемой частью <a href="https://vizit.tours/about/offer/">договора оферты</a>. Продолжая использовать наш интернет - сайт <a href="https://vizit.tours">https://vizit.tours</a> Вы автоматически соглашаетесь с условиями договора и данного пользовательского соглашения.</p>
        <p><strong>Использование файлов Cookie</strong></p>
        <p>Наш сайт использует файлы cookie и похожие технологии, чтобы гарантировать максимальное удобство пользователям, предоставляя персонализированную информацию, запоминая предпочтения в области маркетинга и продукции, а также помогая получить правильную информацию. При использовании данного сайта, Вы подтверждаете свое согласие на использование файлов cookie в соответствии с настоящим уведомлением в отношении данного типа файлов. Если Вы не согласны с тем, чтобы мы использовали данный тип файлов, то Вы должны соответствующим образом установить настройки вашего браузера или не использовать сайт <a href="https://vizit.tours">https://vizit.tours</a></p>
        <p><strong>Как управлять файлами cookie?</strong></p>
        <p>Большинство интернет-браузеров изначально настроены автоматически принимать cookie. Вы можете изменить настройки таким образом, чтобы блокировать cookie, или предупреждать пользователя, когда файлы данного типа будут отправлены на устройство.</p>
        <p>Есть несколько способов управления cookie. Пожалуйста, обратитесь к инструкции браузера для того, чтобы узнать больше о том, как скорректировать или изменить настройки браузера.</p>
        <p>Если отключить cookie, которые мы используем, то это может повлиять на вашу работу в Интернете, в то время как на <a href="https://vizit.tours">https://vizit.tours</a> Вы, возможно, не сможете получать персональную информацию при посещении сайта.</p>
        <p>Если Вы используете различные устройства для просмотра и доступа к нашему сайту (например, компьютер, смартфон, планшет и т.д.), Вы должны убедиться, что каждый браузер на каждом устройстве настроен в соответствии с Вашей точкой зрения на работу с файлами cookie.</p>
        <p><strong>Обработка персональных данных</strong></p>
        <p>Настоящим в соответствии с Федеральным законом от 27.07.2006 года № 152-ФЗ «О персональных данных» Вы подтверждаете свое согласие на обработку сайтом Общества с ограниченной ответственностью «ТУРИСТСКО - ЭКСКУРСИОННАЯ ФИРМА «ВИЗИТ» (ООО «ТЭФ «ВИЗИТ») персональных данных: сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, передачу исключительно в целях оказания туристских и связанных с туризмом услуг, как это описано ниже, блокирование, обезличивание, уничтожение.</p>
        <p>ООО «ТЭФ «ВИЗИТ» гарантирует конфиденциальность получаемой информации. Обработка персональных данных осуществляется в целях эффективного исполнения заказов, договоров и иных обязательств, принятых ООО «ТЭФ «ВИЗИТ» в качестве обязательных к исполнению.</p>
        <p><strong>Какие данные мы сохраняем?</strong></p>
        <p>Настоящее согласие распространяется на следующие Ваши персональные данные: фамилия, имя и отчество адрес электронной почты почтовый адрес доставки контактный телефон платёжные реквизиты Срок действия согласия является неограниченным.</p>
        <p><strong>Отказ от предоставления персональных данных</strong></p>
        <p>Вы можете в любой момент отозвать настоящее согласие, направив письменное уведомления на адрес 353440 Краснодарский край, г.-к. Анапа, ул. Лермонтова, 116 г., оф. 12 с пометкой «Отзыв согласия на обработку персональных данных».</p>
        <p>Обращаем ваше внимание, что отзыв согласия на обработку персональных данных влечёт за собой удаление Вашей учётной записи с Интернет-сайта <a href="https://vizit.tours">https://vizit.tours</a>, а также уничтожение записей, содержащих Ваши персональные данные, в системах обработки персональных данных ООО «ТЭФ «ВИЗИТ», что может сделать невозможным пользование в дальнейшем интернет-сервисами фирмы для получения различных скидок и бонусов согласно действующей программе лояльности.</p>
        <p>Вы гарантируете, что представленная Вами информация является полной, точной и достоверной, а также, что при представлении информации не нарушаются действующее законодательство Российской Федерации, законные права и интересы третьих лиц. Вся представленная информация заполнена Вами в отношении себя лично.</p>
        <p><strong>Срок хранения данных</strong></p>
        <p>Настоящее согласие действует в течение всего периода хранения персональных данных, если иное не предусмотрено законодательством Российской Федерации.</p>
        <p><strong>Интеллектуальная собственность</strong></p>
        <p>Вся текстовая информация и графические изображения, находящиеся на сайте, являются собственностью ООО «ТЭФ «ВИЗИТ», либо используются в рамках лицензионного соглашения с автором контента.</p>
        <p>Перепечатка, воспроизведение в любой форме, распространение, в том числе в переводе, любых материалов с сайта возможны только с письменного разрешения генерального директора ООО «ТЭФ «ВИЗИТ».</p>
        <p>В случае перепечатки материалов пользователь обязан сослаться на сайт ООО «ТЭФ «ВИЗИТ» как на первоисточник публикации. Если материалы размещаются в Интернете, то необходимо сделать активную индексируемую ссылку на сайт.</p>
        <p>Документы и связанные с ними графические изображения, расположенные на сайте, могут быть использованы только в информационных, некоммерческих или личных целях. Никакие документы и связанные с ними графические изображения, расположенные на сайте, не должны изменяться никаким способом. Никакие графические изображения, расположенные на сайте, не должны использоваться отдельно от сопровождающего их текста.</p>
        <p>Документы и связанные с ними графические изображения, расположенные на сайте, могут включать неточности или орфографические ошибки. Изменение информации на сайте производится периодически.</p>
      </div>

    </main>
  </div>
</template>
<script>

import AboutMenu from './_menu';
import $ from "jquery";

export default {

  created() {
    document.title = 'Соглашение'
    $('meta[name="description"]').attr('content', 'Пользовательское соглашение компании ТЭФ Визит')
  },

  components: {
    AboutMenu
  }
}



</script>