<template>
    <nav class="tabs">
        <router-link class="tab" to="/about/">
          О компании
        </router-link>
        <router-link class="tab" to="/about/contacts/">
          Контакты
        </router-link>
        <router-link class="tab" :to="`/${getCityCode()}/about/point-sale/`">
          Точки продаж
        </router-link>
        <router-link class="tab" to="/about/privacy/">
          Соглашение
        </router-link>
      <router-link class="tab" to="/about/offer/">
        Договор оферты
      </router-link>
      <router-link class="tab" to="/about/refund/">
        Правила возврата
      </router-link>
    </nav>
</template>

<script>
import mixins from '../../helpers/mixins.js';

export default {
  mixins: [mixins]
}
</script>