<template>
  <div class="w page">

    <div class="page-header">
      <h1 class="mb0">{{ title() }}</h1>
    </div>

    <main>

      <nav class="breadcrumb">
        <router-link to="/">Главная</router-link>
        <router-link to="/cabinet/orders/">Личный кабинет</router-link>
        <span class="bread-current">{{ title() }}</span>
      </nav>


      <CabinetMenu :orders="orders" />

      <Main v-if="page === 'main'" />
      <Wishlist v-if="page === 'wishlist'" />
      <Cart :ex="{}" v-if="page === 'cart'" />
      <Orders v-if="page === 'orders'" :account="account_id" />
      <Bonus v-if="page === 'bonus'" />
      <Subscribe v-if="page === 'subscribe'" />
      <Profile v-if="page === 'profile'" />

    </main>
  </div>


</template>

<script>
import mixins from '../../helpers/mixins.js';
import CabinetMenu from './_menu';

import Main from './tabs/Main';
import Wishlist from './tabs/Wishlist';
import Cart from './tabs/Cart';
import Orders from './tabs/Orders';
import Bonus from './tabs/Bonus';
import Subscribe from './tabs/Subscribe';
import Profile from './tabs/Profile';

import User from '../../user';

export default {
  mixins: [mixins],

  watch: {
    '$route'() {
      this.page = this.$route.params.page || 'orders';
    },
  },

  created() {
    if (!this.loggedIn) {
      this.$router.push({ path: '/' });
    }
  },

  components: {
    CabinetMenu,
    Main,
    Wishlist,
    Cart,
    Orders,
    Bonus,
    Subscribe,
    Profile
  },
  data () {
    return {
      orders: User.currentUser.account.orders || 0,
      account_id: User.currentUser.account.account_id || 0,
      loggedIn: User.isLoggedIn(),
      pageTitle: {
        orders: 'Мои заказы',
        bonus: 'Бонусный счёт',
        subscribe: 'Настройки уведомлений',
        profile: 'Настройки профиля',
      },
      page: this.$route.params.page || 'main'
    }
  },

  methods: {
    title() {
      return this.pageTitle[this.page]
    }
  }


}

</script>
