<template>
    <section class="auth-main">
        <div class="auth">
            <div class="full-width-content" v-if="loading">
                <div class="loading-report">
                    Загрузка...
                </div>
            </div>

            <div v-else>
                <div v-if="errorText">
                    <div class="reset-invalid-code">
                        Неверный код
                        <router-link to="/auth/forgot/">Назад</router-link>
                    </div>
                </div>

                <div v-else>
                    Новый пароль отправлен вам на телефон
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    import User from '../../user';

    export default {

        data () {
            return {
                loading: true,
                errorText: ''
            }
        },

        created () {
            this.reset();
        },
        
        methods: {

            async reset () {
                let uid = this.$route.query.uid,
                    h = this.$route.query.h;

                if (!uid || !h) {
                    this.errorText = 'Что-то пошло не так:(';
                    this.loading = false;
                    return;
                }

                let result = await User.reset(uid, h);

                if (!result.response) {
                     const errors = {
                        309: 'Что-то пошло не так:('
                    };

                    if (errors[result.code]) {
                        this.errorText = errors[result.code];
                        this.loading = false;
                        return;
                    }

                    this.errorText = 'Что-то пошло не так:(';
                }

                this.loading = false;
            }

        }
    }
</script>