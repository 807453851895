<template>

  <div class="header-home">
    <div class="w">
      <h1>
        Экскурсии<br>
        из {{ getCityTitle(1) }}
      </h1>

    </div>
  </div>


</template>

<script>

import mixins from '../../helpers/mixins.js';

export default {
  mixins: [mixins],

  name: 'ImageHeaderHome',

  data () {
    return {
      data: {}
    }
  },
}
</script>