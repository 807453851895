<template>
  <div class="input" v-bind:class="{ 'error': !!inputError }">
    <label v-bind:for="id" class="input-placeholder">{{ text }}</label>

    <input v-if="mask"
        ref="input"
        v-on:input="$emit('update:modelValue', inputValue)"
        :type="inputType"
        :class="{ 'empty': isEmpty, required: isRequired, 'input--readonly': isReadonly }"
        v-model="inputValue"
        :name="name"
        :disabled="disabled"
        :id="id"
        :readonly="isReadonly"
        :maxlength="inputCount !== null ? inputCount : '' "
        :autocomplete="autocomplete"
        :min="min"
        :max="max"
        v-mask="mask"
    />

    <input
        v-else
        ref="input"
        v-on:input="$emit('update:modelValue', inputValue)"
        :type="inputType"
        :class="{ 'empty': isEmpty, required: isRequired, 'input--readonly': isReadonly }"
        v-model="inputValue"
        :name="name"
        :disabled="disabled"
        :id="id"
        :readonly="isReadonly"
        :maxlength="inputCount !== null ? inputCount : '' "
        :autocomplete="autocomplete"
        :min="min"
        :max="max"
    />


    <div class="msg-error" v-if="inputError">{{ inputError }}</div>
    <div
        v-if="isPassword"
        v-on:click="showPassword"
        :class="{'input-icon-active': isEyeActive}"
         class="input-icon rui rui-vi">
    </div>
    <div class="input-icon" :data-icon="icon" v-if="icon"></div>

    <div v-if="inputCount !== null" class="input-count">{{ inputCount - (inputValue ? inputValue.length : 0) }}
    </div>
    <div v-if="icon2" class="input-count">{{ icon2 }}</div>
  </div>
</template>

<script>
import $ from "jquery";

// eslint-disable-next-line no-unused-vars
import numeric from '../libs/jquery.alphanum';


export default {
  name: 'Input',

  props: {
    name: String,
    mask: {
      type: String,
      default: ''
    },
    text: String,
    modelValue: {
      default: ''
    },
    required: String,
    readonly: String,
    disabled: String,
    type: {
      type: String,
      default: 'text'
    },
    filterType: {
      type: String,
      default: 'eq'
    },
    numericList: String,
    numeric: String,
    icon: String,
    icon2: String,
    count: {
      type: String,
      default: null
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    inputParent: Function
  },

  emits: ['update:modelValue'],

  computed: {
    isEmpty() {
      return ((this.inputValue === null) || (this.inputValue === ''));
    },

    isEyeActive() {
      return (this.inputType === 'text');
    }
  },

  watch: {
    modelValue() {

      if (this.max) {
        if (this.max && this.modelValue > this.max) {
          this.inputValue = this.max
        } else {
          if (this.min && this.modelValue < this.min) {
            this.inputValue = this.min
          } else {
            this.inputValue = this.modelValue
          }
        }

        this.$emit('update:modelValue', this.inputValue)
      } else {
        this.inputValue = this.modelValue;
      }

      if (this.inputParent) {
        this.inputParent(this.modelValue, this.name);
      }
    }
  },

  data() {
    return {
      id: `inp_${this.name.toLowerCase()}`,
      inputType: this.type.toLowerCase(),
      inputValue: this.modelValue,
      inputError: '',
      inputCount: this.count,
      isRequired: this.isTrue(this.required),
      isReadonly: this.isTrue(this.readonly),
      isPassword: (this.type.toLowerCase() === 'password'),
      filterTypeValue: this.filterType,
    }
  },

  mounted() {

    if (this.isTrue(this.numericList)) {
      $(this.$refs.input).alphanum({
        allowSpace: false,
        allowUpper: false,
        allowLower: false,
        allowNewline: false,
        allowNumeric: true,
        allowCaseless: false,
        allowLatin: false,
        allowOtherCharSets: false,
        min: this.min,
        max: this.max,
        allow: '1234567890,'
      });
    } else if (this.isTrue(this.numeric)) {
      $(this.$refs.input).numeric({negative: false, decimal: false});
    }
  },

  created() {

    if (!this.$root.$children) {
      this.$root.$children = [this];
    } else {
      this.$root.$children.push(this);
    }
  },


  methods: {
    clear() {
      this.inputValue = '';
    },

    isTrue(str) {
      return (str) && (['1', 't', 'true', 'y', 'yes'].indexOf(str) > -1);
    },

    getValue() {
      return this.inputValue;
    },

    setValue(val) {
      this.inputValue = val;
    },

    getError() {
      return this.inputError;
    },

    setError(val) {
      this.inputError = val;
    },

    clearError() {
      this.inputError = '';
    },

    getFilterType() {
      return this.filterTypeValue;
    },

    setFilterType(val) {
      this.filterTypeValue = val;
    },

    insertAtCaret(text) {
      let tmpVal = this.getValue(),
          el = this.$refs.input;

      el.focus();
      let newVal = tmpVal.substring(0, el.selectionStart) + text + tmpVal.substring(el.selectionEnd, tmpVal.length);
      this.setValue(newVal);
      return newVal;
    },

    showPassword() {
      if (this.inputType === 'password') {
        this.inputType = 'text';
      } else {
        this.inputType = 'password';
      }

      this.$nextTick(() => {
        // Hack to set focus at the end of the string
        this.$refs.input.focus();
      });
    }
  }
}
</script>