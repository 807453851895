<template>
    <section class="auth-main">
        <div class="auth">
            
            <div v-if="is_forgot">
                <h1>
                   Пожалуйста, проверьте свой телефон.
                </h1>
                <div class="h1-desc">
                    Мы отправили проверочный код на <br>
                    <a href="#">
                        {{user_phone}}
                    </a>
                </div>
            </div>

            <div v-else>
                <h1>
                    Напомнить пароль?
                </h1>
                <div class="h1-desc">
                    Для сброса пароля, введите свой номер телефона
                </div>

                <div v-if="errorText" class="error-box">{{errorText}}</div>

                <Input name="user_phone" text="E-Mail" required="true" v-model.trim="user_phone" />

                <div class="submit">
                    <div class="g2">
                        <div>
                            <button class="btn btn-primary" v-on:click="forgot">Отправить</button>
                        </div>
                        <div>
                            <router-link to="/auth/login/">Войти</router-link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    import User from '../../user';
    import Input from '../../components/Input';

    export default {
        components: {
            Input
        },

        data () {
            return {
                is_forgot: false,
                user_phone: '',
                recaptcha: '',
                showCaptcha: false,
                errorText: ''
            }
        },
        methods: {
            verifyRecaptcha(response) {
                this.recaptcha = response;
            },

            resetRecaptcha() {
                this.recaptcha = '';
            },
            
            async forgot () {
                this.errorText = '';

                if (!this.user_phone) {
                    this.errorText = 'Incorrect phone';
                    return;
                }

                let result = await User.forgot(this.user_phone, this.recaptcha);

                if (result.user_phone) {
                    this.is_forgot = true;
                    this.user_phone = result.user_phone;
                    this.showCaptcha = false;
                } else {
                    this.recaptcha = '';
                    if (this.$refs.recaptcha) {
                        this.$refs.recaptcha.reset();
                    }

                    const errors = {
                        299: 'Неверный номер телефона',
                        308: 'Не нашлось аккаунта с таким номером телефона'
                    };

                    if (errors[result.code]) {
                        this.errorText = errors[result.code];
                        return;
                    }

                    if (result.code === 304) {
                        this.showCaptcha = true;
                        this.errorText = 'Невозможно проверить капчу. Пожалуйста, попробуйте снова.';
                        return;
                    }

                    this.errorText = 'Что-то пошло не так:(';
                }
            }
        }
    }
</script>