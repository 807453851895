import moment from 'moment-timezone';
import localization from 'moment/locale/ru';
import $ from "jquery";

moment().locale('ru', localization)
moment.tz.setDefault("Europe/Moscow");

export default {
    methods: {

        getCityCode() {
            return localStorage.getItem('c_code') || 'anapa';
        },

        getCityTitle(type = 0) {

            let city = JSON.parse(localStorage.getItem('cities'));
            let currentCity = localStorage.getItem('c_code') || 'anapa';


            city = city.find(i => i.city_code === currentCity).city_title

            if (type === 1) {
                let lastSymbol = city.split('').pop();
                switch (lastSymbol) {
                    case 'а':
                        city = city.slice(0, -1) + 'ы';
                        break;
                    case 'к':
                        city = city + 'ы';
                        break;
                    case 'г':
                        city = city + 'а';
                        break;
                }
            }

            if (type === 3) {
                let lastSymbol = city.split('').pop();
                switch (lastSymbol) {
                    case 'а':
                        city = city.slice(0, -1) + 'е';
                        break;
                    case 'к':
                        city = city + 'е';
                        break;
                    case 'г':
                        city = city + 'е';
                        break;
                }
            }
            return city;
        },

        getCityId() {
            let city = JSON.parse(localStorage.getItem('cities'));
            let currentCity = localStorage.getItem('c_code') || 'anapa';

            return city.find(i => i.city_code === currentCity).city_id
        },

        formatNumber(num, def = '0', digits = 2) {
            if (!num) {
                return def;
            }
            return (+num.toFixed(digits)).toLocaleString('en-US');
        },

        declination(n, t) {
            let cases = [2, 0, 1, 1, 1, 2];
            return t[ (n%100>4 && n%100<20)? 2:cases[(n%10<5)?n%10:5] ];
        },

        formatPhone(p) {
            let pattern = /(\d)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
            return p.replace(pattern, '+7 ($2) $3-$4-$5');
        },

        formatNumberSign(number, def, digits) {
            number = +number.toFixed(4);
            var sign = (number > 0) ? '+' : '';
            var result = this.formatNumber(number, def, digits);
            return sign + result;
        },

        formatRUB(amount, digits = 0) {
            var formatter = new Intl.NumberFormat('ru-RU', {
                style: 'currency',
                currency: 'RUB',
                minimumFractionDigits: digits
            });

            return amount ? formatter.format(amount) : formatter.format(0);
        },

        convertDate(date, format = 'DD.MM.YYYY в HH:mm', int = false) {
            if (int) {
                return moment.unix(date / 1000).format(format);
            } else {
                return moment(date).format(format);
            }
        },

        prepareDuration(duration) {

            let d = Math.floor(duration / 60 / 60 / 24);
            let h = Math.floor(duration / 60 / 60) - (d * 24);
            let m = Math.floor(duration / 60) - (h * 60) - (d * 60 * 24);

          //  return (d ? d + ' дн. ' : '') + (h ? h + ' час. ' : '') + (m ? m + ' мин. ' : '');

            let days = [
                'день', 'дня', 'дней'
            ]
            let hours = [
                'час', 'часа', 'часов'
            ]
            let minutes = [
                'минута', 'минуты', 'минут'
            ]

            return `${(d ? `${d} ${this.declination(d, days)}` : '')} ${(h ? `${h} ${this.declination(h, hours)}` : '')} ${(m ? `${m} ${this.declination(m, minutes)}` : '')}`;
        },

        setError(error) {
            if (error.code) {

                if (error.elements) {
                    $.each(error.elements, (key, val) => {
                        this.$root.$children.forEach((component) => {
                            if (component.name === key) {
                                component.setError(val);
                            }
                        });
                    });
                } else {
                    this.$root.$children.forEach((component) => {
                        let key = !error.elements ? error.error_field : error.error_message;
                        let val = !error.elements ? error.error_message : error.entity;
                        if (component.name === key) {
                            component.setError(val);
                        }
                    });
                }

                return;
            }

            $.each(error, (key, val) => {
                this.$root.$children.forEach((component) => {
                    if (component.name === key) {
                        component.setError(val);
                    }
                });
            });
        },

        clearError() {
            if (this.$root.$children) {
                this.$root.$children.forEach((component) => {
                    if (typeof component.clearError === 'function') {
                        component.clearError();
                    }
                });
            }
        },
    }
}
