<template>
  <div class="tabs">
    <router-link
        class="tab"
        v-for="(row, index) in category" v-bind:key="index"
        :to="'/' + getCityCode() + '/blog/' + row.blog_category_url+'/'"
        :title="row.blog_category_title"
    >
      {{row.blog_category_title}}
    </router-link>
  </div>
</template>

<script>

import mixins from '../../helpers/mixins.js';
import Ajax from "../../helpers/ajax";

export default {
  mixins: [mixins],
  components: {
  },

  data () {
    return {
      category: [],
      error: null,
      report: null,
    }
  },

  created () {
    this.loadCategory();
  },

  methods: {
    async loadCategory() {
      let result = await Ajax.get('/blog-category/all');
      if (result.response) {
        this.category = result.response;
      }
    },
  }
}
</script>