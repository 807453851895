<template>
  <div>
    <ImageHeader
        bg="other/header/not-found-page.webp"
        title="Ошибка 404"
        desc="Страница не найдена или перемещена"
    />
    <main class="w">

      <nav class="breadcrumb">
        <router-link to="/">Главная</router-link>
        <span>Страница не найдена</span>
      </nav>
      <h3>Страница к которой вы обращаетесь не существует или перенесена в другое место</h3>

    </main>
  </div>
</template>


<script>

import ImageHeader from '../default/ImageHeader';

export default {

  created() {
    document.title = 'Страница не найдена'

    if (window.location.href.indexOf('/404') === -1) {
      window.location.href = '/404/'
    }
  },

  components: {
    ImageHeader
  }
}



</script>