<template>
  <div class="w page">

    <div class="page-header">
      <h1 class="mb0">Договор оферты</h1>
    </div>

    <main>

      <nav class="breadcrumb">
        <router-link to="/">Главная</router-link>
        <router-link to="/about/">Информация о компании</router-link>
        <span class="bread-current">Договор оферты</span>
      </nav>

      <AboutMenu />


      <div class="text">
        <p style="text-align: center;"><strong>ДОГОВОР</strong><br> публичной оферты на оказание туристских
          услуг</p>
        <p style="text-align: center;"><strong>1. Общие положения</strong></p>
        <p style="text-align: justify;">1.1. Настоящий Договор является официальным предложением Общества с
          ограниченной ответственностью «ТУРИСТСКО - ЭКСКУРСИОННАЯ ФИРМА «ВИЗИТ», обращенным к неограниченному
          кругу лиц, заключить Договор на оказание туристских услуг, содержащее все существенные условия
          Договора. В соответствии с пунктом 2 статьи 437 Гражданского кодекса Российской Федерации данный
          документ является публичной офертой. Опубликован в информационно-телекоммуникационной сети "Интернет"
          на сайте <a href="https://vizit.tours">https://vizit.tours</a>.</p>
        <p style="text-align: justify;">1.2. Договор публичной оферты на оказание туристских услуг (далее -
          Договор) заключается в особом порядке: путем акцепта настоящего Договора, содержащего все существенные
          условия, без подписания сторонами. Договор считается заключенным с момента его акцептации и действует
          до исполнения сторонами всех своих обязательств по настоящему Договору, имеет юридическую силу в
          соответствии со статьей 434 Гражданского кодекса Российской Федерации и является равносильным
          Договору, подписанному сторонами.</p>
        <p style="text-align: justify;">1.3. Настоящий Договор является Договором присоединения. Фактом,
          подтверждающим принятие изложенных ниже условий, и акцептом настоящей публичной оферты является заказ
          туристских услуг и их последующая оплата. В соответствии с пунктом 3 статьи 438 Гражданского кодекса
          РФ акцепт оферты равносилен заключению Договора на условиях, изложенных в оферте.</p>
        <p style="text-align: center;"><strong>1.4. Используемые термины</strong></p>
        <p style="text-align: justify;">1.4.1. Публичная оферта&nbsp;- реклама и иные предложения, адресованные
          неопределенному кругу лиц, а так же содержащие все существенные условия Договора предложение, из
          которого усматривается воля лица, делающего предложение, заключить Договор на указанных в предложении
          условиях с любым, кто отзовется.</p>
        <p style="text-align: justify;">1.4.2. Акцепт&nbsp;— ответ лица, которому адресована оферта, о ее
          принятии. Ответ должен быть полным и безоговорочным. Молчание не является акцептом. Совершение лицом,
          получившим оферту, в срок, установленный для ее акцепта, действий по выполнению указанных в ней
          условий Договора, считается акцептом.</p>
        <p style="text-align: justify;">1.4.3. Турфирма - Общество с ограниченной ответственностью «ТУРИСТСКО -
          ЭКСКУРСИОННАЯ ФИРМА «ВИЗИТ» (ООО «ТЭФ «ВИЗИТ») в лице генерального директора Зинченко Игоря
          Витальевича, действующего на основании Устава. ИНН – 2301076977, ОГРН – 1112301001537. Юридический
          адрес: 353440 Краснодарский край, г. –к. Анапа, ул. Лермонтова, 116 г., оф. 12. Регистрация в ЕФРТ РФ
          № 017562.</p>
        <p style="text-align: justify;">1.4.4. Заказчик - дееспособное физическое лицо, достигшее 18 лет,
          заключившее договор с Турфирмой на приобретение туристских услуг, размещающее заказ и бронирование
          услуг на сайте Турфирмы, либо указанное в качестве получателя услуг, либо иным образом пользующееся
          услугами, приобретенными на сайте, исключительно для личных и иных нужд, не связанных с осуществлением
          предпринимательской деятельности. Для иностранных граждан (нерезидентов РФ) и юридических лиц данный
          договор действует при дополнительных согласованиях.</p>
        <p style="text-align: justify;">1.4.5.Сайт – информационный ресурс Турфирмы в
          информационно-телекоммуникационной сети "Интернет" расположенный по адресу: <a
              href="https://vizit.tours">https://vizit.tours</a>, через который осуществляется
          непосредственное бронирование туристских услуг. Информация на сайте может в любой момент быть изменена
          или дополнена Турфирмой.</p>
        <p style="text-align: justify;">1.4.6. Заказ или бронирование — должным образом оформленный запрос
          Заказчика на получение услуг, выбранных на сайте.</p>
        <p style="text-align: justify;">1.4.7. Ваучер (электронный билет) – документ, направляемый Турфирмой
          Заказчику по электронной почте после осуществления им оплаты экскурсионных услуг.</p>
        <p style="text-align: justify;">1.4.8. Туристские услуги - услуги по разработке, организации и
          проведению экскурсий.</p>
        <p style="text-align: justify;">1.4.9. Экскурсия - услуга по организации посещения объектов
          экскурсионного показа (объекты природного, историко-культурного наследия, промышленные предприятия и
          др.) индивидуальными туристами (экскурсантами) или туристскими группами, заключающаяся в ознакомлении
          и изучении указанных объектов в сопровождении экскурсовода, гида.</p>
        <p style="text-align: center;"><strong>2. Предмет договора</strong></p>
        <p style="text-align: justify;">2.1. В соответствии с условиями настоящего Договора Турфирма обязуется
          обеспечить предоставление Заказчику туристских услуг, а Заказчик обязуется принять и оплатить их. Под
          туристскими услугами понимается&nbsp; сформированный пакет услуг для посещения экскурсии в составе
          сборной экскурсионной группы и может включать в себя: услуги по перевозке, экскурсионное обслуживание,
          услуги сопровождающего гида, иные услуги, предоставляемые в зависимости от целей путешествия.</p>
        <p style="text-align: justify;">2.2. Услуги по настоящему Договору могут быть оказаны как лично
          Турфирмой, так и третьим лицом: организацией, физическим лицом, объектом размещения, посещения, показа
          и др. (далее – Организатор экскурсии).</p>
        <p style="text-align: justify;">2.3. Условия настоящего Договора принимаются Заказчиком полностью.
          Свидетельством полного и безоговорочного акцепта (принятия) условий Договора является осуществление
          Заказчиком процедуры оплаты экскурсии.</p>
        <p style="text-align: justify;">2.4. Если иное не следует из существа обязательства или требований
          закона, договорные права и обязанности Заказчика распространяются также на лиц, в интересах которых
          заключен настоящий Договор.</p>
        <p style="text-align: center;"><strong>3. Права и обязанности Турфирмы и Заказчика</strong></p>
        <p style="text-align: center;"><strong>3.1. Обязательства турфирмы<br></strong></p>
        <p>3.1.1. Обеспечить предоставление Заказчику оплаченных им туристских услуг.</p>
        <p style="text-align: justify;">3.1.2. Информировать Заказчика об условиях приобретения, содержании,
          потребительских свойствах туристских услуг.</p>
        <p style="text-align: justify;">3.1.3. Осуществлять информационную поддержку Заказчика с помощью
          электронных списков рассылки.</p>
        <p style="text-align: justify;">3.1.4. Информировать Заказчика о правах и обязанностях третьих лиц,
          непосредственно связанных с выполнением услуг.</p>
        <p style="text-align: justify;">3.1.5. Своевременно сообщать Заказчику обо всех изменениях условий
          путешествия, произошедших вследствие существенного изменения обстоятельств, из которых стороны
          исходили при заключении настоящего Договора, или вследствие наступления обстоятельств непреодолимой
          силы.</p>
        <p style="text-align: center;"><strong>3.2. Турфирма вправе</strong></p>
        <p>3.2.1. Аннулировать on-line заявку при неполучении от Заказчика оплаты.</p>
        <p style="text-align: justify;">3.2.2. Расторгнуть настоящий Договор с Заказчиком в одностороннем
          порядке, в том числе в связи с недобором сборной экскурсионной группы. Моментом расторжения договора
          считается дата направления соответствующего сообщения Заказчику по электронной почте и/или уведомление
          Заказчика иным способом, позволяющим удостоверить факт получения Заказчиком данного сообщения.</p>
        <p style="text-align: justify;">3.2.3. Производить доставку информации Заказчику с помощью электронных
          списков рассылки, состоящих из добровольно предоставленных Заказчиком e-mail адресов.</p>
        <p style="text-align: justify;">3.2.4. Заключать и исполнять в качестве турагента договоры с третьими
          лицами, в том числе с Организаторами экскурсий, непосредственно оказывающими туристские услуги.</p>
        <p style="text-align: center;"><strong>3.3. Заказчик обязан</strong></p>
        <p style="text-align: justify;">3.3.1. Оплатить Турфирме стоимость забронированных туристских услуг в
          порядке, установленном п.п. 4.1., 4.2. настоящего Договора.</p>
        <p style="text-align: justify;">3.3.2. Самостоятельно ознакомиться на сайте Турфирмы&nbsp; <a
            href="https://vizit.tours">https://vizit.tours</a> с информацией о выбранных туристских
          услугах.</p>
        <p style="text-align: justify;">3.3.3. Предоставить Турфирме подлинные, достоверные и достаточные
          персональные данные, в том числе точную информацию для оперативной связи о номере своего контактного
          телефона, адресе электронной почты.</p>
        <p style="text-align: justify;">3.3.4. Своевременно прибыть к месту сбора туристской группы, указанному
          в ваучере. Все расходы, связанные с опозданием к месту сбора группы, Заказчик несет самостоятельно.&nbsp;Неявка
          или явка к месту сбора после отбытия группы приравнивается к отказу Заказчика от исполнения
          договора.</p>
        <p style="text-align: justify;">3.3.5. При транспортном обслуживании соблюдать правила перевозки
          пассажиров, ручной клади и багажа, установленные нормативно-правовыми актами РФ.</p>
        <p style="text-align: justify;">3.3.6. Во время путешествия: уважать и соблюдать законодательство,
          социальное устройство, обычаи, традиции, религиозные верования места временного пребывания; соблюдать
          установленные правила охраны природы, памятников истории и культуры; соблюдать правила личной
          безопасности; выполнять меры личной профилактики по инфекционным и паразитарным заболеваниям;
          относиться к имуществу третьих лиц с надлежащей заботливостью и осмотрительностью, соблюдать
          установленные третьими лицами правила пользования таким имуществом, не причинять вреда имуществу
          третьих лиц; незамедлительно информировать Турфирму о неоказании или ненадлежащем оказании со стороны
          третьих лиц услуг, входящих в экскурсию; ознакомить иных участников путешествия с содержанием договора
          и со всей необходимой информацией, в том случае, если Заказчик, заключил настоящий Договор не только
          от своего имени, но также от имени или в интересах третьих лиц.</p>
        <p style="text-align: justify;">3.3.7. В случае досрочного одностороннего расторжения Договора
          компенсировать Турфирме все фактические расходы, понесенные ею в связи с исполнением обязанностей по
          настоящему Договору, в том числе штрафные санкции, оплаченные Турфирмой третьим лицам.</p>
        <p>3.4. Заказчик вправе:</p>
        <p style="text-align: justify;">3.4.1. Получить от Турфирмы оплаченные им услуги в соответствии с
          условиями настоящего Договора.</p>
        <p style="text-align: justify;">3.4.2. Получать от Турфирмы информацию, связанную со сроками и условиями
          предоставления услуг.</p>
        <p style="text-align: justify;">3.4.3. Расторгнуть настоящий Договор с соблюдением правил возврата
          денежных средств, установленных в п. 5. настоящего Договора. Денежные средства подлежат возврату
          Заказчику в той части, которая не пошла на покрытие расходов Турфирмы, т.е. за вычетом стоимости
          услуги бронирования и фактических затрат по исполнению Договора.</p>
        <p style="text-align: center;"><strong>4. Правила и порядок on-line бронирования и электронной
          оплаты</strong></p>
        <p style="text-align: justify;">4.1. Заказчик регистрируется на сайте <a href="https://vizit.tours">https://vizit.tours</a>,
          получает доступ к личному кабинету, бронирует услугу и производит оплату.</p>
        <p style="text-align: justify;">4.2. Оплата Турфирме осуществляется Заказчиком с использованием
          банковской карты, через сервисы GooglePay, либо через любую другую форму платежа предоставляемой
          платежной системой "Яндекс.Деньги". Оплата производится на стороне платежной платформы
          "Яндекс.Деньги"</p>
        <p style="text-align: justify;">4.3. Турфирма после получения оплаты отправляет Заказчику ваучер по
          электронной почте на указанный в заявке адрес Заказчика.</p>
        <p style="text-align: justify;">4.4. Турфирма оставляет за собой право отклонить заявку Заказчика при
          невозможности исполнения услуг по настоящему Договору с уведомлением Заказчика по электронной
          почте.</p>
        <p style="text-align: center;"><strong>5. Условия аннуляции и изменений оплаченных туристских
          услуг</strong></p>
        <p style="text-align: justify;">5.1. Заказчик вправе расторгнуть Договор до начала оказания услуг,
          известив письменно Турфирму по электронной почте anapavizit@yandex.ru.</p>
        <p style="text-align: justify;">5.2. Денежные средства, оплаченные Заказчиком по настоящему Договору,
          возвращаются Турфирмой в полном объеме только в случае поступления извещения об аннуляции не позднее,
          чем за 24 часа до начала исполнения услуги.</p>
        <p style="text-align: justify;">5.3. Неявка или опоздание Заказчика ко времени начала экскурсии по любым
          причинам приравнивается к отказу Заказчика от исполнения договора и не влечёт за собой возврат
          денежный средств, перечисленных Турфирме.</p>
        <p style="text-align: justify;">5.3. В случае изменения или расторжения Договора и (или) отказа
          Заказчика от его исполнения Заказчик обязан возместить Турфирме расходы, понесенные Турфирмой при
          исполнении Договора, в том числе денежные средства, переданные третьим лицам до момента получения от
          Заказчика письменного извещения об изменении или расторжении Договора и (или) отказе Заказчика от
          исполнения Договора, а также неустойки (штрафы, пени), оплаченные или подлежащие оплате Турфирмой
          третьим лицам.</p>
        <p style="text-align: justify;">5.4. При аннуляции Турфирмой экскурсии из-за недобора сборной
          экскурсионной группы Турфирма возвращает Заказчику 100 % произведенной им оплаты.</p>
        <p style="text-align: justify;">5.5. В исключительных случаях возможна замена Турфирмой услуг по
          настоящему Договору на аналогичные услуги без взимания какой-либо доплаты со стороны Заказчика.
          Допускается: перенос сроков начала и окончания путешествия, изменения маршрута и расписания экскурсии,
          а также иные оправданные изменения.</p>
        <p style="text-align: center;"><strong>6. Ответственность по обязательствам</strong></p>
        <p style="text-align: justify;">6.1.&nbsp;Турфирма и Заказчик несут ответственность за неисполнение или
          ненадлежащее исполнение условий настоящего Договора в порядке, установленном действующим
          законодательством Российской Федерации, и настоящим Договором.</p>
        <p style="text-align: justify;">6.2.&nbsp;В случае предоставления Заказчику туристской услуги
          Организатором экскурсии ответственность за неоказание или ненадлежащее оказание услуги перед
          Заказчиком несет Организатор экскурсии.</p>
        <p style="text-align: justify;">6.3.Турфирма не несет ответственность перед Заказчиком: - за понесенные
          Заказчиком расходы и иные негативные последствия, возникшие вследствие предоставления Заказчиком
          Турфирме недостоверных и /или недостаточных сведений (неверный номер контактного телефона или e-mail
          адреса и др.), необходимых для исполнения Договора; - за отсутствие возможности доступа Заказчика к
          сети Интернет; - за отсутствие у Заказчика надлежащих документов, а также за последствия утраты, кражи
          личного багажа, ценностей и документов в период получения услуг; - за отсутствие возможности
          предоставления услуг по независящим от Турфирмы техническим, организационным, финансовым и иным
          причинам, в том числе проблем с трафиком движения; - за несоответствие предоставленных туристских
          услуг ожиданиям Заказчика и его субъективной оценке; - за отсутствие благоприятных для Заказчика
          погодных условий в период экскурсии; - за действия или бездействие Организатора экскурсии во время
          оказания услуги.</p>
        <p style="text-align: justify;">6.4. В случае отказа Заказчика от получения услуг или совершения им
          действий, которые привели Турфирму к убыткам, Турфирма вправе потребовать от Заказчика возмещения
          материального ущерба, а также упущенной выгоды.</p>
        <p style="text-align: justify;">6.5. Форс-мажорные обстоятельства (наводнения, землетрясения, пожары,
          военные действия, решения высших государственных органов, забастовки и т.п.), в результате которых не
          могут быть выполнены обязательства по настоящему Договору, освобождают Заказчика и Турфирму от
          ответственности по обязательствам.</p>
        <p style="text-align: justify;">6.6. Заказчик предупрежден и согласен с тем, что в исключительных
          случаях возможна замена Турфирмой услуг, входящих в экскурсию (в том числе замена перевозчика, перенос
          сроков начала и окончания путешествия, изменения маршрута, а также иные оправданные изменения) на
          аналогичные услуги без взимания дополнительной платы.</p>
        <p style="text-align: center;"><strong>7. Порядок разрешения споров, предъявления претензии</strong></p>
        <p style="text-align: justify;">7.1. В случаях возникновения разногласий и споров из-за неисполнения или
          ненадлежащего исполнения настоящего Договора или в связи с ним, Заказчик и Турфирма будут стремиться к
          их урегулированию путем переговоров.</p>
        <p style="text-align: justify;">7.2. Письменная претензия к качеству туристических услуг предъявляется в
          соответствии со статьей 10 Закона РФ "Об основах туристской деятельности в Российской Федерации".</p>
        <p style="text-align: justify;">7.3. Претензия принимается Турфирмой только от Заказчика. От третьих
          лиц, в интересах которых заключен настоящий Договор, претензии не принимаются. 7.4. В случае не
          достижения соглашения в результате переговоров, спор разрешается в судебном порядке.</p>
        <p style="text-align: center;"><strong>8. Заключение, изменение и расторжение Договора</strong></p>
        <p style="text-align: justify;">8.1. Моментом вступления в силу акцептированного настоящего Договора
          считается момент зачисления оплаты на расчетный счет Турфирмы.</p>
        <p>8.2. Настоящий Договор действует до исполнения обязательств по настоящему Договору.</p>
        <p style="text-align: justify;">8.3. Настоящий Договор может быть изменен или расторгнут по соглашению
          сторон, а также иным основаниям, предусмотренным действующим законодательством Российской Федерации,
          или настоящим Договором.</p>
        <p style="text-align: justify;">8.4. Каждая из сторон вправе потребовать изменения или расторжения
          Договора в связи с существенным изменением обстоятельств, из которых стороны исходили при заключении
          Договора. К существенным изменениям обстоятельств относятся: ухудшение условий путешествия; изменение
          сроков совершения путешествия; рост транспортных тарифов; невозможность совершения Заказчиком поездки
          по независящим от него обстоятельствам (болезнь Заказчика – при наличии заверенных медицинских
          документов).</p>
        <p style="text-align: justify;">8.5. По всем вопросам, не урегулированным настоящим Договором, стороны
          руководствуются действующим законодательством Российской Федерации.</p>
        <p style="text-align: center;"><strong>9. Реквизиты Турфирмы</strong></p>

        <b>Реквизиты счёта</b>
       <div>Наименование компании: Индивидуальный предприниматель ЗИНЧЕНКО ИГОРЬ ВИТАЛЬЕВИЧ</div>
        <div>ИНН: 616503873622</div>
        <div>БИК: 044525411</div>
        <div>Банк: ФИЛИАЛ "ЦЕНТРАЛЬНЫЙ" БАНКА ВТБ (ПАО)</div>
        <div>Корреспондентский счет: 30101810145250000411</div>
        <div>Расчётный счёт: 40802810406160001012</div>
        <div class="col-md-6">Телефон</div>
        <div class="col-md-6">+7 (988) 340-65-85</div>
        <hr style="margin: 50px 0">

        <div class="row">
          <div class="col-md-6">Полное наименование Турфирмы</div>
          <div class="col-md-6">«ТУРИСТСКО - ЭКСКУРСИОННАЯ ФИРМА «ВИЗИТ» (ООО «ТЭФ «ВИЗИТ»)</div>
          <div class="col-md-6">ИНН</div>
          <div class="col-md-6">2301076977</div>
          <div class="col-md-6">КПП</div>
          <div class="col-md-6">230101001</div>
          <div class="col-md-6">ОГРН</div>
          <div class="col-md-6">1112301001537</div>
          <div class="col-md-6">Юридический адрес</div>
          <div class="col-md-6">353440 Краснодарский край, г.-к. Анапа, ул. Лермонтова, 116 г., оф. 12</div>
          <div class="col-md-6">Регистрация в ЕФРТ РФ</div>
          <div class="col-md-6">№ 017562</div>
          <div class="col-md-6">Банк</div>
          <div class="col-md-6">ПАО «ЮГ-ИНВЕСТБАНК» г.Краснодар</div>
          <div class="col-md-6">Расчетный счет</div>
          <div class="col-md-6">40702810900880007065</div>
          <div class="col-md-6">Корр. счет</div>
          <div class="col-md-6">30101810600000000966</div>
          <div class="col-md-6">БИК</div>
          <div class="col-md-6">040349966</div>
          <div class="col-md-6">Интернет сайт</div>
          <div class="col-md-6">https://vizit.tours</div>
          <div class="col-md-6">Электронная почта</div>
          <div class="col-md-6">anapavizit@yandex.ru</div>
          <div class="col-md-6">Телефон</div>
          <div class="col-md-6">+7 (988) 340-65-85</div>
        </div>
      </div>

    </main>
  </div>
</template>
<script>

import AboutMenu from './_menu';
import $ from "jquery";

export default {

  created() {
    document.title = 'Договор оферты'
    $('meta[name="description"]').attr('content', 'Договор оферты компании ТЭФ Визит')
  },

  components: {
    AboutMenu
  }
}


</script>