<template>
  <div class="w page">

    <div class="page-header">
      <h1 class="mb0">Информация о компании</h1>
    </div>

    <main>

      <nav class="breadcrumb">
        <router-link to="/">Главная</router-link>
        <span class="bread-current">Информация о компании</span>
      </nav>

      <AboutMenu />

      <div class="text">

        <h2>История компании</h2>

        <p>История нашей компании началась в 2002 году, когда было учреждено общество ООО "Пансионат Визит". В 2011 году на базе предприятия было органзовано ООО "Визит", которое стало заниматься исключительно экскурсиями и предоставлять транспортное обслуживание.</p>
        <p>В 2016 году, наименование компании было уточнено, с целью конкретизации вида деятельности и переименовано в&nbsp; ООО "Туристско-Экскурсионная Фирма "Визит" или сокращено в ТЭФ Визит.</p>
        <p>На этом развитие ООО "ТЭФ "ВИЗИТ" не остановилось. Появились новые сервисные услуги. Трансфер и экскурсионное обслуживание туристических групп на всей территории города- курорта Анапа, VIP – экскурсии, экскурсии для иностранцев.</p>
        <p>С ООО "ТЭФ "ВИЗИТ" теперь можно не только отправиться на экскурсию по Черноморскому побережью России (включая Крым) и Абхазии, но и организовать свой досуг непосредственно в городе. Для любителей дайвинга и прогулок на яхте в открытом море есть специальные предложения.</p>

        <h3>Сотрудники</h3>

        <p>Был сформирован дружный и квалифицированный коллектив и подобран комфортабельный автопарк, включающий в себя автобусы разной вместимости. Все экскурсоводы нашей фирмы лицензированные специалисты, обладают высокой квалификацией и огромным опытом работы.</p>
        <p>За время работы, с учётом интересов туристов, нашими экскурсоводами были составлены списки наиболее популярных и интересных экскурсий, на основании которых сегодня мы можем формировать расписание на весь сезон, что позволяет планировать нашим клиентам свой досуг в Анапе еще дома.</p>

        <h3>Наш автопарк</h3>

        <p>Автопарк нашей компании укомплектован различным по своим параметрам транспортом. Но весь автотранспорт, включая автобусы, микроавтобусы и машины объединены одним критерием - они комфортабельны и оборудованы для туристических поездок.</p>
        <p>В фотогалерее представлены не все автобусы, наш автопарк намного больше и постоянно обновляется.</p>
        <p>Надо отдельно отметить логистку компании. ООО "ТЭФ "ВИЗИТ" производит сбор туристов ориентируясь на локацию клиентов, например только в черте города или только на Пионерском проспекте, что позволяет сократить подготовительную часть экскурсии до 30-40 минут.</p>

        <h3>Взгляд в будущее</h3>

        <p>Наша компания не стоит на месте, мы стараемся идти в ногу со временем и технологическим прогрессом. В этом году мы открыли наш новый сайт, где вы сейчас и читаете эти строки. Теперь вы можете сделать покупку экскурсии, билетов и других туристических услуг в любое время дня и ночи, без привязки к месту - из номера отеля, на пляже, находясь дома или по пути в Анапу.</p>
        <p>Теперь вы можете выбрать и забронировать не только наши услуги, но и услуги наших партнеров, заказать индивидуальную экскурсию, выбрать гида. Основная стоящая задача перед нами на сегодня - перевести наш сайт в формат туристического маркетплейса Анапы, что позволит его наполнить новыми услугами и предложениями и самое главное сделать ваш отдых более доступным.</p>
        <p>Это наш первый шаг во внесении IT технологий в нашу деятельность. На базе нашего сайта в следующем году мы планируем запустить суперприложение, в котором будет все самое необходимое для тех, кто выбрал свой отдых в Анапе.</p>
        <p>Поэтому, выбирая отдых в Анапе, не забывайте о нас, возможно вы найдете в следующий свой приезд в Анапу массу нового и интересного в одном месте.</p>
        <p>Мы, в свою очередь, желаем Вам интересного отдыха и отличного настроения. Ждем встречи на экскурсионных маршрутах!</p>
      </div>

    </main>
  </div>
</template>
<script>

import AboutMenu from './_menu';
import $ from "jquery";

export default {
  created() {
    document.title = 'Информация о компании'
    $('meta[name="description"]').attr('content', 'Информация о компании ТЭФ Визит')
  },

  components: {
    AboutMenu
  }
}



</script>